import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { TailSpin as Loader } from "react-loader-spinner";
import { login } from "../../actions/auth";
import Button from "../../components/Button/Button";
import { ErrorDialog } from "../../components/ConfirmationDialog";
import "./Login.scss";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isAuthenticated = useSelector(({ auth }) => auth.isAuthenticated);
  const apiErrors = useSelector(({ apiErrors }) => apiErrors);

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/");
    }
  }, [isAuthenticated, navigate]);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const loginFormSubmit = (e) => {
    e.preventDefault();
    if (username === "" || password === "") return;
    if (!window.navigator.onLine) {
      ErrorDialog({
        title: "You're offline right now. Check your connection."
      });
    } else {
      setError("");
      setLoading(true);
      dispatch(login(username, password));
    }
  };

  useEffect(() => {
    if (apiErrors.message) {
      setLoading(false);
      setError(apiErrors.message);
    }
  }, [apiErrors]);

  const handleSignUp = () => {
      navigate('/signup');
  };

  const isDisabled = username === "" || password === "";

  return (
    <div>
      <h4 className="flex justify-end mt-16 mh-24">Version 1.0</h4>
      <div className="login-page flex-column flex-center">
        <h1 className="mb-24">Login or Sign Up</h1>
        {error && <p className="mb-16 font-danger bold">{error}</p>}
        <form onSubmit={loginFormSubmit} className="flex-column">
          <input
            placeholder="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            type="text"
            className="mb-16"
          />
          <input
            placeholder="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            type="password"
            className="mb-16"
          />
          {loading ? (
            <div className="flex-center-self">
              <Loader type="Puff" color="#354AC9" height={50} width={50} />
            </div>
          ) : (
            <Fragment>
              <Button label="Login" primary type="submit" disabled={isDisabled} className="mb-16" />
              <Button label="Sign Up" onClick={handleSignUp} />
            </Fragment>
          )}
        </form>
        <div className="flex align-center mt-24">
          <Link to="/privacy" className="mr-24 link">
            Privacy Policy
          </Link>
          <Link to="/forgot-password" className="link">
            Forgot Password?
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Login;
