import { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  assignAdventureToMultipleClasses,
} from '../../actions/classes';
import './AssignClasses.scss';
import ChooseClass from '../AssignAdventures/Tabs/ChooseClass';
import BundlesAdventures from '../AssignAdventures/Tabs/BundlesAdventures';
import SuccesMulitpleClasses from '../AssignAdventures/Tabs/SuccessMultipleClasses';
import AdventuresAndBundles from '../AssignAdventures/Tabs/AdventuresAndBundles';
import AllAdventuesList from '../AssignAdventures/Tabs/AllAdventuesList';
import BundleList from '../AssignAdventures/Tabs/BundleList';
import Classes from '../Classes/Classes';

const Go = () => {
  const [tab, setTab] = useState(0);
  const [selectedClass, setSelectedClass] = useState('');
  const [adventureList, setAdventureList] = useState('');

  const [bundleList, setBundleList] = useState('');
  const [selectedBundle, setSelectedBundle] = useState('');
  const [selectedAdventures, setSelectedAdventures] = useState('');
  const [bundlesAdventureList, setBundlesAdventureList] = useState('');

  const dispatch = useDispatch();
  const renderTab = tab => {
    switch (tab) {
      case 0:
        return (
          <ChooseClass
            selectedClass={selectedClass}
            multiClass
            onNext={classSelected => {
              setSelectedClass(classSelected);
              setTab(1);
            }}
            onBack={() => {
              bundlesAdventureList.length > 0 ? setTab(5) : setTab(1);
            }}
          />
        );
      case 1:
        return (
          <AdventuresAndBundles
            adventureTab={() => setTab(2)}
            bundleTab={() => setTab(4)}
            onBack={() => setTab(0)}
            multiClass
          />
        );
      case 2:
        return (
          <AllAdventuesList
            onBack={() => setTab(1)}
            selectedAdventures={adventureList}
            selectionList={selectedAdventures}
            multiClass
            onNext={adventure => {
              setSelectedAdventures(adventure);
              setTab(3);
            }}
          />
        );

      case 3:
        return (
          <SuccesMulitpleClasses
            choice={{
              class: selectedClass,
              adventure: selectedAdventures,
            }}
            selectedClass={selectedClass}
            selectedAdventures={selectedAdventures}
            bundleListFlag={bundlesAdventureList.length > 0}
            selectedBundle={selectedBundle}
            onBack={() => setTab(2)}
            onGo={(aim, startDate, endDate) => {
              const selectedClassesId = Object.values(selectedClass).map(({ id }) => id);
              const adventureID = Object.values(selectedAdventures)[0].id;
              dispatch(
                assignAdventureToMultipleClasses(
                  selectedClassesId,
                  adventureID,
                  aim,
                  startDate,
                  endDate,
                  bundlesAdventureList.length > 0
                )
              );
              setSelectedClass('');
              setSelectedAdventures('');
              setTab(6);
            }}
          />
        );
      case 4:
        return (
          <BundleList
            bundleList={bundleList}
            onNext={selectedBundle => {
              setBundlesAdventureList(selectedBundle);
              setSelectedBundle(selectedBundle);
              setTab(5);
            }}
            onBack={() => setTab(0)}
          />
        );

      case 5:
        return (
          <BundlesAdventures
            onBack={() => setTab(4)}
            selectedAdventures={bundlesAdventureList}
            selectedBundles={selectedBundle}
            multiClass
            onNext={adventure => {
              setSelectedAdventures(adventure);
              setTab(3);
            }}
          />
        );

      case 6:
        return <Classes />;

      default:
        return (
          <AdventuresAndBundles
            adventuresClick={adventure => {
              setAdventureList(adventure);
              setTab(1);
            }}
            bundleClick={adventure => {
              setBundleList(adventure);
              setTab(4);
            }}
          />
        );
    }
  };
  return (
    <div className="go-page flex-column fl-1">
      <div className="p-24">
        <h2>Assign Adventures</h2>
        {renderTab(tab)}
      </div>
    </div>
  );
};

export default Go;
