import _ from "lodash";
import {
  FETCH_NEXT_STUDENTS_SUCCESS,
  FETCH_STUDENT,
  FETCH_STUDENTS,
  DELETE_STUDENT,
  DELETE_STUDENT_SUCCESS,
  DELETE_STUDENT_ERROR,
  FETCH_STUDENTS_ERROR,
  FETCH_STUDENTS_SUCCESS,
  FETCH_STUDENT_ERROR,
  FETCH_STUDENT_SUCCESS,
} from "../actions/types";

const DEFAULT_STATE = {
  loading: false,
  error: null,
  pagination: {
    count: 0,
    next: null,
    prev: null,
  },
  data: {},
};

const studentsReducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case FETCH_STUDENTS:
    case FETCH_STUDENT:
    case DELETE_STUDENT:
      return { ...state, loading: true, error: null };
    case FETCH_STUDENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        pagination: {
          count: action.payload.count,
          next: action.payload.next,
          previous: action.payload.previous,
        },
        data: { ..._.mapKeys(action.payload.results, "id") },
      };
    case DELETE_STUDENT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: _.omit(state.data, action.payload),
      };

    case FETCH_NEXT_STUDENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        pagination: {
          count: action.payload.count,
          next: action.payload.next,
          previous: action.payload.previous,
        },
        data: { ...state.data, ..._.mapKeys(action.payload.results, "id") },
      };
    case FETCH_STUDENT_SUCCESS:
      const data = { ...state.data, [action.payload.id]: action.payload };
      return { ...state, loading: false, error: null, data };
    case FETCH_STUDENTS_ERROR:
    case FETCH_STUDENT_ERROR:
    case DELETE_STUDENT_ERROR:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default studentsReducer;
