import { useCallback, useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import gamerize from "../../apis/gamerize";
import "./AnalyticsStudent.scss";
import { getStudentAnalytic, getStudentOverallAnalytic } from "../../helper/getStudentAnalytic";
import getAdvenuturesCompleted from "../../helper/getAdventuresCompleted";
import Table from "../../components/Table/Table";
import { useMemo } from "react";
import fetchNext from "../../helper/fetchNext";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Popover } from "antd";
import Button from "../../components/Button/Button";
import { ErrorDialog } from "../../components/ConfirmationDialog";
import 'antd/dist/reset.css';

const AnalyticsStudent = () => {
  const navigate = useNavigate();
  const columns = useMemo(
    () => [
      {
        Header: "Word",
        accessor: "word",
      },
      {
        Header: "In-correct count",
        accessor: "error_count",
      },
      {
        Header: "Image",
        accessor: "image",
        Cell: ({ row }) => (
          <img
            src={row.original?.image}
            height={50}
            width={50}
            style={{ objectFit: "contain" }}
          />
        ),
      },
    ],

    []
  );

  const { id } = useParams();
  const [next, setNext] = useState(null);
  const [studentAnalyticsDetail, setStudentAnalyticsDetail] = useState(null);
  const [overAllStudentAnalyticsDetail, setOverAllStudentAnalyticsDetail] = useState(null);
  const [adventuresCompleted, setAdventuresCompleted] = useState(null);
  const [wordsError, setWordsError] = useState(null);
  const [wordsErrorCount, setWordsErrorCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const getStudentInformation = async () => {
    setError("");
    try {
      setLoading(true);
      const response = await getStudentAnalytic({ id });
      setStudentAnalyticsDetail(response);
      setLoading(false);
    } catch (error) {
      setError("Error Fetching Student Information");
    }
  };

  const getOverAllStudentInformation = async () => {
    setError("");
    try {
      setLoading(true);
      const response = await getStudentOverallAnalytic({ id });
      setOverAllStudentAnalyticsDetail(response);
      setLoading(false);
    } catch (error) {
      setError("Error Fetching Student Information");
    }
  };

  const getCompletedAdventures = async () => {
    setError("");
    try {
      setLoading(true);
      const response = await getAdvenuturesCompleted({ id });
      setAdventuresCompleted(response);
      setLoading(false);
    } catch (error) {
      setError("Error Fetching Completed Adventures");
    }
  };

  const URL = `/analytics/student_analytics/${id}/word_error_count/?`;

  const getErrorCount = useCallback(async (limit = 10) => {
    try {
      const response = await gamerize.get(`${URL}limit=${limit}`);
      setWordsError(response.data.results);
      setWordsErrorCount(response.data.count);
      setNext(response.data.next);
    } catch (error) {
      setError({ message: "Error fetching words error data" });
    }
  });

  useEffect(() => {
    getStudentInformation();
    getCompletedAdventures();
    getOverAllStudentInformation();
    getErrorCount();
  }, []);

  return (
    <div className="view-student-page flex-column fl-1">
      <div className="flex align-center justify-between mh-24 mt-24">
        <h2 style={{ margin: "auto" }}>Student Information</h2>
      </div>
      {studentAnalyticsDetail && (
        <div className="view-student-card flex m-24 fl-1">
          <div className="flex-column fl-1 mr-24">
            <div className="flex align-center justify-between mb-8 mh-24">
              <h3 className="mb-24">
                <span style={{ color: "grey" }}>Username: </span>
                {studentAnalyticsDetail.username}
              </h3>
              <Button
                primary
                className="mr-16 mt-24 mb-4 mb-24 order-select"
                label="View Adventures"
                onClick={() => {
                  if (!window.navigator.onLine) {
                    ErrorDialog({
                      title: "You're offline right now. Check your connection.",
                    });
                  } else {
                    navigate(
                      `/analytics/student/${id}/incomplete-adventure`);
                  }
                }}
              />
            </div>
            <h3 className="mb-24">
              <span style={{ color: "grey" }}>Name: </span>
              {studentAnalyticsDetail.first_name +
                " " +
                studentAnalyticsDetail.last_name}
            </h3>
            <h3 className="mb-24">
              <span style={{ color: "grey" }}>Email: </span>
              <span style={{ color: "blue" }}>
                {studentAnalyticsDetail.email}
              </span>
            </h3>
            <div className="card fl-1 justify-center mr-24">
              <h3 className="mb-12">Quick Stats</h3>
              <span style={{ color: "grey", fontWeight: "bold" }}>
                Last 7 days
              </span>
              <div
                className="flex"
                style={{ marginTop: "16px", marginBottom: "16px" }}
              >
                <div
                  className="card fl-1 justify-center"
                  style={{ height: "160px", textAlign: "center" }}
                >
                  <Popover
                    placement="topLeft"
                    content={checkinInfo}
                    title="Checkin Count"
                    arrowPointAtCenter
                  >
                    <h3>Checkin</h3>
                    <h4 style={{ color: "grey" }}>
                      count <InfoCircleOutlined />
                    </h4>
                  </Popover>
                  <h1
                    style={{
                      fontSize: "2rem",
                      fontWeight: "bold",
                      marginTop: "8px",
                    }}
                  >
                    {studentAnalyticsDetail.check_ins}
                  </h1>
                </div>
                <div
                  className="card fl-1 justify-center"
                  style={{ height: "160px", textAlign: "center" }}
                >
                  <Popover
                    placement="topLeft"
                    content={timeSpentInAppInfo}
                    title="Time Spent In App"
                    arrowPointAtCenter
                  >
                    <h3>Time Spent</h3>
                    <h4 style={{ color: "grey" }}>
                      in App <InfoCircleOutlined />
                    </h4>
                  </Popover>
                  <h1
                    style={{
                      fontSize: "2rem",
                      fontWeight: "bold",
                      marginTop: "8px",
                    }}
                  >
                    {studentAnalyticsDetail.total_time_spent_in_app}
                  </h1>
                </div>
                <div
                  className="card fl-1 justify-center"
                  style={{ height: "160px", textAlign: "center" }}
                >
                  <Popover
                    placement="topLeft"
                    content={timeSpentInGameInfo}
                    title="TIme Spent In Game"
                    arrowPointAtCenter
                  >
                    <h3>Time Spent</h3>
                    <h4 style={{ color: "grey" }}>
                      in Game <InfoCircleOutlined />
                    </h4>
                  </Popover>
                  <h1
                    style={{
                      fontSize: "2rem",
                      fontWeight: "bold",
                      marginTop: "8px",
                    }}
                  >
                    {studentAnalyticsDetail.total_time_spent_in_game}
                  </h1>
                </div>
                <div
                  className="card fl-1 md-24"
                  style={{ height: "160px", textAlign: "center" }}
                >
                  <Popover
                    placement="topLeft"
                    content={wordsStudiedSevenDaysInfo}
                    title="Words Studied"
                    arrowPointAtCenter
                  >
                    <h3>Words</h3>
                    <h4 style={{ color: "grey" }}>
                      Studied <InfoCircleOutlined />
                    </h4>
                  </Popover>
                  <h1
                    style={{
                      fontSize: "2rem",
                      fontWeight: "bold",
                      marginTop: "8px",
                    }}
                  >
                    {studentAnalyticsDetail.total_words_count}
                  </h1>
                </div>
              </div>
              <span style={{ color: "grey", fontWeight: "bold" }}>Overall</span>
              <div className="flex" style={{ marginTop: "16px" }}>
                <div
                  className="card fl-1 md-24"
                  style={{ height: "160px", textAlign: "center" }}
                >
                  <Popover
                    placement="topLeft"
                    content={adventureCompletedInfo}
                    title="Adventures Completed"
                    arrowPointAtCenter
                  >
                    <h3>Adventures</h3>
                    <h4 style={{ color: "grey" }}>
                      Completed <InfoCircleOutlined />
                    </h4>
                  </Popover>
                  <h1
                    style={{
                      fontSize: "2rem",
                      fontWeight: "bold",
                      marginTop: "8px",
                    }}
                  >
                    {studentAnalyticsDetail.total_completed_adventures ?? 0}
                  </h1>
                </div>
                <div
                  className="card fl-1 md-24"
                  style={{ height: "160px", textAlign: "center" }}
                >
                  <Popover
                    placement="topLeft"
                    content={wordsStudiedInfo}
                    title="Words Studied"
                    arrowPointAtCenter
                  >
                    <h3>Word</h3>
                    <h4 style={{ color: "grey" }}>
                      Studied <InfoCircleOutlined />
                    </h4>
                  </Popover>
                  <h1
                    style={{
                      fontSize: "2rem",
                      fontWeight: "bold",
                      marginTop: "8px",
                    }}
                  >
                    {overAllStudentAnalyticsDetail?.total_words_count ?? 0}
                  </h1>
                </div>
                <div
                  className="card fl-1 justify-center"
                  style={{ height: "160px", textAlign: "center" }}
                >
                  <Popover
                    placement="topLeft"
                    content={incorrecltyAnsweredWordsInfo}
                    title="Incorrectly Answered Words"
                    arrowPointAtCenter
                  >
                    <h3>Incorrectly Answered Words</h3>
                    <h4 style={{ color: "grey" }}>
                      Difficult Words <InfoCircleOutlined />
                    </h4>
                  </Popover>
                  <h1
                    style={{
                      fontSize: "2rem",
                      fontWeight: "bold",
                      marginTop: "8px",
                    }}
                  >
                    {wordsErrorCount}
                  </h1>
                </div>
              </div>
              {wordsError && (
                <div style={{ marginTop: "40px" }}>
                  <h3>Difficult Words List</h3>
                  <h4 style={{ color: "grey", marginTop: "6px" }}>
                    List of words that are difficult to learn for the student
                  </h4>
                  <div style={{ marginTop: "40px" }}>
                    <Table
                      columns={columns}
                      data={wordsError}
                      update={async () => {
                        try {
                          const response = await fetchNext(URL, next);
                          const { data, nextPage } = response;
                          setWordsError((prevStats) => [...prevStats, ...data]);
                          setNext(nextPage);
                        } catch (error) {
                          setError("Error fetching more data");
                        }
                      }}
                      next={next}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const uniqueAdventureCompletedInfo = (
  <div style={{ width: "300px" }}>
    <p>
      This analytic represents the number of unique adventures completed by the
      student.
    </p>
    <p>
      We do not collect information about the adventures students have created
      on the device as those are private.
    </p>
  </div>
);

const adventureCompletedInfo = (
  <div style={{ width: "300px" }}>
    <p>
      This analytic represents the number of adventures completed by the
      student.
    </p>
    <p>
      We do not collect information about the adventures students have created
      on the device as those are private.
    </p>
  </div>
);

const timeSpentInAppInfo = (
  <div style={{ width: "300px" }}>
    <p>Time spent in the app learning or making the town in the last 7 days.</p>
    <p>The time is an approximate value. It is synced after 24 hours.</p>
  </div>
);

const timeSpentInGameInfo = (
  <div style={{ width: "300px" }}>
    <p>Time spent in the app learning in the last 7 days.</p>
    <p>Whenever a student plays a mini game, the time is updated.</p>
  </div>
);

const checkinInfo = (
  <div style={{ width: "300px" }}>
    <p>Number of times a student opens up the app in the last 7 days.</p>
    <p>Whenever a student opens up the app we log it.</p>
  </div>
);

const wordsStudiedSevenDaysInfo = (
  <div style={{ width: "300px" }}>
    <p>Number of words & sentences studied in the last 7 days.</p>
    <p>
      Once a student has completed an adventure then we consider the words in
      the adventure as learned.
    </p>
  </div>
);

const wordsStudiedInfo = (
  <div style={{ width: "300px" }}>
    <p>Number of words & sentences studied overall.</p>
    <p>
      Once a student has completed an adventure then we consider the words in
      the adventure as learned.
    </p>
  </div>
);

const incorrecltyAnsweredWordsInfo = (
  <div style={{ width: "300px" }}>
    <p>Number of words that were incorrectly answered.</p>
    <p>
      Words that were answered incorrectly. Please see the list below of the
      words that are difficult for the student.
    </p>
  </div>
);
export default AnalyticsStudent;
