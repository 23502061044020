import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table as AntTable, Select, Modal } from 'antd';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { assignAdventureToMultipleClasses } from '../../actions/classes';
import '../AssignClasses/AssignClasses.scss';
import ChooseClass from '../AssignAdventures/Tabs/ChooseClass';
import BundlesAdventures from '../AssignAdventures/Tabs/BundlesAdventures';
import SuccesMulitpleClasses from '../AssignAdventures/Tabs/SuccessMultipleClasses';
import AdventuresAndBundles from '../AssignAdventures/Tabs/AdventuresAndBundles';
import AllAdventuesList from '../AssignAdventures/Tabs/AllAdventuesList';
import BundleList from '../AssignAdventures/Tabs/BundleList';
import Classes from '../Classes/Classes';

import { addStudentAdventure } from '../../actions/adventures';

const selectAdventuresError = ({ adventures }) => adventures.error;
const selectAdventuresStatus = ({ adventures }) => adventures.status;

const Go = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const student_id = location.id;
  const adventuresError = useSelector(selectAdventuresError);
  const adventuresStatus = useSelector(selectAdventuresStatus);
  const [tab, setTab] = useState(1);
  const [selectedClass, setSelectedClass] = useState('');
  const [adventureList, setAdventureList] = useState('');

  const [bundleList, setBundleList] = useState('');
  const [selectedBundle, setSelectedBundle] = useState('');
  const [selectedAdventures, setSelectedAdventures] = useState('');
  const [bundlesAdventureList, setBundlesAdventureList] = useState('');

  const [studentAdventure, setstudentAdventure] = useState([]);

  useEffect(() => {
    if (adventuresError?.message) {
      error();
    }
  }, [adventuresError]);

  useEffect(() => {
    if (adventuresStatus?.message) {
      success();
    }
  }, [adventuresStatus]);

  useEffect(() => {
    if (studentAdventure?.id) {
      dispatch(addStudentAdventure(student_id, studentAdventure.id));
    }
  }, [studentAdventure]);
  const dispatch = useDispatch();
  const error = () => {
    // Modal.error({
    //   content: adventuresError?.message,
    // });
  };
  const success = () => {
    Modal.success({
      content: adventuresStatus?.message,
    });
  };

  const renderTab = tab => {
    switch (tab) {
      case 1:
        return (
          <AdventuresAndBundles
            adventureTab={() => setTab(2)}
            bundleTab={() => setTab(4)}
            onBack={() => setTab(0)}
            multiClass
          />
        );
      case 2:
        return (
          <AllAdventuesList
            onBack={() => setTab(1)}
            selectedAdventures={adventureList}
            selectionList={selectedAdventures}
            multiClass
            onNext={adventure => {
              navigate(`/analytics/student/${student_id}/incomplete-adventure`,{state:{adventure: adventure[0]}});
            }}
          />
        );
      case 4:
        return (
          <BundleList
            bundleList={bundleList}
            onNext={selectedBundle => {
              setBundlesAdventureList(selectedBundle);
              setSelectedBundle(selectedBundle);
              setTab(5);
            }}
            onBack={() => setTab(0)}
          />
        );
      case 5:
        return (
          <BundlesAdventures
            onBack={() => setTab(4)}
            selectedAdventures={bundlesAdventureList}
            selectedBundles={selectedBundle}
            multiClass
            onNext={adventure => {
              if (student_id) {
                navigate(`/analytics/student/${student_id}/incomplete-adventure`, {state:{adventure: adventure[0]}});
              }
            }}
          />
        );
      default:
        return (
          <AdventuresAndBundles
            adventuresClick={adventure => {
              setAdventureList(adventure);
              setTab(1);
            }}
            bundleClick={adventure => {
              setBundleList(adventure);
              setTab(4);
            }}
          />
        );
    }
  };
  return (
    <div className="go-page flex-column fl-1">
      <div className="p-24">
        <h2>Assign Adventures</h2>
        {renderTab(tab)}
      </div>
    </div>
  );
};

export default Go;
