import { Route, Routes } from "react-router-dom";
import SideBar from "../components/SideBar/SideBar";
import MyStudents from "../pages/MyStudents/MyStudents";
import Students from "../pages/Students/Students";
import UploadData from "../pages/UploadData/UploadData";
import AnalyticsStudent from "../pages/AnalyticsStudent/AnalyticsStudent";

const StudentsRouter = () => {
  return (
    <div className="flex page">
      <SideBar
        pages={[
          { title: "Students", link: `/students/dashboard` },
          { title: "My Students", link: `/students/my-students` },
        ]}
      />
      <Routes>
        <Route path="dashboard" element={<Students />} />
        <Route path="my-students" element={<MyStudents />} />
        <Route path="upload-data" element={<UploadData />} />
        <Route path="my-students/student/:id" element={<AnalyticsStudent />} />
      </Routes>
    </div>
  );
};

export default StudentsRouter;
