import { Route, Routes } from "react-router-dom";
import SideBar from "../components/SideBar/SideBar";
import MyTeachers from "../pages/MyTeachers/MyTeachers";
import Teachers from "../pages/Teachers/Teachers";
import TeachersRequests from "../pages/TeachersRequests/TeachersRequests";

const TeachersRouter = () => {
  return (
    <div className="flex page">
      <SideBar
        pages={[
          { title: "Teachers", link: `/teachers/dashboard` },
          { title: "My Teachers", link: `/teachers/my-teachers` },
          { title: "Teachers Requests", link: `/teachers/teachers-requests` },
        ]}
      />
      <Routes>
        <Route path={`dashboard`} element={<Teachers />} />
        <Route path={`my-teachers`} element={<MyTeachers/>} />
        <Route
          path={`teachers-requests`}
          element={<TeachersRequests/>}
        />
        <Route
          path={`my-teachers/teacher/view/:id`}
          element={<div>Some Teacher</div>}
        />
      </Routes>
    </div>
  );
};

export default TeachersRouter;
