import { useEffect, useState } from "react";

import moment from "moment";
import _ from "lodash";

import { Table } from "antd";
import Button from "../../../components/Button/Button";
import "antd/dist/reset.css";
import { TailSpin as Loader } from "react-loader-spinner";

import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";

import { fetchBundle } from "../../../actions/bundles";
import { fetchAdventures } from "../../../actions/adventures";

const selectBundles = ({ bundles }) => bundles.data;
const bundleById = (id) =>
  createSelector([selectBundles], (bundles) => bundles[id]);

const AdventureList = ({
  onBack,
  selectedBundles,
  onNext,
  multiClass,
  bundleId,
  classBattle,
  selectedAdventure,
}) => {
  const bundle = useSelector(bundleById(selectedBundles?.id));
  const [selected, setSelected] = useState(selectedAdventure || []);
  const [bundlesAdventures, setBundlesAdventures] = useState([]);

  console.log("adv in bundle", selectedAdventure, "selected", selected);
  console.log("what battle", classBattle);

  const handleNext = () => {
    if (!_.isEmpty(selected)) {
      onNext(selected);
    }
  };

  const shouldDisable = Object.keys(selected || []).length > 0;
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const dispatch = useDispatch();
  const offset = (page - 1) * pageSize;
  const limit = pageSize;

  useEffect(() => {
    dispatch(fetchAdventures(search, limit, offset));
  }, [search, dispatch, page, pageSize]);

  useEffect(() => {
    if (selectedBundles) {
      dispatch(fetchBundle(selectedBundles.id));
    }
  }, [selectedBundles]);

  useEffect(() => {
    setBundlesAdventures(bundle?.adventures);
  }, [bundle]);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Learning Aim",
      dataIndex: "learning_aim",
      render: (value) =>
        value === "spelling_the_word"
          ? "Spelling the Word"
          : value === "writing_the_sentence"
          ? "Write the Sentence"
          : value === "saying_the_word"
          ? "Saying the Word"
          : value === "say_the_sentence"
          ? "Say the Sentence"
          : "",
    },
    {
      title: "Date Created",
      dataIndex: "date_created",
      defaultSortOrder: "descend",
      render: (value) => moment(value).format("DD MMM, YYYY"),
    },
    {
      title: "Date Updated",
      dataIndex: "date_updated",
      defaultSortOrder: "descend",
      render: (value) => moment(value).format("DD MMM, YYYY"),
    },
  ];

  const rowSelection = {
    renderCell: (checked, record, index, originNode) => {
      if (multiClass) {
        if (shouldDisable && !checked) return null;
        return originNode;
      }
      return originNode;
    },
    selectedRowKeys: selected ? Object.keys(selected).map((a) => +a) : [],
    onChange: (selectedRowKeys, selectedRows) => {
      const rows = {};
      selectedRows.forEach((each) => {
        rows[each.id] = each;
      });
      setSelected(rows);
    },
    columnTitle: <div />,
  };

  const renderAdventureList = () => (
    <>
      {!bundlesAdventures ? (
        <div className="flex flex-center fl-1">
          <Loader type="Puff" color="#354AC9" height={50} width={50} />
        </div>
      ) : (
        <Table
          title={null}
          pagination={false}
          columns={columns}
          dataSource={bundlesAdventures?.map((obj) => ({
            ...obj,
            key: obj.id,
          }))}
          showHeader
          size="default"
          bordered={false}
          rowSelection={
            classBattle
              ? rowSelection
              : {
                  renderCell: (checked, record, index, originNode) => {
                    if (multiClass) {
                      if (shouldDisable && !checked) return null;
                      return originNode;
                    }
                    return originNode;
                  },
                  onChange: (selectedRowKeys, selectedRows) => {
                    selectedRows.forEach((each) => {
                      setSelected([...selected, each]);
                    });
                  },
                  columnTitle: <div />,
                }
          }
        />
      )}
    </>
  );
  return (
    <>
      {classBattle ? (
        <h3 className="ml-10 mr-24 mt-24 mb-16 text-heading ">
          Select an adventure from the adventure list
        </h3>
      ) : (
        <h1 className="mh-24 mt-24 mb-16">Adventure List</h1>
      )}
      <div className="overflow-hidden flex-column fl-1">
        {classBattle ? null : (
          <div className="flex justify-between align-center ph-24 pv-16 ml-8 mg-top">
            <h3>Total number of adventures: {bundlesAdventures?.length}</h3>
            <h3>Selected Adventures: {Object.keys(selected).length}</h3>
          </div>
        )}

        <div id="scrollableContainer" className="list-container fl-1 ml-24">
          {renderAdventureList()}
        </div>
      </div>
      <div className="flex justify-end mb-24 mh-24 summary-btns">
        {bundlesAdventures !== null && (
          <>
            <Button
              label="Back"
              primary
              onClick={onBack}
              className="mr-24"
              wide
            />
            <Button
              label="Next"
              primary
              onClick={handleNext}
              wide
              disabled={_.isEmpty(selected)}
            />
          </>
        )}
      </div>
    </>
  );
};

export default AdventureList;
