import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, Link } from "react-router-dom";
import { deleteClass, fetchClass, removeStudents } from "../../actions/classes";
import { stringMatch } from "../../utils";
import TabError from "../../components/TabError/TabError";
import {TailSpin as Loader} from "react-loader-spinner";
import Button from "../../components/Button/Button";
import { DownOutlined } from "@ant-design/icons";
import {
  ConfirmationDialog,
  ErrorDialog,
} from "../../components/ConfirmationDialog";
import { Table as AntTable, Dropdown, Menu } from "antd";
import _ from "lodash";

const selectClasses = ({ classes }) => classes.data;
const selectClassesLoading = ({ classes }) => classes.loading;
const selectClassesError = ({ classes }) => classes.error;

const ViewClass = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const classes = useSelector(selectClasses);
  const classesLoading = useSelector(selectClassesLoading);
  const classesError = useSelector(selectClassesError);

  const [fetchedStudents, setFetchedStudents] = useState([]);
  const [students, setStudents] = useState([]);
  const [search, setSearch] = useState("");

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "User Name",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "Name",
      dataIndex: "first_name",
      key: "first_name",
      render: (value, row) => value + " " + row.last_name,
    },
    {
      title: "Action",
      key: "action",
      width: 160,
      fixed: "right",
      render: (value) => (
        <span className="table-action-buttons">
          <Link
            className="text-button view"
            to={`/classes/my-students/student/${value.id}`}
          >
            View
          </Link>
          <Dropdown
            trigger={["click"]}
            className="table-more-button"
            overlay={
              <Menu className="actions-menu">
                <Menu.Item onClick={() => deleteHandler(value)}>
                  Delete
                </Menu.Item>
              </Menu>
            }
          >
            <span className="text-button ant-dropdown-link">
              More <DownOutlined />
            </span>
          </Dropdown>
        </span>
      ),
    },
  ];

  const deleteHandler = (value) => {
    ConfirmationDialog({
      title: "Are You Sure?",
      onConfirm: () => {
        if (!window.navigator.onLine) {
          ErrorDialog({
            title: "You're offline right now. Check your connection.",
          });
        } else {
          dispatch(removeStudents(id, [value.id]));
        }
      },
    });
  };

  useEffect(() => {
    dispatch(fetchClass(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (classesLoading === false && !_.isEmpty(classes) && !classes[id]) {
      navigate("/classes/my-classes");
    }
  }, [classes, navigate, id, classesLoading]);

  useEffect(() => {
    if (classes[id]) {
      setFetchedStudents(classes[id].students);
    }
  }, [classes, id]);

  useEffect(() => {
    setStudents(fetchedStudents);
  }, [fetchedStudents]);

  useEffect(() => {
    setStudents(
      fetchedStudents.filter(
        ({ username }) => username && stringMatch(username, search)
      )
    );
  }, [search, fetchedStudents]);

  const handleDeleteClass = () => {
    if (!window.navigator.onLine) {
      ErrorDialog({
        title: "You're offline right now. Check your connection.",
      });
    } else {
      dispatch(deleteClass(id));
    }
  };

  const renderStudentList = () =>
    students.length > 0 ? (
      <AntTable
        title={null}
        columns={columns}
        dataSource={students}
        showHeader
        size="default"
        bordered={false}
      />
    ) : (
      <div className="ph-24">No students in this class.</div>
    );

  return (
    <div className="viewclass-page flex-column fl-1 pt-24">
      {classesLoading ? (
        <div className="flex flex-center fl-1">
          <Loader type="Puff" color="#354AC9" height={50} width={50} />
        </div>
      ) : classesError ? (
        <TabError
          onClick={() => dispatch(fetchClass(id))}
          message={classesError.message}
          label="Reload Tab"
        />
      ) : (
        <Fragment>
          <div className="flex align-center justify-between mb-16 mh-24">
            <h2>{`${classes[id]?.name}${
              classes[id]?.is_default_class ? " (Default Class)" : ""
            }`}</h2>
            <input
              className="input input-icon icon-search-b"
              placeholder="Search Students"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          {classes[id]?.teacher && (
            <div className="mb-16 mh-24">
              {classes[id].teacher ? (
                <h3>
                  Teacher: {classes[id].teacher.first_name || ""}{" "}
                  {classes[id].teacher.last_name || ""}
                </h3>
              ) : (
                <h3>No teacher</h3>
              )}
            </div>
          )}
          <div className="flex mb-24 mh-24">
            <Button
              label="View Assigned Adventures"
              primary
              onClick={() => {
                if (!window.navigator.onLine) {
                  ErrorDialog({
                    title: "You're offline right now. Check your connection.",
                  });
                } else {
                  navigate(
                    `/classes/my-classes/class/view/${id}/adventures`
                  );
                }
              }}
              className="mr-16"
            />
            <Button
              label="Delete Class"
              danger
              onClick={() => {
                ConfirmationDialog({
                  title: "Are You Sure?",
                  onConfirm: () => {
                    handleDeleteClass();
                  },
                });
              }}
            />
          </div>

          <div className="fl-1 list-container">{renderStudentList()}</div>
        </Fragment>
      )}
    </div>
  );
};

export default ViewClass;
