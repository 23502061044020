import { Link } from "react-router-dom";
import "./Teachers.scss";

const Teachers = () => {
  return (
    <div className="teachers-page fl-1 p-24">
      <div className="grid-fill">
        <Link to="/teachers/my-teachers">
          <div className="card card-nav clickable flex-column flex-center font-black">
            <h2 className="mb-16">My Teachers</h2>
            <p>List of Your teachers</p>
          </div>
        </Link>
        <Link to="/teachers/teachers-requests">
          <div className="card card-nav clickable flex-column flex-center font-black">
            <h2 className="mb-16">Teacher Request</h2>
            <p>List of Requests</p>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default Teachers;
