import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../../components/Button/Button";
import { TailSpin as Loader } from "react-loader-spinner";
import TabError from "../../../../components/TabError/TabError";
import { fetchMonsters } from "../../../../actions/class-battle-history";
import "./MonsterList.scss";

const selectMonsterList = ({ classBattle }) => classBattle.monsters_list;
const selectBattleHistoryLoading = ({ classBattle }) =>
  classBattle.monsters_loader;
const selectBattleHistoryError = ({ classBattle }) => classBattle.error;
const selectPagination = ({ classBattle }) =>
  classBattle.monsters_list_pagination;

const ChooseMonster = ({
  selectedMonsterId,
  setSelectedMonsterId,
  battleType,
  onBack,
  onCreate,
}) => {
  const dispatch = useDispatch();
  const fetchedMonstersList = useSelector(selectMonsterList);
  const battleHistoryLoading = useSelector(selectBattleHistoryLoading);
  const battleHistoryError = useSelector(selectBattleHistoryError);
  const pagination = useSelector(selectPagination);

  const [monsters, setMonsters] = useState([]);
  const [page, setPage] = useState(1);
  const pageSize = 10;
  const listInnerRef = useRef();

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = listInnerRef.current;
      if (scrollLeft + clientWidth >= scrollWidth - 100) {
        if (monsters.length < pagination.count) {
          setPage((prevPage) => prevPage + 1);
        }
      }
    }
  };

  useEffect(() => {
    dispatch(fetchMonsters(pageSize, (page - 1) * pageSize));
  }, [dispatch, page, pageSize]);

  useEffect(() => {
    if (page === 1) {
      setMonsters(Object.values(fetchedMonstersList));
    } else {
      const newMonsters = Object.values(fetchedMonstersList).filter(
        (newMonster) =>
          !monsters.some(
            (existingMonster) => existingMonster.id === newMonster.id
          )
      );
      setMonsters((prev) => [...prev, ...newMonsters]);
    }
  }, [fetchedMonstersList, page]);

  const handleMonsterSelection = (monster) => {
    setSelectedMonsterId(monster.id);
  };

  return (
    <>
      {battleType === "review_battle" ? (
        <h3 className="mb-12 text-heading mg-top ml-10">
          Step 3: Choose Monster
        </h3>
      ) : (
        <h3 className="mb-12 text-heading mg-top ml-10">
          Step 4: Choose Monster
        </h3>
      )}
      <p className="mb-24 ml-10">Click to select a monster</p>
      {battleHistoryLoading ? (
        <div className="flex flex-center fl-1">
          <Loader type="Puff" color="#354AC9" height={50} width={50} />
        </div>
      ) : battleHistoryError ? (
        <TabError
          onClick={() => dispatch(fetchMonsters(pageSize, 0))}
          message={battleHistoryError.message}
          label="Reload Monster List"
        />
      ) : (
        <div
          className="box"
          onScroll={onScroll}
          ref={listInnerRef}
          style={{ overflowX: "auto" }}
        >
          {monsters.map((monster) => (
            <div
              key={monster.id}
              className={`card card-nav clickable flex flex-center ml-24 ${
                selectedMonsterId === monster.id ? "selected" : ""
              }`}
              onClick={() => handleMonsterSelection(monster)}
            >
              <div className="content">
                <img
                  src={monster.image}
                  alt={monster.name}
                  height="250px"
                  width="210px"
                />
                <div className="flex justify-end">
                  <p className="font-black bold">{monster.name}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
      <div className="flex justify-end mb-24 mh-24 mv-24">
        <Button label="Back" primary className="mr-24" wide onClick={onBack} />
        <Button
          label={battleType === "review_battle" ? "Create" : "Next"}
          primary
          className="mr-24"
          wide
          onClick={onCreate}
          disabled={!selectedMonsterId}
        />
      </div>
    </>
  );
};

export default ChooseMonster;
