import _ from "lodash";
import {
  FETCH_BATTLE_HISTORY,
  FETCH_BATTLE_DETAILS_SUCCESS,
  FETCH_BATTLE_HISTORY_SUCCESS,
  FETCH_NEXT_BATTLE_HISTORY_SUCCESS,
  FETCH_BATTLE_DETAILS,
  FETCH_STUDENTS_JOINED,
  FETCH_STUDENTS_JOINED_SUCCESS,
  FETCH_MONSTERS_SUCCESS,
  FETCH_MONSTERS_ERROR,
  FETCH_MONSTERS,
  SET_BATTLE_STATE,
  FETCH_BATTLE_DETAILS_ERROR,
  FETCH_BATTLE_HISTORY_ERROR,
  FETCH_STUDENTS_JOINED_ERROR,
} from "../actions/types";

const DEFAULT_STATE = {
  loading: false,
  history_loader: false,
  students_loader: false,
  monsters_loader: false,
  error: null,
  pagination: {
    count: 0,
    next: null,
    prev: null,
  },
  data: {},
  detail: {},
  students_joined: {},
  students_joined_pagination: {
    count: 0,
    next: null,
    prev: null,
  },
  monsters_list: [],
  monsters_list_pagination: {
    count: 0,
    next: null,
    prev: null,
  },
  isBattleLive: false,
};

const battleHistoryReducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case FETCH_MONSTERS:
      return { ...state, monsters_loader: true, error: null };
    case FETCH_BATTLE_HISTORY:
      return { ...state, loading: true, error: null };
    case FETCH_BATTLE_DETAILS:
      return { ...state, history_loader: true, error: null };
    case FETCH_STUDENTS_JOINED:
      return { ...state, students_loader: true, error: null };
    case FETCH_BATTLE_HISTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        pagination: {
          count: action.payload.count,
          next: action.payload.next,
          previous: action.payload.previous,
        },
        data: { ..._.mapKeys(action.payload.results, "id") },
      };
    case FETCH_NEXT_BATTLE_HISTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        pagination: {
          count: action.payload.count,
          next: action.payload.next,
          previous: action.payload.previous,
        },
        data: { ...state.data, ..._.mapKeys(action.payload.results, "id") },
      };
    case FETCH_BATTLE_DETAILS_SUCCESS:
      return {
        ...state,
        history_loader: false,
        error: null,
        data: { ...state.data },
        detail: action.payload,
      };

    case FETCH_STUDENTS_JOINED_SUCCESS:
      return {
        ...state,
        students_loader: false,
        error: null,
        students_joined_pagination: {
          count: action.payload.count,
          next: action.payload.next,
          previous: action.payload.previous,
        },
        students_joined: { ..._.mapKeys(action.payload.results, "student.id")}
      };
    case FETCH_MONSTERS_SUCCESS:
      return {
        ...state,
        monsters_loader: false,
        error: null,
        monsters_list_pagination: {
          count: action.payload.count,
          next: action.payload.next,
          previous: action.payload.previous,
        },
        monsters_list: {...action.payload.results},
      };
    case FETCH_MONSTERS_ERROR:
    case FETCH_BATTLE_HISTORY_ERROR:
    case FETCH_BATTLE_DETAILS_ERROR:
    case FETCH_STUDENTS_JOINED_ERROR:
      return { ...state, loading: false, error: action.payload };
    case SET_BATTLE_STATE:
      return { ...state, isBattleLive: action.payload };
    default:
      return state;
  }
};

export default battleHistoryReducer;
