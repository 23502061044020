import React from "react";
import { useSelector } from "react-redux";
import { Link, NavLink, useLocation } from "react-router-dom";
import _ from "lodash";
import Avatar from "../../images/avatar.png";
import "./SideBar.scss";

const selectUserType = ({ auth }) => auth.user_type;
const selectUsername = ({ auth }) => auth.first_name;

const SideBar = ({ pages }) => {
  const { pathname } = useLocation();
  const user_type = useSelector(selectUserType);
  const userName = useSelector(selectUsername);

  const isBattleLive = useSelector((state) => state.classBattle.isBattleLive);

  const handleClick = (e) => {
    if (isBattleLive) {
      e.preventDefault();
      alert("You cannot navigate away during the game!");
    }
  };
  const renderList = () => {
    return (
      <ul className="p-0">
        {pages.map(({ link, title }) => (
          <NavLink
            key={link}
            to={link}
            onClick={handleClick}
            className={({ isActive }) =>
              isActive ? "sidebar-item mb-16 selected" : "sidebar-item mb-16"
            }
          >
            {title}
          </NavLink>
        ))}
        {pathname !== "/" && (
          <NavLink to="/" className="sidebar-item mb-16" onClick={handleClick}>
            Home
          </NavLink>
        )}
      </ul>
    );
  };

  return (
    <div className="sidebar">
      <Profile userName={userName} user_type={user_type} />
      {renderList()}
    </div>
  );
};

const Profile = React.memo(({ userName, user_type }) => {
  const isBattleLive = useSelector((state) => state.classBattle.isBattleLive);

  const handleClick = (e) => {
    if (isBattleLive) {
      e.preventDefault();
      alert("You cannot navigate away during the game!");
    }
  };
  return (
    <Link
      className="flex align-center ph-24 pv-16"
      to="/profile/options"
      onClick={handleClick}
    >
      <img src={Avatar} className="avatar mr-8" alt="Gibran" />
      <div className="flex-column">
        <h2>{userName}</h2>
        <p>{_.capitalize(user_type)}</p>
      </div>
    </Link>
  );
});

export default SideBar;
