import Button from "../../../components/Button/Button";

const ChooseMultipleAdventures = ({
  adventureTab,
  bundleTab,
  onBack,
  multiClass,
  reviewTab,
  isReview = false,
}) => {
  return (
    <div>
      {isReview ? (
        <h3 className="mb-24 text-heading mg-top ml-10">
          Step 2: Choose Adventure/Bundle or Review
        </h3>
      ) : (
        <h3 className="mb-24 text-heading mg-top">
          Step 1: Select from Adventures or Bundles
        </h3>
      )}
      <div className="grid-fill ml-10">
        <>
          <div
            className="card card-nav clickable flex flex-center"
            onClick={adventureTab}
          >
            <p className="font-black bold">Adventures</p>
          </div>
          <div
            className="card card-nav clickable flex flex-center"
            onClick={bundleTab}
          >
            <p className="font-black bold">Bundles</p>
          </div>
          {isReview ? (
            <div
              className="card card-nav clickable flex flex-center"
              onClick={reviewTab}
            >
              <p className="font-black bold">Review</p>
            </div>
          ) : null}
        </>
      </div>
      {multiClass && (
        <div className="flex justify-end mb-24 mt-24">
          <>
            <Button label="Back" primary onClick={onBack} wide />
          </>
        </div>
      )}
    </div>
  );
};

export default ChooseMultipleAdventures;
