import { useTable } from "react-table";
import InfiniteScroll from "react-infinite-scroll-component";
import {TailSpin as Loader} from "react-loader-spinner";

// Create a default prop getter
const defaultPropGetter = () => ({});

const Table = ({
  columns,
  data,
  update,
  next,
  getRowProps = defaultPropGetter,
}) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    });

  return (
    <InfiniteScroll
      dataLength={rows.length}
      next={update}
      hasMore={!!next}
      loader={
        <div className="flex flex-center fl-1 mt-24">
          <Loader type="Puff" color="#354AC9" height={50} width={50} />
        </div>
      }
      className="mh-24"
      scrollableTarget="scrollableContainer"
      endMessage={
        <p className="text-center mt-24">
          <b>End of List</b>
        </p>
      }
    >
      <table className="table" {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th className="table-header" {...column.getHeaderProps(column)}>
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr className="table-row" {...row.getRowProps(getRowProps(row))}>
                {row.cells.map((cell) => {
                  return (
                    <td className="table-data" {...cell.getCellProps()}>
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </InfiniteScroll>
  );
};

export default Table;
