import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { logout } from "../../actions/auth";
import classNames from "classnames";
import "./NavMenu.scss";

const NavMenu = ({ opened, close }) => {
  const dispatch = useDispatch();
  const isBattleLive = useSelector((state) => state.classBattle.isBattleLive); // Assuming the game state is managed here

  const handleClick = (e) => {
    if (isBattleLive) {
      e.preventDefault();
      alert("You cannot navigate away during the game!");
    }
  };
  const containerClasses = classNames(
    "nav-menu-container",
    opened ? "fade-in" : "fade-out"
  );

  const menuClasses = classNames(
    "nav-menu",
    opened ? "slide-in__right" : "slide-out__right"
  );

  return (
    <div className={containerClasses} onClick={close}>
      <div className={menuClasses} onClick={(e) => e.stopPropagation()}>
        <Link to="/" className="mb-24" onClick={handleClick}>
          Home
        </Link>
        <Link to="/classes/dashboard" className="mb-24" onClick={handleClick}>
          Classes
        </Link>
        <Link to="/adventures/dashboard" className="mb-24" onClick={handleClick}>
          Adventures
        </Link>
        {!isBattleLive&&<p onClick={() => dispatch(logout())}>Logout</p>}
      </div>
    </div>
  );
};

export default NavMenu;
