export function renderColorScheme() {
  return (
    <div className="flex justify-end" style={{ margin: "40px" }}>
      <h3 style={{ margin: "auto 40px auto" }}>Color Scheme (Time Spent): </h3>
      <div class="color-scheme" style={{ backgroundColor: "#ACDDDE" }}>
        <h4 style={{ margin: "auto" }}>1 - 5</h4>
      </div>
      <div class="color-scheme" style={{ backgroundColor: "#CAF1DE" }}>
        <h4 style={{ margin: "auto" }}>6 - 10</h4>
      </div>
      <div class="color-scheme" style={{ backgroundColor: "#E1F8DC" }}>
        <h4 style={{ margin: "auto" }}>11 - 20</h4>
      </div>
      <div class="color-scheme" style={{ backgroundColor: "#FEF8DD" }}>
        <h4 style={{ margin: "auto" }}>21 - 60</h4>
      </div>
      <div class="color-scheme" style={{ backgroundColor: "#FFE7C7" }}>
        <h4 style={{ margin: "auto" }}>60 +</h4>
      </div>
    </div>
  );
}
