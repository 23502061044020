import React from "react";
import { NavLink } from "react-router-dom";
import _ from "lodash";
import "./SideBarAnalytics.scss";

const SideBarAnalytics = ({ pages }) => {
  const renderList = () => {
    return (
      <ul className="p-0">
        {pages.map(({ link, title }) => (
          <NavLink
            key={link}
            to={link}
            className={({ isActive }) => isActive ? "sidebar-analytics-item mb-16 selected" : "sidebar-analytics-item mb-16"}
          >
            {title}
          </NavLink>
        ))}
      </ul>
    );
  };

  return <div className="sidebar-analytics">{renderList()}</div>;
};

export default SideBarAnalytics;
