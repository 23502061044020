// AUTH
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const SIGN_UP = "SIGN_UP";
export const FETCH_USER = "FETCH_USER";
export const UPDATE_USER = "UPDATE_USER";
export const INSTITUTE_CODE = "INSTITUTE_CODE";

// STUDENTS
export const FETCH_STUDENTS = "FETCH_STUDENTS";
export const FETCH_STUDENTS_SUCCESS = "FETCH_STUDENTS_SUCCESS";
export const FETCH_STUDENTS_ERROR = "FETCH_STUDENTS_ERROR";

export const FETCH_NEXT_STUDENTS_SUCCESS = "FETCH_NEXT_STUDENTS_SUCCESS";

export const FETCH_STUDENT = "FETCH_STUDENT";
export const FETCH_STUDENT_SUCCESS = "FETCH_STUDENT_SUCCESS";
export const FETCH_STUDENT_ERROR = "FETCH_STUDENT_ERROR";

export const DELETE_STUDENT = "DELETE_STUDENT";
export const DELETE_STUDENT_SUCCESS = "DELETE_STUDENT_SUCCESS";
export const DELETE_STUDENT_ERROR = "DELETE_STUDENT_ERROR";

// CLASSES
export const FETCH_CLASSES = "FETCH_CLASSES";
export const FETCH_CLASSES_SUCCESS = "FETCH_CLASSES_SUCCESS";
export const FETCH_CLASSES_ERROR = "FETCH_CLASSES_ERROR";

export const FETCH_NEXT_CLASSES_SUCCESS = "FETCH_NEXT_CLASSES_SUCCESS";

export const FETCH_CLASS = "FETCH_CLASS";
export const FETCH_CLASS_SUCCESS = "FETCH_CLASS_SUCCESS";
export const FETCH_CLASS_ERROR = "FETCH_CLASS_ERROR";

export const CREATE_CLASS = "CREATE_CLASS";
export const CREATE_CLASS_SUCCESS = "CREATE_CLASS_SUCCESS";
export const CREATE_CLASS_ERROR = "CREATE_CLASS_ERROR";

export const UPDATE_CLASS = "UPDATE_CLASS";
export const UPDATE_CLASS_SUCCESS = "UPDATE_CLASS_SUCCESS";
export const UPDATE_EDIT_CLASS_STATUS = "UPDATE_EDIT_CLASS_STATUS";
export const UPDATE_REMOVE_STUDENT_STATUS = "UPDATE_REMOVE_STUDENT_STATUS";
export const UPDATE_CLASS_ERROR = "UPDATE_CLASS_ERROR";

export const DELETE_CLASS = "DELETE_CLASS";
export const DELETE_CLASS_SUCCESS = "DELETE_CLASS_SUCCESS";
export const DELETE_CLASS_ERROR = "DELETE_CLASS_ERROR";

export const ASSIGN_CLASS_ADVENTURE = "ASSIGN_CLASS_ADVENTURE";
export const ASSIGN_CLASS_ADVENTURE_SUCCESS = "ASSIGN_CLASS_ADVENTURE_SUCCESS";
export const ASSIGN_CLASS_ADVENTURE_ERROR = "ASSIGN_CLASS_ADVENTURE_ERROR";

export const ASSIGN_MULTIPLE_CLASSES_ADVENTURE =
  "ASSIGN_MULTIPLE_CLASSES_ADVENTURE";
export const ASSIGN_MULTIPLE_CLASSES_ADVENTURE_SUCCESS =
  "ASSIGN_MULTIPLE_CLASSES_ADVENTURE_SUCCESS";
export const ASSIGN_MULTIPLE_CLASSES_ADVENTURE_ERROR =
  "ASSIGN_MULTIPLE_CLASSES_ADVENTURE_ERROR";

export const UNASSIGN_CLASS_ADVENTURE = "UNASSIGN_CLASS_ADVENTURE";
export const UNASSIGN_CLASS_ADVENTURE_SUCCESS =
  "UNASSIGN_CLASS_ADVENTURE_SUCCESS";
export const UNASSIGN_CLASS_ADVENTURE_ERROR = "UNASSIGN_CLASS_ADVENTURE_ERROR";

export const ASSIGN_CLASS_TEACHER = "ASSIGN_CLASS_TEACHER";
export const ASSIGN_CLASS_TEACHER_SUCCESS = "ASSIGN_CLASS_TEACHER_SUCCESS";
export const ASSIGN_CLASS_TEACHER_ERROR = "ASSIGN_CLASS_TEACHER_ERROR";

export const UNASSIGN_CLASS_TEACHER = "UNASSIGN_CLASS_TEACHER";
export const UNASSIGN_CLASS_TEACHER_SUCCESS = "UNASSIGN_CLASS_TEACHER_SUCCESS";
export const UNASSIGN_CLASS_TEACHER_ERROR = "UNASSIGN_CLASS_TEACHER_ERROR";

export const ASSIGN_MULTIPLE_CLASSES_TEACHER =
  "ASSIGN_MULTIPLE_CLASSES_TEACHER";
export const ASSIGN_MULTIPLE_CLASSES_TEACHER_SUCCESS =
  "ASSIGN_MULTIPLE_CLASSES_TEACHER_SUCCESS";
export const ASSIGN_MULTIPLE_CLASSES_TEACHER_ERROR =
  "ASSIGN_MULTIPLE_CLASSES_TEACHER_ERROR";

// ADVENTURES
export const FETCH_ADVENTURES = "FETCH_ADVENTURES";
export const FETCH_ADVENTURES_SUCCESS = "FETCH_ADVENTURES_SUCCESS";
export const FETCH_ADVENTURES_ERROR = "FETCH_ADVENTURES_ERROR";

export const FETCH_NEXT_ADVENTURES_SUCCESS = "FETCH_NEXT_ADVENTURES_SUCCESS";

export const FETCH_ADVENTURE = "FETCH_ADVENTURE";
export const FETCH_ADVENTURE_SUCCESS = "FETCH_ADVENTURE_SUCCESS";
export const FETCH_ADVENTURE_ERROR = "FETCH_ADVENTURE_ERROR";

export const CREATE_ADVENTURE = "CREATE_ADVENTURE";
export const CREATE_ADVENTURE_SUCCESS = "CREATE_ADVENTURE_SUCCESS";
export const CREATE_ADVENTURE_ERROR = "CREATE_ADVENTURE_ERROR";

export const UPDATE_ADVENTURE = "UPDATE_ADVENTURE";
export const UPDATE_ADVENTURE_SUCCESS = "UPDATE_ADVENTURE_SUCCESS";
export const UPDATE_ADVENTURE_ERROR = "UPDATE_ADVENTURE_ERROR";

export const DELETE_ADVENTURE = "DELETE_ADVENTURE";
export const DELETE_ADVENTURE_SUCCESS = "DELETE_ADVENTURE_SUCCESS";
export const DELETE_ADVENTURE_ERROR = "DELETE_ADVENTURE_ERROR";

// TEACHERS
export const FETCH_TEACHERS = "FETCH_TEACHERS";
export const FETCH_TEACHERS_SUCCESS = "FETCH_TEACHERS_SUCCESS";
export const FETCH_TEACHERS_ERROR = "FETCH_TEACHERS_ERROR";

export const FETCH_NEXT_TEACHERS_SUCCESS = "FETCH_NEXT_TEACHERS_SUCCESS";

export const DELETE_TEACHER = "DELETE_TEACHER";
export const DELETE_TEACHER_SUCCESS = "DELETE_TEACHER_SUCCESS";
export const DELETE_TEACHER_ERROR = "DELETE_TEACHER_ERROR ";

// BUNDLES
export const FETCH_BUNDLES = "FETCH_BUNDLES";
export const FETCH_BUNDLES_SUCCESS = "FETCH_BUNDLES_SUCCESS";
export const FETCH_BUNDLES_ERROR = "FETCH_BUNDLES_SUCCESS_ERROR";

export const FETCH_NEXT_BUNDLES_SUCCESS = "FETCH_NEXT_BUNDLES_SUCCESS";

export const FETCH_BUNDLE = "FETCH_BUNDLE";
export const FETCH_BUNDLE_SUCCESS = "FETCH_BUNDLE_SUCCESS";
export const FETCH_BUNDLE_ERROR = "FETCH_BUNDLE_ERROR";

// API ERRORS
export const SET_API_ERROR = "SET_API_ERROR";
export const CLEAR_API_ERROR = "CLEAR_API_ERROR";

// Payment Option
export const PAYMENT_OPTION = "PAYMENT_OPTION";

// CLASS BATTLE HISTORY
export const FETCH_BATTLE_HISTORY="FETCH_BATTLE_HISTORY";
export const FETCH_BATTLE_HISTORY_SUCCESS = "FETCH_BATTLE_HISTORY_SUCCESS";
export const FETCH_BATTLE_HISTORY_ERROR = "FETCH_BATTLE_HISTORY_ERROR";
export const FETCH_BATTLE_DETAILS_ERROR = "FETCH_BATTLE_DETAILS_ERROR";
export const FETCH_STUDENTS_JOINED_ERROR = "FETCH_STUDENTS_JOINED_ERROR";
export const FETCH_BATTLE_DETAILS_SUCCESS = "FETCH_BATTLE_DETAILS_SUCCESS";
export const FETCH_BATTLE_DETAILS = "FETCH_BATTLE_DETAILS";
export const FETCH_NEXT_BATTLE_HISTORY_SUCCESS = "FETCH_NEXT_BATTLE_HISTORY_SUCCESS";
export const FETCH_STUDENTS_JOINED = "FETCH_STUDENTS_JOINED";
export const FETCH_STUDENTS_JOINED_SUCCESS = "FETCH_STUDENTS_JOINED_SUCCESS";
export const FETCH_MONSTERS = "FETCH_MONSTERS";
export const FETCH_MONSTERS_SUCCESS = "FETCH_MONSTERS_SUCCESS";
export const FETCH_MONSTERS_ERROR = "FETCH_MONSTERS_ERROR";

export const SET_BATTLE_STATE = 'SET_BATTLE_STATE';