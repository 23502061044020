import Button from "../../../components/Button/Button";

const SuccesMulitpleClasses = ({ choice, onBack, onAssign }) => {
  const classes = Object.values(choice.class);
  return (
    <div className="flex-column fl-1">
      <h3 className="mb-16 mh-24">Summary</h3>
      <div className="go-success-card mh-24">
        {classes.map(({ name }) => (
          <h2 className="mb-24" key={name}>
            {name}
          </h2>
        ))}
        <h2>
          {choice.teacher.first_name} {choice.teacher.last_name}
        </h2>
      </div>
      <div className="flex justify-end mt-auto mh-24 mb-24">
        <Button label="Back" primary onClick={onBack} className="mr-24" wide />
        <Button label="Assign" primary onClick={() => onAssign(choice)} wide />
      </div>
    </div>
  );
};

export default SuccesMulitpleClasses;
