import _ from "lodash";
import {
  FETCH_BUNDLE, FETCH_BUNDLES, FETCH_BUNDLES_ERROR, FETCH_BUNDLES_SUCCESS, FETCH_BUNDLE_ERROR, FETCH_BUNDLE_SUCCESS, FETCH_NEXT_BUNDLES_SUCCESS
} from "../actions/types";

const DEFAULT_STATE = {
  loading: false,
  error: null,
  pagination: {
    count: 0,
    next: null,
    prev: null
  },
  data: {}
};

const bundlesReducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case FETCH_BUNDLES:
    case FETCH_BUNDLE:
      return { ...state, loading: true, error: null };
    case FETCH_BUNDLES_SUCCESS:
      return {
        ...state, loading: false, error: null, pagination: {
          count: action.payload.count,
          next: action.payload.next,
          previous: action.payload.previous
        },
        data: { ..._.mapKeys(action.payload.results, "id") }
      };
    case FETCH_NEXT_BUNDLES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        pagination: {
          count: action.payload.count,
          next: action.payload.next,
          previous: action.payload.previous
        },
        data: { ...state.data, ..._.mapKeys(action.payload.results, "id") }
      }
    case FETCH_BUNDLE_SUCCESS:
      const data = { ...state.data, [action.payload.id]: action.payload }
      return { ...state, loading: false, error: null, data };
    case FETCH_BUNDLES_ERROR:
    case FETCH_BUNDLE_ERROR:
      return { ...state, loading: false, error: action.payload }
    default:
      return state;
  }
};

export default bundlesReducer;
