import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TabError from "../../components/TabError/TabError";
import { TailSpin as Loader } from "react-loader-spinner";
import { Table as AntTable, Input } from "antd";
import {
  fetchBattleHistory,
  fetchBattleHistoryDetails,
} from "../../actions/class-battle-history";
import ClassBattleModal from "../../components/ClassBattleModal/ClassBattleModal";

const selectBattleHistory = ({ classBattle }) => classBattle.data;
const selectBattleDetails = ({ classBattle }) => classBattle.detail;
const selectBattleHistoryPagination = ({ classBattle }) =>
  classBattle.pagination;
const selectBattleHistoryLoading = ({ classBattle }) => classBattle.loading;
const selectBattleHistoryError = ({ classBattle }) => classBattle.error;
const { Search } = Input;

const ClassBattleHistory = () => {
  const dispatch = useDispatch();

  const fetchedBattleHistory = useSelector(selectBattleHistory);
  const fetchedHistoryDetails = useSelector(selectBattleDetails);
  const battleHistoryPagination = useSelector(selectBattleHistoryPagination);
  const battleHistoryLoading = useSelector(selectBattleHistoryLoading);
  const battleHistoryError = useSelector(selectBattleHistoryError);

  const [battleHistory, setBattleHistory] = useState([]);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const offset = (page - 1) * pageSize;
  const [isView, setView] = useState(false);
  const [selectedBattle, setSelectedBattle] = useState({});
  const [battleHistoryDetails, setBattleHistoryDetails] = useState({});

  function formatDate(utcDateString) {
    const date = new Date(utcDateString + "Z");

    const options = {
      day: "numeric",
      month: "short", 
      year: "2-digit",
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    };

    const localDateString = date.toLocaleString("en-US", options);
    return localDateString;
  }

  const columns = [
    {
      title: "Class Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Start Time",
      dataIndex: "date_created",
      key: "date_created",
      render: (value) => <>{formatDate(value)}</>,
    },
    {
      title: "Monster",
      dataIndex: "monster",
      key: "monster",
      render: (value) => <>{value.name}</>,
    },
    {
      title: "Action",
      key: "action",
      width: 160,
      render: (text, record) => (
        <span className="text-button view" onClick={() => viewHandler(record)}>
          View
        </span>
      ),
    },
  ];

  useEffect(() => {
    dispatch(fetchBattleHistory(search, pageSize, offset));
  }, [dispatch, offset, pageSize]);

  useEffect(() => {
    setBattleHistoryDetails(fetchedHistoryDetails);
  }, [fetchedHistoryDetails]);

  useEffect(() => {
    const sortedHistory = Object.values(fetchedBattleHistory).sort((a, b) => {
      return new Date(b.date_created) - new Date(a.date_created);
    });
    setBattleHistory(sortedHistory);
  }, [fetchedBattleHistory]);

  const handlePagination = () => {
    return {
      current: page,
      pageSize: pageSize,
      total: battleHistoryPagination.count,
      onChange: (battleHistoryPage, battleHistoryPageSize) => {
        setPage(battleHistoryPage);
        setPageSize(battleHistoryPageSize);
      },
    };
  };

  const onCancelClick = () => {
    setView(false);
  };

  const viewHandler = (value) => {
    setSelectedBattle(value);
    dispatch(fetchBattleHistoryDetails(value.id));
    setView(true);
  };

  const renderBattlesList = () => (
    <AntTable
      title={null}
      pagination={handlePagination()}
      columns={columns}
      dataSource={battleHistory}
      showHeader
      size="default"
      bordered={false}
    />
  );

  const onSearch = () => {
    dispatch(fetchBattleHistory(search, pageSize, offset));
    setPage(1);
  };

  return (
    <div className="my-teachers-page flex-column fl-1 pt-24">
      {battleHistoryLoading ? (
        <div className="flex flex-center fl-1">
          <Loader type="Puff" color="#354AC9" height={50} width={50} />
        </div>
      ) : battleHistoryError ? (
        <TabError
          onClick={() => dispatch(fetchBattleHistory())}
          message={battleHistoryError.message}
          label="Reload Battle History"
        />
      ) : (
        <Fragment>
          <div className="flex align-center justify-between mb-16 mh-24">
            <h2>Class Battle History</h2>
          </div>
          <div className="mb-16 mh-24">
            <Search
              placeholder="Search Battles"
              enterButton="Search"
              size="large"
              onSearch={onSearch}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          <div id="scrollableContainer" className="scroll-vertical fl-1">
            {renderBattlesList()}
          </div>
          {isView && Object.keys(battleHistoryDetails).length > 0 ? (
            <ClassBattleModal
              modalIsOpen={true}
              onCancelClick={onCancelClick}
              selectedBattle={selectedBattle}
              battleHistoryDetails={battleHistoryDetails}
            ></ClassBattleModal>
          ) : null}
        </Fragment>
      )}
    </div>
  );
};

export default ClassBattleHistory;
