import gamerize from "../apis/gamerize";
const getSubscriptionDetail = async () => {
  try {
    const response = await gamerize.get("/users/subscription/");
    return response.data;
  } catch (error) {
    throw new Error("Error Fetching Subscription Details");
  }
};
export default getSubscriptionDetail;
