import classNames from "classnames";
import IconButton from "../Button/IconButton";
import "./TeacherListItem.scss";

const TeacherListItem = ({ teacher, edit, selected, onClick }) => {
  const classes = classNames(
    "teacher-list-item",
    "mb-32",
    "pointer",
    selected && "selected"
  );
  return (
    <div key={teacher.id} className={classes} onClick={onClick}>
      <div className="flex-column">
        <h3 className="mb-4">
          {teacher.first_name} {teacher.last_name}
        </h3>
        <h3 className="mb-4">
          {teacher.email}
        </h3>
        <p>Click to pick teacher.</p>
      </div>
      {edit && (
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();
            edit();
          }}
          icon="edit"
          className="circle"
        />
      )}
    </div>
  );
};

export default TeacherListItem;
