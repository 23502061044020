import { Link } from "react-router-dom";
import Button from "../../Button/Button";

const ClassAdded = ({ onBack, edit }) => {
  return (
    <div className="flex-column p-24 fl-1">
      <h2 className="mb-24">Class {edit ? "Edited" : "Created"}</h2>
      <p className="mb-24">
        You can preview and edit classes from the my classes tab.
      </p>
      <div className="flex">
        <Button label="New Class" primary onClick={onBack} className="mr-24" />
        <Link to="/classes/my-classes">
          <Button label="My Classes" primary />
        </Link>
      </div>
    </div>
  );
};

export default ClassAdded;
