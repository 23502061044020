import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import SideBar from "../../components/SideBar/SideBar";
import isClassBattleEnabled from "../../helper/isClassBattleEnabled";
import { TailSpin as Loader } from "react-loader-spinner";
import { TeacherNavBarLink } from "../../components/NavBar/navbarLinks";

const TeacherHome = () => {
  const [classRoomBattleEnabled, setClassRoomBattleEnabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const fetchClassBattleState = async () => {
    setError("");
    try {
      setIsLoading(true);
      const enableClassBattle = await isClassBattleEnabled();
      setClassRoomBattleEnabled(enableClassBattle);
      setIsLoading(false);
    } catch (error) {
      setError("Error Fetching Class Battle State");
    }
  };
  useEffect(() => {
    fetchClassBattleState();
  }, []);
  return (
    <>
      {isLoading ? (
        <div
          className="flex-column flex-center"
          style={{ width: "-webkit-fill-available" }}
        >
          <Loader type="Puff" color="#354AC9" height={54} width={54} />
        </div>
      ) : (
        <div className="page flex">
          <SideBar
            pages={[
              ...TeacherNavBarLink,
              ...(classRoomBattleEnabled ? [{ title: "Class Battle 🚧", link: "/class-battle/dashboard" }] : []),
            ]}
          />
          <div className="home-page flex-column justify-between fl-1 p-24">
            <div className="grid-fill">
              <Link
                className="card card-nav clickable flex flex-center"
                to="/classes/dashboard"
              >
                <p className="font-black bold">Classes</p>
              </Link>
              <Link
                className="card card-nav clickable flex flex-center"
                to="/adventures/dashboard"
              >
                <p className="font-black bold">Adventures</p>
              </Link>
              <Link
                className="card card-nav clickable flex flex-center"
                to="/analytics/dashboard"
              >
                <p className="font-black bold">Analytics</p>
              </Link>
              {classRoomBattleEnabled ? (
                <Link
                  className="card card-nav clickable flex flex-center"
                  to="/class-battle/dashboard"
                >
                  <p className="font-black bold">Class Battle Class Battle (beta) 🚧</p>
                </Link>
              ) : null}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TeacherHome;
