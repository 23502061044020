import gamerize from "../apis/gamerize";

const fetchNext = async (url, next) => {
  if (!next) return;
  const query = next.split("?")[1] || "";
  try {
    const response = await gamerize.get(`${url}${query}`);
    const data = response.data.results;
    const nextPage = response.data.next;
    return { data, nextPage };
  } catch (error) {
    throw new Error(error);
  }
};

export default fetchNext;
