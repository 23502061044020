import _ from "lodash";
import {
  ASSIGN_CLASS_ADVENTURE,
  ASSIGN_CLASS_ADVENTURE_ERROR,
  ASSIGN_CLASS_ADVENTURE_SUCCESS,
  ASSIGN_CLASS_TEACHER,
  ASSIGN_CLASS_TEACHER_ERROR,
  ASSIGN_CLASS_TEACHER_SUCCESS,
  ASSIGN_MULTIPLE_CLASSES_ADVENTURE,
  ASSIGN_MULTIPLE_CLASSES_ADVENTURE_ERROR,
  ASSIGN_MULTIPLE_CLASSES_ADVENTURE_SUCCESS,
  ASSIGN_MULTIPLE_CLASSES_TEACHER,
  ASSIGN_MULTIPLE_CLASSES_TEACHER_ERROR,
  ASSIGN_MULTIPLE_CLASSES_TEACHER_SUCCESS,
  CREATE_CLASS,
  CREATE_CLASS_ERROR,
  CREATE_CLASS_SUCCESS,
  DELETE_CLASS,
  DELETE_CLASS_ERROR,
  DELETE_CLASS_SUCCESS,
  FETCH_CLASS,
  FETCH_CLASSES,
  FETCH_CLASSES_ERROR,
  FETCH_CLASSES_SUCCESS,
  FETCH_CLASS_ERROR,
  FETCH_CLASS_SUCCESS,
  FETCH_NEXT_CLASSES_SUCCESS,
  UNASSIGN_CLASS_ADVENTURE,
  UNASSIGN_CLASS_ADVENTURE_ERROR,
  UNASSIGN_CLASS_ADVENTURE_SUCCESS,
  UNASSIGN_CLASS_TEACHER,
  UNASSIGN_CLASS_TEACHER_ERROR,
  UNASSIGN_CLASS_TEACHER_SUCCESS,
  UPDATE_CLASS,
  UPDATE_CLASS_ERROR,
  UPDATE_CLASS_SUCCESS,
  UPDATE_EDIT_CLASS_STATUS,
  UPDATE_REMOVE_STUDENT_STATUS,
} from "../actions/types";

const DEFAULT_STATE = {
  loading: false,
  error: null,
  status: "idle",
  pagination: {
    count: 0,
    next: null,
    prev: null,
  },
  data: {},
};

const classesReducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case FETCH_CLASSES:
    case FETCH_CLASS:
    case CREATE_CLASS:
    case UPDATE_CLASS:
    case DELETE_CLASS:
    case ASSIGN_CLASS_TEACHER:
    case UNASSIGN_CLASS_TEACHER:
    case ASSIGN_MULTIPLE_CLASSES_TEACHER:
    case ASSIGN_CLASS_ADVENTURE:
    case ASSIGN_MULTIPLE_CLASSES_ADVENTURE:
    case UNASSIGN_CLASS_ADVENTURE:
      return { ...state, status: "loading", loading: true, error: null };
    case FETCH_CLASSES_SUCCESS:
    case ASSIGN_MULTIPLE_CLASSES_TEACHER_SUCCESS:
    case ASSIGN_MULTIPLE_CLASSES_ADVENTURE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        status: "succeeded",
        pagination: {
          count: action.payload.count,
          next: action.payload.next,
          previous: action.payload.previous,
        },
        data: { ..._.mapKeys(action.payload.results, "id") },
      };
    case FETCH_NEXT_CLASSES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        status: "succeeded",
        pagination: {
          count: action.payload.count,
          next: action.payload.next,
          previous: action.payload.previous,
        },
        data: { ...state.data, ..._.mapKeys(action.payload.results, "id") },
      };
    case FETCH_CLASS_SUCCESS:
    case UPDATE_CLASS_SUCCESS:
    case ASSIGN_CLASS_ADVENTURE_SUCCESS:
    case UNASSIGN_CLASS_ADVENTURE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        status: "succeeded",
        data: {
          ...state.data,
          [action.payload.id]: action.payload,
        },
      };
    case UPDATE_REMOVE_STUDENT_STATUS:
      return {
        ...state,
        loading: false,
        error: null,
        status: "succeeded",
        statusCodeDelete: action.payload,
      };
    case UPDATE_EDIT_CLASS_STATUS:
      return {
        ...state,
        loading: false,
        error: null,
        status: "succeeded",
        statusCode: action.payload,
      };
    case ASSIGN_CLASS_TEACHER_SUCCESS:
    case UNASSIGN_CLASS_TEACHER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        status: "succeeded",
        data: _.omit(state.data, action.payload.id),
      };
    case CREATE_CLASS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        status: "succeeded",
        pagination: {
          ...state.pagination,
          count: state.pagination.count + 1,
        },
        data: {
          ...state.data,
          [action.payload.id]: action.payload,
        },
      };
    case DELETE_CLASS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        status: "succeeded",
        pagination: {
          ...state.pagination,
          count: state.pagination.count - 1,
        },
        data: _.omit(state.data, action.payload),
      };

    case FETCH_CLASSES_ERROR:
    case FETCH_CLASS_ERROR:
    case CREATE_CLASS_ERROR:
    case UPDATE_CLASS_ERROR:
    case DELETE_CLASS_ERROR:
    case ASSIGN_CLASS_TEACHER_ERROR:
    case UNASSIGN_CLASS_TEACHER_ERROR:
    case ASSIGN_MULTIPLE_CLASSES_TEACHER_ERROR:
    case ASSIGN_CLASS_ADVENTURE_ERROR:
    case ASSIGN_MULTIPLE_CLASSES_ADVENTURE_ERROR:
    case UNASSIGN_CLASS_ADVENTURE_ERROR:
      return {
        ...state,
        status: "failed",
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default classesReducer;
