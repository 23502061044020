import { Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

export function ConfirmationDialog({
  title,
  message,
  type = "danger",
  onConfirm,
  yesText,
  onCancel = false,
}) {
  Modal.confirm({
    title: title,
    icon: <ExclamationCircleOutlined />,
    content: message,
    // okText: "Yes",
    okText: yesText,
    //okType: type,
    //cancelText: "No",
    onOk: onConfirm,
    onCancel: onCancel,
    centered: true,
  });
}
export function SuccessDialog({
  title,
  message,
  type = "danger",
  onConfirm,
  onCancel = false,
}) {
  Modal.success({
    title: title,
    content: message,
    okText: "Got it",
    onOk: onConfirm,
    centered: true,
    okType: type,
    cancelText: "No",
  });
}
export function ErrorDialog({
  title,
  message,
  type = "danger",
  onConfirm,
  onCancel = false,
}) {
  Modal.error({
    title: title,
    content: message,
    okText: "Got it",
    onOk: onConfirm,
    centered: true,
    okType: type,
    cancelText: "No",
  });
}
