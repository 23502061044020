import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import Chart from "react-apexcharts";
import { fetchStudent } from "../../actions/student";
import "./ViewStudent.scss";
import Avatar from "../../images/student-avatar.jpg";

const selectStudents = ({ students }) => students.data;

const ViewStudent = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const student = useSelector(selectStudents)?.[id];

  useEffect(() => {
    if (!student) {
      dispatch(fetchStudent(id));
    }
  }, [dispatch, student, id]);

  return (
    <div className="view-student-page flex-column fl-1">
      <div className="flex align-center justify-between mh-24 mt-24">
        <h2>My Students</h2>
      </div>
      {student && (
        <div className="view-student-card flex m-24 fl-1">
          <div className="flex-column fl-1 mr-24">
            <h3 className="mb-24">{student.email}</h3>
            <div className="card mb-24">
              <h3>Word Stats</h3>
              <div className="flex" style={{ height: "240px" }}>
                <Chart
                  type="bar"
                  height={200}
                  width={600}
                  series={[
                    {
                      name: "In Short Memory",
                      data: [44, 55, 57, 56, 61, 58, 63, 60, 66],
                    },
                    {
                      name: "Being Studied",
                      data: [76, 85, 101, 98, 87, 105, 91, 114, 94],
                    },
                    {
                      name: "Learned",
                      data: [35, 41, 36, 26, 45, 48, 52, 53, 41],
                    },
                  ]}
                  options={{
                    chart: {
                      type: "bar",
                      height: 200,
                      toolbar: {
                        show: false,
                      },
                    },
                    plotOptions: {
                      bar: {
                        horizontal: false,
                        columnWidth: "55%",
                        endingShape: "rounded",
                      },
                    },
                    dataLabels: {
                      enabled: false,
                    },
                    stroke: {
                      show: true,
                      width: 2,
                      colors: ["transparent"],
                    },
                    xaxis: {
                      labels: {
                        show: false,
                      },
                      axisBorder: {
                        show: false,
                      },
                      axisTicks: {
                        show: false,
                      },
                      crosshair: {
                        show: false,
                      },
                    },
                    yaxis: {
                      labels: {
                        show: false,
                      },
                    },
                    grid: {
                      show: false,
                    },
                    fill: {
                      colors: ["#606060", "#AC6926", "#EF3779"],
                      opacity: 1,
                    },
                    tooltip: {},
                    colors: ["#606060", "#AC6926", "#EF3779"],
                  }}
                />
              </div>
            </div>
            <div className="flex">
              <div
                className="card fl-1 justify-center mr-24"
                style={{ height: "240px", textAlign: "center" }}
              >
                <h3>Time</h3>
                <h4>Learning</h4>
                <Chart
                  options={{
                    chart: {
                      type: "donut",
                    },
                    plotOptions: {
                      pie: {
                        donut: {
                          labels: {
                            show: true,
                            name: {
                              show: false,
                            },
                            value: {
                              fontSize: "2rem",
                              fontWeight: "bold",
                              formatter: (val) => `${val}h`,
                            },
                          },
                        },
                      },
                    },
                    labels: [""],
                    dataLabels: {
                      enabled: false,
                    },
                    legend: {
                      show: false,
                    },
                    fill: {
                      colors: ["#FFCC60"],
                    },
                  }}
                  series={[45]}
                  type="donut"
                  height={200}
                />
              </div>
              <div
                className="card fl-1 justify-center"
                style={{ height: "240px", textAlign: "center" }}
              >
                <h3>Time</h3>
                <h4>Reviewing</h4>
                <Chart
                  options={{
                    chart: {
                      type: "donut",
                    },
                    plotOptions: {
                      pie: {
                        donut: {
                          labels: {
                            show: true,
                            name: {
                              show: false,
                            },
                            value: {
                              fontSize: "2rem",
                              fontWeight: "bold",
                              formatter: (val) => `${val}h`,
                            },
                          },
                        },
                      },
                    },
                    labels: [""],
                    dataLabels: {
                      enabled: false,
                    },
                    legend: {
                      show: false,
                    },
                    fill: {
                      colors: ["#FFCC60"],
                    },
                  }}
                  series={[25]}
                  type="donut"
                  height={200}
                />
              </div>
            </div>
          </div>
          <div
            className="flex-column align-center"
            style={{
              width: "280px",
            }}
          >
            <img
              src={Avatar}
              style={{
                width: "260px",
              }}
              alt="avatar"
            />
            <h1
              className="mt-24"
              style={{
                textAlign: "center",
              }}
            >
              10
            </h1>
          </div>
        </div>
      )}
    </div>
  );
};

export default ViewStudent;
