import { useEffect, useState } from "react";
import {TailSpin as Loader} from "react-loader-spinner";
import Button from "../../../components/Button/Button";
import { useSelector } from "react-redux";

const bundles = [
  {
    id: "1",
    name: "bundle 1",
  },
  {
    id: "2",
    name: "bundle 2",
  },
  {
    id: "3",
    name: "bundle 3",
  },
];

const BundleForm = ({ onSubmit }) => {

  const apiErrors = useSelector(({ apiErrors }) => apiErrors)

  const [bundle, setBundle] = useState(bundles[0]);
  const [error, setError] = useState("");
  const [schoolName, setSchoolName] = useState('');
  const [loading, setLoading] = useState(false)

  const onFormSubmit = (e) => {
    setError("")
    setLoading(true)
    e.preventDefault();
    onSubmit(bundle, schoolName);
  };

  useEffect(() => {
    if (apiErrors.message) {
      setLoading(false)
      setError('Error Signing Up!')
    }
  }, [apiErrors])

  const renderBundles = () =>
    bundles.map((bundleItem) => (
      <div
        key={bundleItem.id}
        className={`button-card ${bundleItem.id === bundle.id ? "selected" : ""
          }`}
        onClick={() => setBundle(bundleItem)}
      >
        <h3>{bundleItem.name}</h3>
      </div>
    ));

  return (
    <form onSubmit={onFormSubmit} className="flex-column">
      <h1 className="mb-32">Enter School Name</h1>
      {/* <div className="flex mb-32 justify-between align-center">
        {renderBundles()}
      </div> */}
      <input
        placeholder="School Name"
        value={schoolName}
        onChange={(e) => setSchoolName(e.target.value)}
        type="text"
        className="fl-1 mb-16"
      />
      {loading ? <div style={{ alignSelf: "center" }}>
        <Loader
          type="Puff"
          color="#354AC9"
          height={50}
          width={50} //3 secs
        />
      </div> : schoolName &&
        <Button label="Next" primary type="submit" className="mb-16" />
      }
      {error && <p className="font-danger text-center bold">{error}</p>}
    </form>
  );
};

export default BundleForm;
