import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  assignAdventure,
} from '../../actions/classes';
import './AssignAdventures.scss';
import ChooseClass from './Tabs/ChooseClass';
import BundlesAdventures from './Tabs/BundlesAdventures';
import SuccesMulitpleClasses from './Tabs/SuccessMultipleClasses';
import AdventuresAndBundles from './Tabs/AdventuresAndBundles';
import AllAdventuesList from './Tabs/AllAdventuesList';
import BundleList from './Tabs/BundleList';
import Classes from '../Classes/Classes';

const Go = () => {
  const [tab, setTab] = useState(0);
  const [selectedClass, setSelectedClass] = useState('');
  const [adventureList, setAdventureList] = useState('');

  const [bundleList, setBundleList] = useState('');
  const [selectedBundle, setSelectedBundle] = useState('');
  const [selectedAdventures, setSelectedAdventures] = useState('');
  const [bundlesAdventureList, setBundlesAdventureList] = useState('');

  const dispatch = useDispatch();
  const renderTab = tab => {
    const multiClass = false;
    switch (tab) {
      case 0:
        return (
          <AdventuresAndBundles
            adventureTab={() => setTab(1)}
            bundleTab={() => setTab(4)}
          />
        );
      case 1:
        return (
          <AllAdventuesList
            onBack={() => setTab(0)}
            selectedAdventures={selectedAdventures}
            onNext={adventure => {
              setSelectedAdventures(adventure);
              setTab(2);
            }}
          />
        );
      case 2:
        return (
          <ChooseClass
            selectedClass={selectedClass}
            onNext={classSelected => {
              setSelectedClass(classSelected);
              setTab(3);
            }}
            onBack={() => {
              selectedBundle ? setTab(5) : setTab(1);
            }}
          />
        );

      case 3:
        return (
          <SuccesMulitpleClasses
            choice={{
              class: selectedClass,
              adventure: selectedAdventures,
            }}
            selectedClass={selectedClass}
            selectedAdventures={selectedAdventures}
            bundleListFlag={selectedBundle && true}
            selectedBundle={selectedBundle}
            onBack={() => setTab(2)}
            onGo={(aim, startDate, endDate) => {
              const classId = Object.values(selectedClass)[0].id;
              const privateAdventureIds = Object.values(selectedAdventures)
                .filter(adventure => !adventure.bundleName)
                .map(({ id }) => id);
              const bundleAdventuresIds = Object.values(selectedAdventures)
                .filter(adventure => adventure.bundleName)
                .map(({ id }) => id);
              dispatch(
                assignAdventure(
                  classId,
                  privateAdventureIds,
                  bundleAdventuresIds,
                  aim,
                  startDate,
                  endDate,
                  selectedBundle && true
                )
              );
              setSelectedClass('');
              setSelectedAdventures('');
              setTab(6);
            }}
          />
        );
      case 4:
        return (
          <BundleList
            bundleList={bundleList}
            onNext={selectedBundle => {
              setBundlesAdventureList(selectedBundle);
              setSelectedBundle(selectedBundle);
              setTab(5);
            }}
            onBack={() => setTab(0)}
          />
        );

      case 5:
        return (
          <BundlesAdventures
            onBack={() => setTab(4)}
            selectedBundles={selectedBundle}
            onNext={adventure => {
              setSelectedAdventures(adventure);
              setTab(2);
            }}
          />
        );

      case 6:
        return <Classes />;

      default:
        return (
          <AdventuresAndBundles
            adventuresClick={adventure => {
              setAdventureList(adventure);
              setTab(1);
            }}
            bundleClick={adventure => {
              setBundleList(adventure);
              setTab(4);
            }}
          />
        );
    }
  };
  return (
    <div className="go-page flex-column fl-1">
      <div className="p-24">
        <h2>Assign Adventures</h2>
        {renderTab(tab)}
      </div>
    </div>
  );
};

export default Go;
