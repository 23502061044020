import { Link, useNavigate } from "react-router-dom";
import "./ClassListItem.scss";
import IconButton from "../Button/IconButton";
import ClassIllustration from "../../images/class-illustration.jpg";
import classNames from "classnames";

const ClassListItem = ({ classItem, editable, linked, selected, onClick }) => {
  const classes = classNames(
    "class-list-item",
    "mb-32",
    selected && "selected"
  );
  const navigate = useNavigate();
  return (
    <div key={classItem.id} className={classes} onClick={onClick}>
      {linked ? (
        <Link
          to={`/classes/my-classes/class/view/${classItem.id}`}
          className="flex-column"
        >
          <h3 className="mb-12">
            {classItem.name}{" "}
            {classItem.is_default_class ? <span>Default Class</span> : ""}
          </h3>
          <p>Click To preview class</p>
        </Link>
      ) : (
        <div className="flex-column">
          <h3 className="mb-12">
            {classItem.name}{" "}
            {classItem.is_default_class ? <span>Default Class</span> : ""}
          </h3>
          <p>Click to select class</p>
        </div>
      )}

      <div className="flex align-center">
        <img
          src={ClassIllustration}
          alt="class illustration"
          className="icon-48"
        />
        <h1 className="ml-16">{classItem.students.length}</h1>
      </div>
      {editable && (
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();
            navigate(`/classes/my-classes/class/edit/${classItem.id}`);
          }}
          icon="edit"
          className="circle"
        />
      )}
    </div>
  );
};

export default ClassListItem;
