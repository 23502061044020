import gamerize, { gamerizeUnauthenticated } from "../apis/gamerize";
import {
  FETCH_USER,
  LOGIN,
  LOGOUT,
  SET_API_ERROR,
  SIGN_UP,
  UPDATE_USER,
  INSTITUTE_CODE,
} from "./types";

export const login = (username, password) => async (dispatch) => {
  try {
    const response = await gamerizeUnauthenticated.post("/users/login/", {
      username,
      password,
    });
    if (response.data.user_type === "student") {
      dispatch({
        type: SET_API_ERROR,
        payload: {
          error: {
            message: "Invalid Credentials!",
          },
        },
      });
    } else {
      dispatch({
        type: LOGIN,
        payload: response.data,
      });
    }
  } catch (error) {
    if (error.response.status === 400) {
      dispatch({
        type: SET_API_ERROR,
        payload: {
          message: "Invalid Credentials!",
        },
      });
    } else {
      dispatch({
        type: SET_API_ERROR,
        payload: {
          message: "Server Issue",
        },
      });
    }
  }
};

export const signUp = (information, code) => async (dispatch) => {
  try {
    const response = await gamerizeUnauthenticated.post(
      "/users/register/",
      information
    );
    if (code) {
      await gamerizeUnauthenticated.post(
        "/schools/requests/teachers/",
        { school: code },
        {
          headers: {
            Authorization: `JWT ${response.data.access_token}`,
          },
        }
      );
    }
    dispatch({
      type: SIGN_UP,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: SET_API_ERROR,
      payload: {
        message: "There was an error signing up. Please try again.",
      },
    });
  }
};

export const signUpAdmin =
  (information, bundle, schoolName) => async (dispatch) => {
    try {
      const response = await gamerizeUnauthenticated.post(
        "/users/register/",
        information
      );
      if (schoolName) {
        await gamerizeUnauthenticated.post(
          "/schools/schools/",
          {
            name: schoolName,
          },
          {
            headers: {
              Authorization: `JWT ${response.data.access_token}`,
            },
          }
        );
      }
      dispatch({
        type: SIGN_UP,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: SET_API_ERROR,
        payload: {
          message: "There was an error signing up",
        },
      });
    }
  };

export const logout = () => ({ type: LOGOUT });

export const fetchUser = () => async (dispatch, getState) => {
  try {
    const userType = getState().auth.user_type;
    if (!userType) return;
    const response = await gamerize.get(`/users/${userType}s/profile/`);
    const schoolRes = await gamerize.get("/schools/schools/");
    const school = schoolRes.data?.results?.[0];
    dispatch({
      type: FETCH_USER,
      payload: { ...response.data, school },
    });
  } catch (error) {
    dispatch({
      type: LOGOUT,
    });
  }
};

export const updateUser =
  (user, adventureLimit) => async (dispatch, getState) => {
    try {
      const userType = getState().auth.user_type;
      if (!userType) return;
      const response = await gamerize.patch(
        `/users/${userType}s/profile/`,
        user
      );
      if (adventureLimit) {
        const response = await gamerize.patch(
          `/schools/schools/${adventureLimit.school}`,
          {
            adventure_limit: adventureLimit.limit,
          }
        );
        const school = response.data;
        dispatch({
          type: UPDATE_USER,
          payload: { ...response.data, school },
        });
      } else {
        dispatch({
          type: UPDATE_USER,
          payload: response.data,
        });
      }
    } catch (error) {
      dispatch({
        type: SET_API_ERROR,
        payload: {
          message: "An error occured while updating the user",
        },
      });
    }
  };

export const generateNewCode = () => async (dispatch) => {
  try {
    const response = await gamerize.post("/schools/schools/change_code/");
    dispatch({
      type: INSTITUTE_CODE,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: SET_API_ERROR,
      payload: {
        message: "An error occured while getting the institute code",
      },
    });
  }
};
