import { useDispatch, useSelector } from "react-redux";
import { createAdventure } from "../../actions/adventures";
import AdventureForm from "../../components/AdventureForm/AdventureForm";
import "./NewAdventure.scss";

const selectAdventuresStatus = ({ adventures }) => adventures.status

const NewAdventure = () => {
  const dispatch = useDispatch();
  const adventuresStatus = useSelector(selectAdventuresStatus);
  return (
    <div className="flex-column fl-1">
      <h2 className="p-24">Create Adventure</h2>
      {adventuresStatus === 'failed' && <p className="ph-24 error-message">Error creating Adventure</p>}
      <AdventureForm
        className="flex-column fl-1 overflow-hidden"
        onSubmit={(adventure) => dispatch(createAdventure(adventure))}
        status={adventuresStatus}
      />
    </div>
  );
};

export default NewAdventure;
