import { useState, useEffect } from 'react';
import {TailSpin as Loader} from 'react-loader-spinner';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import moment from 'moment';
import { Table as AntTable, Select, Modal } from 'antd';
import { useDispatch } from 'react-redux';

import gamerize from '../../apis/gamerize';
import Button from '../../components/Button/Button';
import { addStudentAdventure, removeStudentAdventure } from '../../actions/adventures';
import { ErrorDialog } from '../../components/ConfirmationDialog';
import Util from "../../components/util/index";

const IncompleteAdvenures = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [adventuresData, setAdventuresData] = useState('');
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [selected, setSelected] = useState([]);
  const [assignAdventure, setAssignAdventure] = useState(false);
  const [adventureId, setAdventureId] = useState('');

  const offset = (page - 1) * pageSize;
  const [adventureFilterValue, setAdventureFilterValue] = useState('all_adventures');
  const { id } = useParams();
  const { Option } = Select;
  const [fetchUrl, setfetchUrl] = useState(`all_adventures`);
  const [visible, setVisible] = useState(false);

  const columns = [
    {
      title: 'Adventure Id',
      dataIndex: 'adventure_id',
      key: 'adventure_id',
    },

    {
      title: 'Name',
      dataIndex: 'name',
      width: 260,
      key: 'name',
    },
    {
      title: 'Completed Games',
      dataIndex: 'completed_games',
      key: 'completed_games',
      fixed: 'right',
    },
    {
      title: 'Partial Wins',
      dataIndex: 'partial_wins',
      key: 'partial_wins',
      fixed: 'right',
    },
    {
      title: "Start Date",
      dataIndex: "start_date",
      defaultSortOrder: "descend",
      render: (value) => Util.formatDate(value),
    },
    {
      title: "End Date",
      dataIndex: "end_date",
      defaultSortOrder: "descend",
      render: (value) => Util.formatDate(value),
    },
    {
      title: 'Total Times Played',
      dataIndex: 'total_times_played',
      key: 'total_times_played',
      fixed: 'right',
    },
    {
      title: 'Action',
      key: 'action',
      width: 160,
      fixed: 'right',
      render: value => (
        <span className="table-action-buttons">
          <div
            className="text-button mr-10"
            onClick={() => applyConfirmationHandler(value)}
          >
            Remove
          </div>
        </span>
      ),
    },
  ];

  const error = message => {
    Modal.error({
      content: message,
    });
  };

  const handleChange = value => {
    setAdventureFilterValue(value);
    setPageSize(10);
    if (!value) {
      setfetchUrl('all_adventures');
      fetchAdventures('all_adventures');
    } else {
      setfetchUrl(value);
      fetchAdventures(value);
    }
  };

  const handlePagination = () => {
    return {
      current: page,
      pageSize: pageSize,
      total: adventuresData.count,
      onChange: (AdventurePage, AdventurePageSize) => {
        setPage(AdventurePage);
        setPageSize(AdventurePageSize);
      },
    };
  };

  useEffect(() => {
    if (location?.state?.adventure?.id) {
      my_async_fn();
      setAssignAdventure(false);
    }
  }, [location]);

  async function my_async_fn() {
    const response = await dispatch(addStudentAdventure(location.state?.adventure.id, id));
    if (response?.status === 200) {
      success('Succesfully Assigned');
      setTimeout(() => {
        fetchAdventures('all_adventures');
      }, 1000);
    }
    if (response?.status >= 400 || !response) {
      error('Error Assigning Adventure');
    }
  }

  useEffect(() => {
    fetchAdventures();
  }, [pageSize, id, offset]);

  const applyConfirmationHandler = value => {
    console.log(value);
    setVisible(true);
    setAdventureId(value.adventure_id);
  };

  const displayAdventureType = () => {
    if (adventureFilterValue === 'pending_adventures') {
      return 'Incompleted Adventures';
    }
    if (adventureFilterValue === 'completed_adventures') {
      return 'Completed Adventures';
    }
    if (adventureFilterValue === 'app_adventures_listing') {
      return 'App Adventures Listing';
    }

    return 'All Adventures';
  };

  const success = message => {
    Modal.success({
      content: message,
    });
  };

  const applyHandler = () => {
    setVisible(false);
    async function remove_async() {
      const response = await dispatch(removeStudentAdventure(adventureId, id));
      console.log(response);
      if (response?.status === 200) {
        setTimeout(() => {
          fetchAdventures('all_adventures');
        }, 1000);
        success('Succesfully Removed');
      }
      if (response?.status >= 400 || !response) {
        error('Error Removing Adventure');
      }
    }
    remove_async();
  };

  const cancelHandler = () => {
    setVisible(false);
    setAssignAdventure(false);
    setSelected([]);
  };

  const fetchAdventures = value => {
    let searchUrl = '';
    if (value) {
      searchUrl = value;
    } else {
      searchUrl = fetchUrl;
    }

    const fetchRequest = async () => {
      try {
        setLoading(true);
        const res = await gamerize.get(
          `/analytics/student_analytics/${id}/${searchUrl}/?limit=${pageSize}&offset=${offset}`
        );
        if (res.data.results.length > 0) {
          setAdventuresData(res.data);
        } else {
          setAdventuresData('');
        }
        setLoading(false);
      } catch (error) {
        ErrorDialog({
          title: error.response.data,
        });
        setLoading(false);
      }
    };

    fetchRequest();
  };

  const renderIncompleteAdventures = () => (
    <AntTable
      title={null}
      pagination={handlePagination()}
      columns={columns}
      dataSource={adventuresData?.results}
      showHeader
      size="default"
      bordered={false}
    />
  );
  const assignAdventureHandler = () => {
    navigate(`/classes/students-adventure`,{state:{id:id}});
  };

  return (
    <div className="flex-column fl-1 pt-24">
      <h2 className="ml-16 mb-24">{displayAdventureType()}</h2>
      <div className="flex flex-end-self">
        <Button
          label={assignAdventure ? 'Cancel' : 'Assign Adventure'}
          primary
          className={`mr-24 ${assignAdventure ? 'btn-color-danger' : ''}`}
          disabled={adventuresData === null || adventuresData === ''}
          onClick={() => assignAdventureHandler()}
        />
      </div>
      <Modal
        title="Confirmation Message"
        centered
        visible={visible}
        onOk={() => applyHandler()}
        onCancel={() => cancelHandler()}
        width={700}
      >
        <h3>
          Are You Sure You want to {assignAdventure ? 'Add' : 'Remove'} selected
          Adventure?
        </h3>
      </Modal>
      <h4 className="ml-16">
        <span className="total_students mr-8 mb-24">Total number of Adventures :</span>
        {adventuresData?.count}
      </h4>
      <div className="flex flex-end-self pv-16 ph-24">
        <Select
          placeholder="Adventure Filters"
          style={{
            width: 300,
          }}
          onChange={handleChange}
          value={adventureFilterValue}
        >
          <Option value="all_adventures">All Adventures</Option>
          <Option value="completed_adventures">Completed Adventures</Option>
          <Option value="pending_adventures">Incompleted Adventures</Option>
          <Option value="app_adventures_listing">App Listing</Option>
        </Select>
      </div>
      {loading ? (
        <div className="flex flex-center fl-1">
          <Loader type="Puff" color="#354AC9" height={50} width={50} />
        </div>
      ) : (
        <div>{renderIncompleteAdventures()}</div>
      )}
    </div>
  );
};

export default IncompleteAdvenures;
