import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import {
  assignTeacher,
  assignTeacherMultipleClasses,
  fetchAssignedClasses,
} from "../../actions/classes";
import { fetchNextTeachers, fetchTeachers } from "../../actions/teachers";
import ChooseClass from "./Tabs/ChooseClass";
import ChooseTeacher from "./Tabs/ChooseTeacher";
import SuccesMulitpleClasses from "./Tabs/SuccessMultipleClasses";
import SuccessSingleClass from "./Tabs/SuccessSingleClass";
import TabError from "../../components/TabError/TabError";
import {TailSpin as Loader} from "react-loader-spinner";
import { ErrorDialog } from "../../components/ConfirmationDialog";

const selectClasses = ({ classes }) => classes.data;
const selectClassesPagination = ({ classes }) => classes.pagination;
const selectClassesLoading = ({ classes }) => classes.loading;
const selectClassesError = ({ classes }) => classes.error;

const selectClassList = createSelector([selectClasses], (classes) =>
  Object.values(classes)
);

const selectTeachers = ({ teachers }) => teachers.data;
const selectTeachersPagination = ({ teachers }) => teachers.pagination;
const selectTeachersLoading = ({ teachers }) => teachers.loading;
const selectTeachersError = ({ teachers }) => teachers.error;

const selectTeacherList = createSelector([selectTeachers], (teachers) =>
  Object.values(teachers)
);

const AssignTeacherForm = () => {
  const dispatch = useDispatch();
  const classes = useSelector(selectClassList);
  const classesPagination = useSelector(selectClassesPagination);
  const classesLoading = useSelector(selectClassesLoading);
  const classesError = useSelector(selectClassesError);

  const teachers = useSelector(selectTeacherList);
  const teachersPagination = useSelector(selectTeachersPagination);
  const teachersLoading = useSelector(selectTeachersLoading);
  const teachersError = useSelector(selectTeachersError);

  useEffect(() => {
    dispatch(fetchAssignedClasses());
    dispatch(fetchTeachers());
  }, [dispatch]);

  const [tab, setTab] = useState(0);
  const [selectedClass, setSelectedClass] = useState("");
  const [selectedTeacher, setSelectedTeacher] = useState("");

  const renderTab = (tab) => {
    switch (tab) {
      case 0:
        return (
          <ChooseClass
            classes={classes}
            selectedClass={selectedClass}
            onNext={(classSelected) => {
              setSelectedClass(classSelected);
              setTab(1);
            }}
            classesPagination={classesPagination}
          />
        );
      case 1:
        return (
          <ChooseTeacher
            teachers={teachers}
            teachersPagination={teachersPagination}
            loadMore={() => dispatch(fetchNextTeachers())}
            onBack={() => setTab(0)}
            selectedTeacher={selectedTeacher}
            onNext={(teacher) => {
              setSelectedTeacher(teacher);
              Object.keys(selectedClass).length > 1 ? setTab(4) : setTab(3);
            }}
          />
        );
      case 3:
        return (
          <SuccessSingleClass
            choice={{
              class: Object.values(selectedClass)[0],
              teacher: selectedTeacher,
            }}
            onBack={() => setTab(1)}
            onAssign={() => {
              const classId = Object.values(selectedClass)[0].id;
              const teacherId = selectedTeacher.id;
              console.log(classId, teacherId);
              if (!window.navigator.onLine) {
                ErrorDialog({
                  title: "You're offline right now. Check your connection.",
                });
              } else {
                dispatch(assignTeacher(classId, teacherId));

                setSelectedClass("");
                setSelectedTeacher("");
                setTab(0);
              }
            }}
          />
        );
      case 4:
        return (
          <SuccesMulitpleClasses
            choice={{ class: selectedClass, teacher: selectedTeacher }}
            onBack={() => setTab(1)}
            onAssign={() => {
              const classIds = Object.keys(selectedClass);
              const teacherId = selectedTeacher.id;
              if (!window.navigator.onLine) {
                ErrorDialog({
                  title: "You're offline right now. Check your connection.",
                });
              } else {
                dispatch(assignTeacherMultipleClasses(classIds, teacherId));

                setSelectedClass("");
                setSelectedTeacher("");
                setTab(0);
              }
            }}
          />
        );
      default:
        return (
          <ChooseClass
            selectedClass={selectedClass}
            onNext={(classSelected) => {
              setSelectedClass(classSelected);
              setTab(1);
            }}
          />
        );
    }
  };

  if (classesLoading || teachersLoading) {
    return (
      <div className="flex flex-center fl-1">
        <Loader type="Puff" color="#354AC9" height={50} width={50} />
      </div>
    );
  } else {
    if (classesError || teachersError) {
      return (
        <TabError
          onClick={() => {
            if (classesError) {
              dispatch(fetchAssignedClasses());
            }
            if (teachersError) {
              dispatch(fetchTeachers());
            }
          }}
          message={classesError?.message || teachersError?.message}
          label="Reload Adventures"
        />
      );
    } else {
      return <Fragment>{renderTab(tab)}</Fragment>;
    }
  }
};

export default AssignTeacherForm;
