import { Fragment, useCallback, useEffect, useState } from "react";
import gamerize from "../../apis/gamerize";
import TabError from "../../components/TabError/TabError";
import { stringMatch } from "../../utils";
import "./TeachersRequests.scss";
import {TailSpin as Loader} from "react-loader-spinner";
import { Table as AntTable, Dropdown, Menu, Input } from "antd";
import { DownOutlined } from "@ant-design/icons";
import {
  ConfirmationDialog,
  ErrorDialog,
} from "../../components/ConfirmationDialog";

const TeachersRequests = () => {
  const [fetchedTeachers, setFetchedTeachers] = useState([]);

  const [teachers, setTeachers] = useState([]);
  const [search, setSearch] = useState("");

  const [loadingRequests, setLoadingRequests] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const { Search } = Input;

  const offset = (page - 1) * pageSize;

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "First Name",
      dataIndex: "first_name",
      key: "first_name",
    },

    {
      title: "Last Name",
      dataIndex: "last_name",
      key: "last_name",
    },
    {
      title: "Action",
      key: "action",
      width: 160,
      fixed: "right",
      render: (value) => (
        <span>
          <div
            className="text-button mr-10"
            onClick={() => onAddTeacher(value.requestID)}
          >
            Add
          </div>
          <Dropdown
            trigger={["click"]}
            className="table-more-button"
            overlay={
              <Menu className="actions-menu">
                <Menu.Item
                  className="negative delete"
                  onClick={() => onRemoveTeacher(value.requestID)}
                >
                  Delete
                </Menu.Item>
              </Menu>
            }
          >
            <span className="text-button ant-dropdown-link">
              More <DownOutlined />
            </span>
          </Dropdown>
        </span>
      ),
    },
  ];

  const fetchRequests = useCallback(async () => {
    setLoadingRequests(true);
    try {
      const res = await gamerize.get(
        `/schools/requests/owners/?status=pending&offset=${offset}`
      );
      const list = res.data.results.map(({ id, teacher }) => ({
        ...teacher,
        requestID: id,
      }));
      setFetchedTeachers(list);
      setLoadingRequests(false);
    } catch (error) {
      setError("There was an error fetching requests");
      setLoadingRequests(false);
    }
  }, [offset, pageSize]);

  useEffect(() => {
    fetchRequests();
  }, [fetchRequests]);

  useEffect(() => {
    setTeachers(Object.values(fetchedTeachers));
  }, [fetchedTeachers]);

  useEffect(() => {
    fetchTeacherData()
  }, [fetchedTeachers]);

  const onAddTeacher = (id) => {
    ConfirmationDialog({
      title: "Are You Sure?",
      onConfirm: async () => {
        await gamerize.post(
          `/schools/requests/owners/${id}/accept_request/`,
          {}
        );
        const res = await gamerize.get(
          "/schools/requests/owners/?status=pending"
        );
        const list = res.data.results.map(({ id, teacher }) => ({
          ...teacher,
          requestID: id,
        }));
        setFetchedTeachers(list);
      },
    });
  };

  const fetchTeacherData = () =>{
    setTeachers(
      Object.values(fetchedTeachers).filter(({ first_name }) =>
        stringMatch(first_name, search)
      )
    );
  }
  
  const onSearch = value => {
    fetchTeacherData()
    setPage(1);
  };

  const handlePagination = () => {
    return {
      current: page,
      pageSize: pageSize,
      total: fetchedTeachers.length,
      onChange: (teacherPage, teacherPageSize) => {
        setPage(teacherPage);
        setPageSize(teacherPageSize);
      },
    };
  };

  const onRemoveTeacher = (id) => {
    ConfirmationDialog({
      title: "Are You Sure?",
      onConfirm: async () => {
        if (!window.navigator.onLine) {
          ErrorDialog({
            title: "You're offline right now. Check your connection.",
          });
        } else {
          await gamerize.post(
            `/schools/requests/owners/${id}/reject_request/`,
            {}
          );
          const res = await gamerize.get(
            "/schools/requests/owners/?status=pending"
          );
          const list = res.data.results.map(({ id, teacher }) => ({
            ...teacher,
            requestID: id,
          }));
          setFetchedTeachers(list);
        }
      },
    });
  };

  const renderTeachersList = () =>
    teachers.length > 0 ? (
      <AntTable
        title={null}
        pagination={handlePagination()}
        columns={columns}
        dataSource={teachers}
        showHeader
        size="default"
        bordered={false}
      />
    ) : (
      <div className="p-24">
        <h1>No Pending Requests</h1>
      </div>
    );

  return (
    <div className="teachers-requests-page flex-column fl-1 pt-24">
      {loadingRequests ? (
        <div className="flex flex-center fl-1">
          <Loader type="Puff" color="#354AC9" height={50} width={50} />
        </div>
      ) : error ? (
        <TabError
          onClick={() => fetchRequests()}
          message={error}
          label="Reload Teachers"
        />
      ) : (
        <Fragment>
          <div className="flex align-center justify-between mb-16 mh-24">
            <h2>Teachers Requests</h2>
          </div>
          <div className="flex align-center justify-between mb-8 mh-24">
            <Search
              placeholder="Search Teachers"
              enterButton="Search"
              size="large"
              onSearch={onSearch}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              />
          </div>
          <div className="scroll-vertical fl-1">{renderTeachersList()}</div>
        </Fragment>
      )}
    </div>
  );
};

export default TeachersRequests;
