import { Fragment, useEffect, useState } from "react";
import {TailSpin as Loader} from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { createSelector } from "reselect";
import { fetchBundles } from "../../actions/bundles";
import TabError from "../../components/TabError/TabError";
import { Table as AntTable, Image, Input } from "antd";
import 'antd/dist/reset.css';
import "./Bundles.scss";

const selectBundles = ({ bundles }) => bundles.data;
const selectBundlesPagination = ({ bundles }) => bundles.pagination;
const selectBundlesLoading = ({ bundles }) => bundles.loading;
const selectBundlesError = ({ bundles }) => bundles.error;

const bundlesArray = createSelector([selectBundles], (bundles) =>
  Object.values(bundles)
);

const Bundles = () => {

  const bundlesData = useSelector(bundlesArray);
  const bundlesPagination = useSelector(selectBundlesPagination);
  const bundlesLoading = useSelector(selectBundlesLoading);
  const bundlesError = useSelector(selectBundlesError);
  const { Search } = Input;
  const dispatch = useDispatch();

  const [search, setSearch] = useState("");
  const [bundles, setBundles] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const offset = (page - 1) * 10;

  useEffect(() => {
    dispatch(fetchBundles(search, pageSize, offset));
  }, [dispatch, page]);

  useEffect(() => {
    setBundles(bundlesData);
  }, [bundlesData]);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Image",
      dataIndex: "cover_image",
      width: 180,
      render: (value) => (
        <Image
          src={value}
          height={75}
          width={75}
          style={{ objectFit: "contain" }}
        />
      ),
    },
    {
      title: "Action",
      key: "action",
      width: 130,
      fixed: "right",
      render: (value) => (
        <span className="table-action-buttons">
          <Link
            className="text-button view"
            style={{ color: "white" }}
            to={`/adventures/bundles/view/${value.id}`}
          >
            View
          </Link>
        </span>
      ),
    },
  ];

  const handlePagination = () => {
    return {
      current: page,
      pageSize: pageSize,
      total: bundlesPagination.count,
      onChange: (page, pageSize) => {
        setPage(page);
        setPageSize(pageSize);
      },
    };
  };

  const renderBundleList = () => (
    <AntTable
      title={null}
      pagination={handlePagination()}
      columns={columns}
      dataSource={bundles}
      showHeader
      size="default"
      bordered={false}
    />
  );

  const onSearch = value => {
    dispatch(fetchBundles(search, pageSize, offset));
    setPage(1);
  };

  return (
    <div className="bundles-page flex-column fl-1">
      <h1 className="mh-24 mt-24 mb-16">Bundle List</h1>

      {bundlesLoading ? (
        <div className="flex flex-center fl-1">
          <Loader type="Puff" color="#354AC9" height={50} width={50} />
        </div>
      ) : bundlesError ? (
        <TabError
          onClick={() => dispatch(fetchBundles())}
          message={bundlesError.message}
          label="Reload Bundle"
        />
      ) : (
        <Fragment>
          <Search
            className="ph-24 pv-16 ml-8"
            placeholder="Search Bundles"
            enterButton="Search"
            size="large"
            onSearch={onSearch}
            value={search}
            onChange={e => setSearch(e.target.value)}
          />
          <div className="overflow-hidden flex-column fl-1">
            <div className="flex justify-between align-center ph-24 pv-16 ml-8">
              <h2>Bundles</h2>
            </div>
            <div id="scrollableContainer" className="list-container fl-1 ml-24">
              {renderBundleList()}
            </div>
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default Bundles;
