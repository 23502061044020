import { useState } from "react";
import Button from "../../../components/Button/Button";
import TeacherListItem from "../../../components/TeacherListItem/TeacherListItem";
import {TailSpin as Loader} from "react-loader-spinner";
import InfiniteScroll from 'react-infinite-scroll-component';


const ChooseTeacher = ({ teachers, onNext, onBack, selectedTeacher = null, teachersPagination, loadMore }) => {
  const [selected, setSelected] = useState(selectedTeacher);

  const renderTeacherList = () => (
    <InfiniteScroll
      className="p-24"
      dataLength={teachers.length}
      next={loadMore}
      hasMore={teachersPagination.next ? true : false}
      loader={
        <div className="flex flex-center">
          <Loader
            type="Puff"
            color="#354AC9"
            height={50}
            width={50}
          />
        </div>
      }
      scrollableTarget="scrollableContainer"
      endMessage={
        <p style={{ textAlign: 'center' }}>
          <b>End of List</b>
        </p>
      }>

      {teachers.map((teacher) => (
        <TeacherListItem
          key={teacher.id}
          onClick={() => setSelected(teacher)}
          selected={selected && selected.id === teacher.id}
          teacher={teacher}
        />
      ))}
    </InfiniteScroll>
  );

  return (
    <div className="flex-column fl-1 overflow-hidden">
      <div className="flex-column mb-16 mh-24">
        <h3 className="mb-16">Step 2: Choose Teacher</h3>
      </div>
      <div id="scrollableContainer" className="list-container fl-1">{renderTeacherList()}</div>
      <div className="flex justify-end mb-24 mh-24">
        <Button label="Back" primary onClick={onBack} className="mr-24" wide />
        <Button label="Next" primary onClick={() => onNext(selected)} wide disabled={!selected} />
      </div>
    </div>
  );
};

export default ChooseTeacher;
