import "./AssignTeacher.scss";
import AssignTeacherForm from "./AssignTeacherForm";

const AssignTeacher = () => {
  return (
    <div className="go-page page flex-column fl-1">
      <div className="p-24">
        <h2 className="mb-8">Assign Teachers!</h2>
        <p className="mb-16"> Assign Classes to teachers</p>
      </div>
      <AssignTeacherForm />;
    </div>
  );
};

export default AssignTeacher;
