import { useState } from "react";
import ChooseClass from "../AssignAdventures/Tabs/ChooseClass";
import BundlesAdventures from "../AssignAdventures/Tabs/BundlesAdventures";
import AdventuresAndBundles from "../AssignAdventures/Tabs/AdventuresAndBundles";
import AllAdventuesList from "../AssignAdventures/Tabs/AllAdventuesList";
import BundleList from "../AssignAdventures/Tabs/BundleList";
import Summary from "./Tabs/Summary";
import "./NewClassBattle.scss";
import Game from "./Tabs/Game/Game";
import ChooseMonster from "./Tabs/Monsters/MonsterList";
const Go = () => {
  const [tab, setTab] = useState(0);

  const [selectedClass, setSelectedClass] = useState("");
  const [adventureList, setAdventureList] = useState("");
  const [battleType, setBattleType] = useState("");

  const [bundleList, setBundleList] = useState("");
  const [selectedBundle, setSelectedBundle] = useState("");
  const [selectedAdventures, setSelectedAdventures] = useState("");
  const [bundlesAdventureList, setBundlesAdventureList] = useState("");
  const [selectedMonsterId, setSelectedMonsterId] = useState(null);
  const [isBundleAdventure, setIsBundleAdventure] = useState(false);

  const getAdventure = () => {
    if (battleType !== "adventure_battle") {
      return null;
    }
    if (!isBundleAdventure) return selectedAdventures;
    return bundlesAdventureList;
  };

  const renderTab = (tab) => {
    switch (tab) {
      case 0:
        return (
          <ChooseClass
            selectedClass={selectedClass}
            onNext={(classSelected) => {
              setSelectedClass(classSelected);
              setTab(1);
            }}
            classBattle={true}
          />
        );
      case 1:
        return (
          <AdventuresAndBundles
            adventureTab={() => {
              setBattleType("adventure_battle");
              setTab(2);
            }}
            bundleTab={() => {
              setBattleType("adventure_battle");
              setTab(4);
            }}
            onBack={() => {
              setTab(0);
              setSelectedAdventures("");
              setBattleType("");
            }}
            reviewTab={() => {
              setBattleType("review_battle");
              setTab(3);
            }}
            isReview={true}
            multiClass
          />
        );
      case 2:
        return (
          <AllAdventuesList
            onBack={() => {
              setTab(1);
              setSelectedAdventures("");
            }}
            selectedAdventures={selectedAdventures}
            selectionList={selectedAdventures}
            multiClass
            onNext={(adventure) => {
              setSelectedAdventures(adventure);
              setTab(3);
            }}
            classBattle={true}
          />
        );

      case 3:
        return (
          <ChooseMonster
            selectedMonsterId={selectedMonsterId}
            setSelectedMonsterId={setSelectedMonsterId}
            battleType={battleType}
            onBack={() => {
              if (battleType === "review_battle") {
                setSelectedMonsterId(null);
                setTab(1);
              } else if (selectedBundle && isBundleAdventure) {
                setSelectedMonsterId(null);
                setTab(5);
              } else {
                setSelectedMonsterId(null);
                setTab(2);
              }
            }}
            onCreate={() => {
              if (battleType === "review_battle") {
                if (selectedMonsterId) {
                  setTab(6);
                }
              } else {
                if (selectedMonsterId) {
                  setTab(7);
                }
              }
            }}
          ></ChooseMonster>
        );

      case 4:
        return (
          <BundleList
            bundleList={bundleList}
            onNext={(selectedBundle) => {
              setSelectedBundle(selectedBundle);
              setIsBundleAdventure(true);
              setTab(5);
            }}
            onBack={() => {
              setTab(1);
              setIsBundleAdventure(false);
            }}
            classBattle={true}
          />
        );

      case 5:
        return (
          <BundlesAdventures
            onBack={() => {
              setTab(4);
              setBundlesAdventureList("");
            }}
            selectedBundles={selectedBundle}
            selectedAdventure={bundlesAdventureList}
            onNext={(bundlesAdventureList) => {
              setBundlesAdventureList(bundlesAdventureList);
              setTab(3);
            }}
            classBattle={true}
            multiClass
          />
        );

      case 6:
        return (
          <Game
            selectedClass={selectedClass}
            monster_id={selectedMonsterId}
            battle_type={battleType}
            adventure={getAdventure()}
          ></Game>
        );

      case 7:
        return (
          <Summary
            selectedClass={selectedClass}
            selectedAdventures={
              isBundleAdventure ? bundlesAdventureList : selectedAdventures
            }
            onBack={() => setTab(3)}
            onNext={() => setTab(6)}
          />
        );

      default:
        return (
          <AdventuresAndBundles
            adventuresClick={(adventure) => {
              setAdventureList(adventure);
              setTab(1);
            }}
            bundleClick={(adventure) => {
              setBundleList(adventure);
              setTab(4);
            }}
          />
        );
    }
  };
  return (
    <div className="go-page flex-column fl-1 display">
      <div className="p-24">
        <h2 className="ml-10">New Class Battle</h2>
        {renderTab(tab)}
      </div>
    </div>
  );
};

export default Go;
