import Select from "react-select";
import { useState } from "react";
import { useSelector } from "react-redux";
import Button from "../../../components/Button/Button";
import { aims } from "../../../constants";

const Summary = ({ selectedClass, selectedAdventures, onBack, onNext }) => {
  const selectedAdventure = Object.values(selectedAdventures);
  const selectAdventuresError = ({ adventures }) => adventures.error;
  const adventuresError = useSelector(selectAdventuresError);

  const [aim, setAim] = useState(selectedAdventure[0].learning_aim);
  const [error, setError] = useState(false);

  const handleCreate = () => {
    if (aim) {
      selectedAdventure[0].learning_aim = aim;
      onNext();
      if (adventuresError) {
        setError("Failed to Assign Adventure");
      }
    }
  };

  const handleAim = (value) => {
    setAim(value);
  };

  return (
    <div className="go-choose-adventure flex-column fl-1">
      <h1 className="mb-16 text-heading mg-top ml-10 mr-24">Summary</h1>
      <div className="go-success-card ml-10 mr-24">
        <h2 className="mb-8 text-heading">Class Name:</h2>

        <p className="mb-16">{Object.values(selectedClass)[0].name}</p>

        <div className="flex">
          <h2 className="mb-8 mt-8 text-heading" style={{ width: "300px" }}>
            Adventure Name:
          </h2>{" "}
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </div>
        {selectedAdventure && (
          <div className="flex">
            <div style={{ width: "300px" }}>{selectedAdventure[0].name}</div>{" "}
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </div>
        )}

        <br />
        <h2 className="mb-8 text-heading">
          {selectedAdventure[0].learning_aim !== null
            ? `Learning Aim`
            : `Pick a learnings Aim:`}
        </h2>
        <div>
          <>
            {selectedAdventure[0].learning_aim !== null ? (
              <p>{selectedAdventure[0].learning_aim}</p>
            ) : (
              <Select
                options={aims}
                onChange={({ value }) => handleAim(value)}
                defaultValue={aims[0]}
                className="adventures-list-filter"
                isDisabled={selectedAdventure[0].learning_aim !== null}
              />
            )}
          </>
        </div>

        <br />

        {error && <p className="font-danger text-center bold">{error}</p>}
      </div>

      <div className="flex justify-end mt-auto mh-24 mb-24 summary-btns">
        <Button label="Back" primary onClick={onBack} className="mr-24" wide />
        <Button
          label="Create"
          primary
          onClick={handleCreate}
          wide
          disabled={!aim}
        />
      </div>
    </div>
  );
};

export default Summary;
