import classNames from "classnames";
import "./Button.scss";

const IconButton = ({ icon, primary, danger, className, ...rest }) => {
  const cssClasses = classNames(
    "btn-icon",
    `icon-${icon}`,
    primary && "btn-primary",
    danger && "btn-danger",
    className
  );
  return <div className={cssClasses} {...rest} />;
};

export default IconButton;
