import { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { editAdventure, fetchAdventure } from "../../actions/adventures";
import AdventureForm from "../../components/AdventureForm/AdventureForm";
import "./EditAdventure.scss";
import { createSelector } from "reselect";
import TabError from "../../components/TabError/TabError";
import {TailSpin as Loader} from "react-loader-spinner";

const selectAdventures = ({ adventures }) => adventures.data;
const selectAdventuresLoading = ({ adventures }) => adventures.loading
const selectAdventuresError = ({ adventures }) => adventures.error
const selectAdventuresStatus = ({ adventures }) => adventures.status

const adventureByID = (id) =>
  createSelector([selectAdventures], (adventures) => adventures[id]);

const EditAdventure = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const adventure = useSelector(adventureByID(id));
  const adventuresLoading = useSelector(selectAdventuresLoading)
  const adventuresError = useSelector(selectAdventuresError)
  const adventuresStatus = useSelector(selectAdventuresStatus);

  useEffect(() => {
    dispatch(fetchAdventure(id));
  }, [dispatch, id]);

  if (!adventure) {
    return null;
  }

  return (
    <div className="flex-column fl-1">
      {adventuresLoading ? (
        <div className="flex flex-center fl-1">
          <Loader
            type="Puff"
            color="#354AC9"
            height={50}
            width={50}
          />
        </div>
      ) :
        adventuresError ? (
          <TabError
            onClick={() => dispatch(fetchAdventure(id))}
            message={adventuresError.message}
            label="Reload Adventures" />
        ) : (
          <Fragment>
            <h2 className="p-24">Edit Adventure</h2>
            <AdventureForm
              className="flex-column fl-1 overflow-hidden"
              adventure={{ ...adventure, aim: { aim: adventure.learning_aim } }}
              onSubmit={(adventure) =>
                dispatch(editAdventure(id, { ...adventure, id }))
              }
              edit
              status={adventuresStatus}
            />
          </Fragment>
        )
      }
    </div>
  );
};

export default EditAdventure;
