import gamerize from '../apis/gamerize';
import { ErrorDialog, SuccessDialog } from '../components/ConfirmationDialog';
import {
  CREATE_ADVENTURE,
  CREATE_ADVENTURE_ERROR,
  CREATE_ADVENTURE_SUCCESS,
  DELETE_ADVENTURE,
  DELETE_ADVENTURE_ERROR,
  DELETE_ADVENTURE_SUCCESS,
  FETCH_ADVENTURE,
  FETCH_ADVENTURES,
  FETCH_ADVENTURES_ERROR,
  FETCH_ADVENTURES_SUCCESS,
  FETCH_ADVENTURE_ERROR,
  FETCH_ADVENTURE_SUCCESS,
  FETCH_NEXT_ADVENTURES_SUCCESS,
  UPDATE_ADVENTURE,
  UPDATE_ADVENTURE_ERROR,
  UPDATE_ADVENTURE_SUCCESS,
} from './types';

export const fetchAdventures =
  (searchAdventure = '', limit = 20, offset = 0, filter) =>
  async dispatch => {
    let is_cloned_adventures = null;
    let is_expired_adventures = null;
    let is_active_adventures = null;
    if (filter === 'cloned_adventures') {
      is_cloned_adventures = 1;
    } else if (filter === 'expired_adventures') {
      is_expired_adventures = 1;
    }
    else if (filter === 'active_adventures') {
      is_active_adventures = 1;
    }
    dispatch({ type: FETCH_ADVENTURES });
    try {
      const response = await gamerize.get(
        `/adventures/adventures/?limit=${limit}&offset=${offset}&search=${searchAdventure}&is_cloned_adventures=${is_cloned_adventures}&is_expired_adventures=${is_expired_adventures}&is_active_adventures=${is_active_adventures}&public_only=false`
      );
      dispatch({
        type: FETCH_ADVENTURES_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: FETCH_ADVENTURES_ERROR,
        payload: {
          message: 'Error fetching adventures.',
        },
      });
    }
  };

export const fetchNextAdventures = () => async (dispatch, getState) => {
  const { next } = getState().adventures.pagination;
  if (!next) return;
  const query = next.split('?')[1] || '';
  console.log('query', query);
  try {
    const response = await gamerize.get(`/adventures/adventures/?${query}`);
    dispatch({
      type: FETCH_NEXT_ADVENTURES_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_ADVENTURES_ERROR,
      payload: {
        message: 'Error fetching adventures.',
      },
    });
  }
};

export const createAdventure = adventure => async dispatch => {
  dispatch({ type: CREATE_ADVENTURE });
  try {
    const response = await gamerize.post('/adventures/adventures/', adventure);
    dispatch({
      type: CREATE_ADVENTURE_SUCCESS,
      payload: { ...adventure, id: response.data.id },
    });
  } catch (error) {
    dispatch({
      type: CREATE_ADVENTURE_ERROR,
      payload: {
        message: 'Error creating adventures.',
      },
    });
  }
};

export const editAdventure = (id, adventure) => async dispatch => {
  dispatch({ type: UPDATE_ADVENTURE });
  try {
    const response = await gamerize.put(`/adventures/adventures/${id}/`, adventure);
    dispatch({
      type: UPDATE_ADVENTURE_SUCCESS,
      payload: { ...adventure, id: response.data.id },
    });
  } catch (error) {
    dispatch({
      type: UPDATE_ADVENTURE_ERROR,
      payload: {
        message: 'Error updating adventure.',
      },
    });
  }
};

export const fetchAdventure = id => async dispatch => {
  dispatch({ type: FETCH_ADVENTURE });
  try {
    const response = await gamerize.get(`/adventures/adventures/${id}/`);
    dispatch({
      type: FETCH_ADVENTURE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    ErrorDialog({
      title: error.response.data.detail,
    });
    dispatch({
      type: FETCH_ADVENTURE_ERROR,
      payload: {
        message: 'Error updating adventure.',
      },
    });
  }
};

export const deleteAdventure = id => async dispatch => {
  dispatch({ type: DELETE_ADVENTURE });
  try {
    const response = await gamerize.delete(`/adventures/adventures/${id}/`);
    dispatch({
      type: DELETE_ADVENTURE_SUCCESS,
      payload: id,
    });
    if (response) {
      SuccessDialog({
        title: 'Successfully Deleted',
      });
    }
  } catch (error) {
    ErrorDialog({
      title: error.response.data,
    });
    dispatch({
      type: DELETE_ADVENTURE_ERROR,
      payload: {
        message: 'Error deleting adventure.',
      },
    });
  }
};

export const addStudentAdventure = (id, ids) => async dispatch => {
  dispatch({ type: CREATE_ADVENTURE });
  const data = [parseInt(ids)];
  try {
    const responseClass = await gamerize.post(
      `/adventures/add-student-adventures/${id}/`,
      {
        ids: data,
      }
    );
    dispatch({
      type: CREATE_ADVENTURE_SUCCESS,
      payload: 'Added successfully',
    });
    return responseClass
  } catch (error) {
    dispatch({
      type: CREATE_ADVENTURE_ERROR,
      payload: {
        message: 'Error Assigning Adventure.',
      },
    });
  }
};

export const removeStudentAdventure = (advenuteId, studentId) => async dispatch => {
  dispatch({ type: CREATE_ADVENTURE });
  const data = [parseInt(studentId)];
  try {
    const responseClass = await gamerize.post(
      `/adventures/remove-student-adventures/${advenuteId}/`,
      {
        ids: data,
      }
    ); 
    return responseClass
    dispatch({
      type: CREATE_ADVENTURE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: CREATE_ADVENTURE_ERROR,
      payload: {
        message: 'Error Removing Adventure.',
      },
    });
  }
};
