import gamerize from "../apis/gamerize";
const getAdvenuturesCompleted = async ({ id }) => {
  try {
    const response = await gamerize.get(
      `/analytics/student_analytics/${id}/completed_adventures/`
    );
    return response.data;
  } catch (error) {
    throw new Error("Error Fetching Completed Adventures");
  }
};
export default getAdvenuturesCompleted;
