import { CLEAR_API_ERROR, SET_API_ERROR } from "../actions/types";

const DEFAULT_STATE = {};

const apiErrorsReducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case SET_API_ERROR:
      return { ...state, ...action.payload };
    case CLEAR_API_ERROR:
      return DEFAULT_STATE;
    default:
      return state;
  }
};

export default apiErrorsReducer;
