import { Link } from "react-router-dom"
import Button from "../../components/Button/Button"

const NotFound = () => {
  return (
    <div className="flex-column flex-center fl-1" style={{
      height: "100vh"
    }}>
      <h1 className="mb-24">Error 404: Page not Found!</h1>
      <Link to="/"><Button primary label="Go Home" /></Link>
    </div>
  )
}

export default NotFound