import { getMinutes } from "../utils";

const getColorCoding = (row) => {
  const totalMin = getMinutes(row.original.time_spent);

  console.log("Mnutes : " + totalMin);

  return totalMin < 5
    ? "#ACDDDE"
    : totalMin < 10
    ? "#CAF1DE"
    : totalMin < 20
    ? "#E1F8DC"
    : totalMin < 60
    ? "#FEF8DD"
    : "#FFE7C7";
};

export default getColorCoding;
