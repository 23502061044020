import { Route, Routes } from "react-router-dom";
import SideBar from "../components/SideBar/SideBar";
import ProfileOptions from "../pages/ProfileOptions/ProfileOptions";

const ProfileRouter = () => {
  return (
    <div className="flex page">
      <SideBar pages={[{ title: "Options", link: `/profile/options` }]} />
      <Routes>
        <Route path={`options`} element={<ProfileOptions/>} />
      </Routes>
    </div>
  );
};

export default ProfileRouter;
