import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { createSelector } from "reselect";
import { editClass, removeStudents, fetchClass } from "../../actions/classes";
import ClassForm from "../../components/ClassForm/ClassForm";
import "./EditClass.scss";
import {TailSpin as Loader} from "react-loader-spinner";
import TabError from "../../components/TabError/TabError";

const selectClasses = ({ classes }) => classes.data;
const selectClassesLoading = ({ classes }) => classes.loading;
const selectClassesError = ({ classes }) => classes.error;
const selectClassesStatus = ({ classes }) => classes.status;

const classByID = (id) =>
  createSelector([selectClasses], (classes) => classes[id]);

const EditClass = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const classData = useSelector(classByID(id));
  const classesLoading = useSelector(selectClassesLoading);
  const classesError = useSelector(selectClassesError);
  const classesStatus = useSelector(selectClassesStatus);

  useEffect(() => {
    dispatch(fetchClass(id));
  }, [dispatch, id]);

  return (
    <div className="new-class-page fl-1">
      {classesLoading ? (
        <div className="flex flex-center fl-1">
          <Loader type="Puff" color="#354AC9" height={50} width={50} />
        </div>
      ) : classesError ? (
        <TabError
          onClick={() => dispatch(fetchClass(id))}
          message={classesError.message}
          label="Reload Tab"
        />
      ) : (
        <ClassForm
          className="fl-1"
          onSubmit={(editedClass) => {
            if (editedClass.deleteIds.length !== 0) {
              dispatch(removeStudents(id, editedClass.deleteIds));
            }
            if (editedClass.addIds.length !== 0) {
              dispatch(editClass(id, editedClass));
            }
          }}
          classData={classData}
          edit
          status={classesStatus}
        />
      )}
    </div>
  );
};

export default EditClass;
