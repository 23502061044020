import { combineReducers } from "redux";
import { LOGOUT, SET_API_ERROR } from "../actions/types";
import adventuresReducer from "./adventures";
import apiErrorsReducer from "./apiErrors";
import authReducer from "./auth";
import classesReducer from "./classes";
import studentsReducer from "./students";
import teachersReducer from "./teachers";
import bundlesReducer from "./bundles";
import subscriptionReducer from "./subscription";
import battleHistoryReducer from "./class-battle-history";

const appReducer = combineReducers({
  auth: authReducer,
  students: studentsReducer,
  classes: classesReducer,
  adventures: adventuresReducer,
  teachers: teachersReducer,
  bundles: bundlesReducer,
  subscription: subscriptionReducer,
  apiErrors: apiErrorsReducer,
  classBattle: battleHistoryReducer
});

const reducer = (state, action) => {
  if (action.type !== SET_API_ERROR) {
    state.apiErrors = {};
  }

  if (action.type === LOGOUT) {
    state = undefined;
  }

  return appReducer(state, action);
};

export default reducer;
