import './BundleView.scss';
import { Input, Table as AntTable } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { useNavigate, useParams, Link } from 'react-router-dom';
import {TailSpin as Loader} from 'react-loader-spinner';
import { fetchBundle } from '../../actions/bundles';
import { stringMatch } from '../../utils';
import TabError from '../../components/TabError/TabError';

const selectBundles = ({ bundles }) => bundles.data;
const selectBundlesLoading = ({ bundles }) => bundles.loading;
const selectBundlesError = ({ bundles }) => bundles.error;

const bundleById = id => createSelector([selectBundles], bundles => bundles[id]);

const BundleView = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { Search } = Input;

  const bundle = useSelector(bundleById(id));
  const bundleLoading = useSelector(selectBundlesLoading);
  const bundlesError = useSelector(selectBundlesError);

  useEffect(() => {
    dispatch(fetchBundle(id));
  }, []);

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Adventure Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Action',
      key: 'action',
      width: 160,
      fixed: 'right',
      render: value => (
        <span className="table-action-buttons">
          <Link
            className="text-button view"
            to={`/adventures/bundles/view/${id}/adventure/view/${value.id}`}
          >
            View
          </Link>
        </span>
      ),
    },
  ];

  useEffect(() => {
    if (!bundle) {
      dispatch(fetchBundle(id));
    }
  }, [dispatch, id, bundle]);

  useEffect(() => {
    setAdventures(bundle?.adventures || []);
  }, [bundle]);

  const [search, setSearch] = useState('');
  const [adventures, setAdventures] = useState([]);

  const renderAdventureList = () => (
    <AntTable
      title={null}
      columns={columns}
      dataSource={adventures}
      showHeader
      size="default"
    />
  );

  useEffect(() => {
    onSearch(search);
  }, [bundle]);

  const onSearch = value => {
    setAdventures(bundle?.adventures?.filter(({ name }) => stringMatch(name, value)));
  };

  return (
    <div className="bundle-view-page flex-column fl-1">
      <div className="mh-24 mt-24">
        <button className="btn-link" onClick={() => navigate('/adventures/bundles')}>
          Back to Bundles
        </button>
      </div>
      {bundleLoading ? (
        <div className="flex flex-center fl-1">
          <Loader type="Puff" color="#354AC9" height={50} width={50} />
        </div>
      ) : bundlesError ? (
        <TabError
          onClick={() => dispatch(fetchBundle())}
          message={bundlesError.message}
          label="Reload Bundle"
        />
      ) : (
        <>
          <div className="flex align-center justify-between mb-8 mh-24">
            <Search
              className="ph-24 pv-16 ml-8"
              placeholder="Search Bundles"
              enterButton="Search"
              size="large"
              onSearch={onSearch}
              value={search}
              onChange={e => setSearch(e.target.value)}
            />
          </div>
          <div className="overflow-hidden flex-column fl-1">
            <div className="flex justify-between align-center mh-24 pv-16">
              <h2>{bundle?.name}</h2>
            </div>
            <div className="list-container fl-1 ph-24">{renderAdventureList()}</div>
          </div>
        </>
      )}
    </div>
  );
};

export default BundleView;
