import gamerize from "../apis/gamerize";
import {
  FETCH_BATTLE_HISTORY,
  FETCH_BATTLE_HISTORY_SUCCESS,
  FETCH_BATTLE_DETAILS,
  FETCH_BATTLE_DETAILS_SUCCESS,
  FETCH_STUDENTS_JOINED,
  FETCH_STUDENTS_JOINED_SUCCESS,
  FETCH_MONSTERS,
  FETCH_MONSTERS_SUCCESS,
  FETCH_MONSTERS_ERROR,
  SET_BATTLE_STATE,
  FETCH_BATTLE_HISTORY_ERROR,
  FETCH_BATTLE_DETAILS_ERROR,
  FETCH_STUDENTS_JOINED_ERROR,
} from "./types";

export const fetchBattleHistory =
  (search="", limit = 10, offset = 0) =>
  async (dispatch) => {
    dispatch({ type: FETCH_BATTLE_HISTORY });
    try {
      const response = await gamerize.get(
        `class-battle/class-battles-history/?search=${search}&limit=${limit}&offset=${offset}`
      );

      dispatch({
        type: FETCH_BATTLE_HISTORY_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: FETCH_BATTLE_HISTORY_ERROR,
        payload: {
          message: "There was an error fetching the battle history.",
        },
      });
    }
  };

export const fetchBattleHistoryDetails = (id) => async (dispatch) => {
  dispatch({ type: FETCH_BATTLE_DETAILS });
  try {
    const response = await gamerize.get(
      `/class-battle/class-battles-history/${id}`
    );

    dispatch({
      type: FETCH_BATTLE_DETAILS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_BATTLE_DETAILS_ERROR,
      payload: {
        message: "There was an error fetching the battle history details.",
      },
    });
  }
};

export const fetchJoinedStudents = (id,limit,offset) => async (dispatch) => {
  dispatch({ type: FETCH_STUDENTS_JOINED });
  try {
    const response = await gamerize.get(
      `class-battle/${id}/student-performances/?limit=${limit}&offset=${offset}`
    );

    dispatch({
      type: FETCH_STUDENTS_JOINED_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_STUDENTS_JOINED_ERROR,
      payload: {
        message: "There was an error fetching the students joined.",
      },
    });
  }
};


export const fetchMonsters = (limit, offset) => async (dispatch) => {
  dispatch({ type: FETCH_MONSTERS });
  try {
    const response = await gamerize.get(
      `/api/v1/class-battle/monsters/?limit=${limit}&offset=${offset}`
    );

    dispatch({
      type: FETCH_MONSTERS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_MONSTERS_ERROR,
      payload: {
        message: "There was an error fetching the monsters.",
      },
    });
  }
};

export const setBattleState = (isBattleLive) => ({
  type: SET_BATTLE_STATE,
  payload: isBattleLive
});

