import { Fragment, useState, useEffect } from "react";
import {TailSpin as Loader} from "react-loader-spinner";
import { useSelector } from "react-redux";
import Button from "../../Button/Button";
import StudentsBar from "../../StudentsBar/StudentsBar";
import TabError from "../../TabError/TabError";
import StudentGridItem from "../StudentGridItem/StudentGridItem";
import {
  ConfirmationDialog,
  SuccessDialog,
  ErrorDialog,
} from "../../ConfirmationDialog";
import gamerize from "../../../apis/gamerize";
import { useParams } from "react-router-dom";

const selectClassesLoading = ({ classes }) => classes.loading;
const selectClassesError = ({ classes }) => classes.error;
const selectClassesStatus = ({ classes }) => classes.statusCode;

const ClassAdd = ({ classData, onAdd, edit, createLoading, createError }) => {
  const { id } = useParams();

  const classesLoading = useSelector(selectClassesLoading);
  const classesError = useSelector(selectClassesError);
  const classesStatus = useSelector(selectClassesStatus);

  const [className, setClassName] = useState(classData.name || "");
  const [error, setError] = useState("");
  const [showPopUp, setshowPopUp] = useState(false);
  const [selectedStudents, setSelectedStudents] = useState(
    classData.students || []
  );
  const [delSelectedStudents, setDelSelectedStudents] = useState([]);
  const [addSelectedStudents, setAddSelectedStudents] = useState([]);

  useEffect(() => {
    if (classesStatus === 200 && showPopUp === true) {
      SuccessDialog({
        title: "Class Updated!",
      });
      setshowPopUp(false);
    }
  }, [classesStatus]);

  const pickStudent = (student) => {
    if (!selectedStudents.find(({ id }) => id === student.id)) {
      setSelectedStudents((prevList) => [...prevList, student]);
    }
    if (delSelectedStudents.some(({ id }) => id === student.id)) {
      setDelSelectedStudents(
        delSelectedStudents.filter(({ id }) => id !== student.id)
      );
    } else {
      setAddSelectedStudents((list) => [...list, student]);
    }
  };

  const removeStudent = (student) => {
    setSelectedStudents(selectedStudents.filter(({ id }) => id !== student.id));
    if (addSelectedStudents.some(({ id }) => id === student.id)) {
      setAddSelectedStudents(
        addSelectedStudents.filter(({ id }) => id !== student.id)
      );
    } else {
      setDelSelectedStudents((prevList) => [...prevList, student]);
    }
  };

  const renderStudentGrid = () =>
    selectedStudents.map((student) => (
      <StudentGridItem
        key={student.id}
        student={student}
        onRemove={() => removeStudent(student)}
      />
    ));

  const handleUpdateName = async (e) => {
    e.preventDefault();
    ConfirmationDialog({
      title: `Are You Sure to change the name to "${className}"?`,

      onConfirm: async () => {
        if (!window.navigator.onLine) {
          ErrorDialog({
            title: "You're offline right now. Check your connection.",
          });
        } else {
          try {
            await gamerize.patch(`/schools/classes/${id}/`, {
              name: className,
            });
          } catch (error) {
            throw new Error(error.message);
          }
        }
      },
    });
  };
  const handleUpdateClass = () => {
    if (!window.navigator.onLine) {
      ErrorDialog({
        title: "You're offline right now. Check your connection.",
      });
    } else {
      setshowPopUp(true);
      onAdd({
        className,
        selectedStudents,
        addSelectedStudents,
        delSelectedStudents,
      });
    }
  };

  const onFormSubmit = (e) => {
    e.preventDefault();
    const addStudents = addSelectedStudents.map(({ first_name }) => first_name);
    const delStudents = delSelectedStudents.map(({ first_name }) => first_name);

    if (className === "") {
      setError("Please enter a valid Class Name!");
    } else if (selectedStudents.length === 0) {
      setError("Please Pick atleast 1 student");
    } else if (
      addSelectedStudents.length === 0 &&
      delSelectedStudents.length === 0
    ) {
      ErrorDialog({
        title: "Please select at least one student to delete or add",
      });
    } else {
      setError("");
      ConfirmationDialog({
        title: `Are You Sure 
        ${addStudents.length ? `to add user(s) =  [${addStudents}  ]` : ""}
          ${
            addStudents.length && delStudents.length
              ? `and delete user(s) = [${delStudents}]`
              : delStudents.length
              ? ` to delete user(s) = [${delStudents}]`
              : ""
          } ?`,

        onConfirm: () => {
          handleUpdateClass();
        },
      });
    }
  };

  return (
    <div className="flex fl-1">
      {classesLoading && !createLoading ? (
        <div className="flex flex-center fl-1">
          <Loader type="Puff" color="#354AC9" height={50} width={50} />
        </div>
      ) : classesError ? (
        <TabError
          onClick={() => console.log("Error")}
          message={classesError.message}
          label="Reload Form"
        />
      ) : (
        <Fragment>
          <div className="flex-column p-24 fl-1">
            <h2 className="mb-16">New Class</h2>
            {createError && (
              <p className="mb-24 error-message">Error creating class</p>
            )}
            <form className="class-form flex-column fl-1">
              <div className="flex align-center mb-16">
                <label className="mr-16">Class Name:</label>
                <input
                  value={className}
                  onChange={(e) => setClassName(e.target.value)}
                />
                {edit && (
                  <Button
                    label="Update Name"
                    short
                    success
                    className=" ml-24"
                    onClick={handleUpdateName}
                  />
                )}
                {error && <p className="ml-16 error-message">{error}</p>}
              </div>
              <div className="flex align-center mb-16">
                <label className="mr-16">Number of Students:</label>
                <p>{selectedStudents.length}</p>
              </div>
              <div className="students-grid-container fl-1 mb-16">
                <div className="students-grid">{renderStudentGrid()}</div>
              </div>

              <div className="flex flex-end-self">
                <Button
                  label={edit ? "Save" : "Create Class"}
                  primary
                  loading={createLoading}
                  onClick={onFormSubmit}
                />
              </div>
            </form>
          </div>
          <StudentsBar
            selectedStudents={selectedStudents}
            pickStudent={pickStudent}
          />
        </Fragment>
      )}
    </div>
  );
};

export default ClassAdd;
