import { Link } from "react-router-dom";
import "./Classes.scss";

const Classes = () => {
  return (
    <div className="classes-page fl-1 p-24">
      <div className="grid-fill">
        <Link to="/classes/new">
          <div className="card card-nav clickable flex-column flex-center font-black">
            <h2 className="mb-16">New Class</h2>
            <p>Create a new Class</p>
          </div>
        </Link>
        <Link to="/classes/my-classes">
          <div className="card card-nav clickable flex-column flex-center font-black">
            <h2 className="mb-16">My Class</h2>
            <p>List of Your Classes</p>
          </div>
        </Link>
        <Link to="/classes/assign-teacher">
          <div className="card card-nav clickable flex-column flex-center font-black">
            <h2 className="mb-16">Assign Teacher</h2>
            <p>Assign Classes to teachers</p>
          </div>
        </Link>
        <Link to="/classes/unassign-teacher">
          <div className="card card-nav clickable flex-column flex-center font-black">
            <h2 className="mb-16">Unassign Teacher</h2>
            <p>Unassign Classes to teachers</p>
          </div>
        </Link>
        <Link to="/classes/my-students">
          <div className="card card-nav clickable flex-column flex-center font-black">
            <h2 className="mb-16">My Students</h2>
            <p>List of Your Students</p>
          </div>
        </Link>
        <Link to="/classes/assign-classes">
          <div className="card card-nav clickable flex-column flex-center font-black">
            <h2 className="mb-16">Assign Adventure</h2>
            <p>Send Students on Adventure</p>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default Classes;
