import Button from "../../../components/Button/Button";
import { ErrorDialog } from "../../ConfirmationDialog";

const AvailableGames = ({ games, onBack, onNext, type, edit, loading }) => {
  const handleBack = () => {
    onBack();
  };

  const handleNext = () => {
    if (!window.navigator.onLine) {
      ErrorDialog({
        title: "You're offline right now. Check your connection.",
      });
    } else {
      onNext();
    }
  };

  const renderGames = () => {
    return (
      <div className="games-grid ph-24">
        {games.map((game) => (
          <div key={game.name} className="button-card">
            {game.name}
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="overflow-hidden fl-1 flex-column">
      <h3 className="ph-24 pv-16">Step 3: Games</h3>
      <p className="pv-16 ph-24">{type}</p>
      <div className="scroll-vertical fl-1 mb-16">{renderGames()}</div>
      <div className="flex mh-24 mb-24">
        <Button label="Back" onClick={handleBack} primary className="mr-24" />
        <Button
          label={edit ? "Edit" : "Create"}
          onClick={handleNext}
          primary
          loading={loading}
        />
      </div>
    </div>
  );
};

export default AvailableGames;
