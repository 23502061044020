import { Route, Routes } from "react-router-dom";
import SideBar from "../components/SideBar/SideBar";
import CheckoutSession from "../pages/CheckoutSession/CheckoutSession";
import PricingTable from "../pages/PricingTable/PricingTable";
import SubscriptionDetail from "../pages/SubscriptionDetail/SubscriptionDetail";
import BankTransfer from "../pages/PaymentOptions/BankTransfer";
import getSubscriptionDetail from "../helper/getSubscriptionDetail";
import { useState, useEffect } from "react";

const SubscriptionsRouter = () => {
  const [subscriptionDetail, setSubscriptionDetail] = useState("");

  const getSubscription = async () => {
    try {
      const response = await getSubscriptionDetail();
      setSubscriptionDetail(response);
    } catch (error) {
      console.log("Error Fetching Subscriptions Detail");
    }
  };

  useEffect(() => {
    getSubscription();
  }, []);

  return (
    <div className="flex page">
      {subscriptionDetail?.payment_type === 1 ? (
        <SideBar pages={[]} />
      ) : (
        <SideBar
          pages={[
            { title: "Bank Transfer Requests", link: `/subscriptions/bank-transfers` },
          ]}
        />
      )}
      <Routes>
        <Route path={`dashboard`} element={<PricingTable/>} />
        <Route path={`detail`} element={<SubscriptionDetail/>} />
        <Route path={`bank-transfers`} element={<BankTransfer/>} />
        <Route
          path={`create-checkout-session/:packageId`}
          element={<CheckoutSession/>}
        />
      </Routes>
    </div>
  );
};

export default SubscriptionsRouter;
