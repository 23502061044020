import { useSelector } from "react-redux";
import OwnerOptions from "./OwnerOptions";
import TeacherOptions from "./TeacherOptions";

const authSelector = ({ auth }) => auth;

const ProfileOptions = () => {
  const user = useSelector(authSelector);

  if (!user) {
    return <div className="flex flex-center">Loading</div>;
  }

  if (user.user_type === "teacher") {
    return <TeacherOptions user={user} />;
  } else {
    return <OwnerOptions user={user} />;
  }
};

export default ProfileOptions;
