import { useEffect } from "react";
import {TailSpin as Loader} from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { createSelector } from "reselect";
import _ from "lodash";
import {
  fetchNextClasses,
  fetchUnassignedClasses,
  unassignTeacher,
} from "../../actions/classes";
import Button from "../../components/Button/Button";
import ClassListItem from "../../components/ClassListItem/ClassListItem";
import TabError from "../../components/TabError/TabError";
import "./UnassignTeacher.scss";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  ConfirmationDialog,
  ErrorDialog,
} from "../../components/ConfirmationDialog";

const selectClasses = ({ classes }) => classes.data;
const selectClassesPagination = ({ classes }) => classes.pagination;
const selectClassesLoading = ({ classes }) => classes.loading;
const selectClassesError = ({ classes }) => classes.error;

const selectClassList = createSelector([selectClasses], (classes) =>
  Object.values(classes)
);

const UnassignTeacher = () => {
  const dispatch = useDispatch();
  const classes = useSelector(selectClassList);
  const classesPagination = useSelector(selectClassesPagination);
  const classesLoading = useSelector(selectClassesLoading);
  const classesError = useSelector(selectClassesError);

  const [selected, setSelected] = useState(null);

  const renderClassList = () => (
    <InfiniteScroll
      className="p-24"
      dataLength={classes.length}
      next={() => dispatch(fetchNextClasses())}
      hasMore={classesPagination.next ? true : false}
      loader={
        <div className="flex flex-center">
          <Loader type="Puff" color="#354AC9" height={50} width={50} />
        </div>
      }
      scrollableTarget="scrollableContainer"
      endMessage={
        <p style={{ textAlign: "center" }}>
          <b>End of List</b>
        </p>
      }
    >
      {classes.map((myClass) => (
        <ClassListItem
          key={myClass.id}
          classItem={myClass}
          onClick={() => setSelected(myClass)}
          selected={selected && selected.id === myClass.id}
        />
      ))}
    </InfiniteScroll>
  );

  useEffect(() => {
    dispatch(fetchUnassignedClasses());
  }, [dispatch]);

  const handleUnassignTeacher = () => {
    ConfirmationDialog({
      title: "Are You Sure?",
      onConfirm: () => {
        if (!window.navigator.onLine) {
          ErrorDialog({
            title: "You're offline right now. Check your connection.",
          });
        } else {
          const classId = selected.id;
          const teacherId = selected.teacher.id;
          dispatch(unassignTeacher(classId, teacherId));
          setSelected(null);
        }
      },
    });
  };
  if (classesLoading) {
    return (
      <div className="flex flex-center fl-1">
        <Loader type="Puff" color="#354AC9" height={50} width={50} />
      </div>
    );
  } else {
    if (classesError) {
      return (
        <TabError
          onClick={() => dispatch(fetchUnassignedClasses())}
          message={classesError.message}
          label="Reload Tab"
        />
      );
    } else {
      return (
        <div className="go-page page flex-column fl-1">
          <div className="p-24">
            <h2 className="mb-8">Unassign Teachers</h2>
            <p className="mb-16"> Unassign Classes to teachers</p>
          </div>
          <div className="go-choose-class flex-column fl-1 overflow-hidden">
            <h3 className="mb-16 mh-24">Choose a class: </h3>
            <div id="scrollableContainer" className="list-container fl-1">
              {renderClassList()}
            </div>
            <Button
              label="Unassign Teacher"
              primary
              onClick={handleUnassignTeacher}
              className="mb-24 mh-24"
              wide
              disabled={_.isEmpty(selected)}
            />
          </div>
        </div>
      );
    }
  }
};

export default UnassignTeacher;
