import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import _ from "lodash";
import { TailSpin as Loader } from "react-loader-spinner";
import InfiniteScroll from "react-infinite-scroll-component";
import { Table as AntTable, Input, Modal, Button as AntButton } from "antd";

import TabError from "../../../components/TabError/TabError";
import Button from "../../../components/Button/Button";
import { fetchClasses } from "../../../actions/classes";

import { maxNumberOfStudentsInClassBattle } from "../../../constants";

const { Search } = Input;
const selectClasses = ({ classes }) => classes.data;
const selectClassesPagination = ({ classes }) => classes.pagination;
const selectClassesLoading = ({ classes }) => classes.loading;
const selectClassesError = ({ classes }) => classes.error;

const selectClassList = createSelector([selectClasses], (classes) =>
  Object.values(classes)
);

const columns = [
  {
    title: "Class Name",
    dataIndex: "name",
    key: "name",
    width: 580,
  },
  {
    title: "Number of Students",
    dataIndex: "students",
    key: "students",
    render: (value) => value.length,
    width: 150,
  },
];

const ChooseClass = ({
  onNext,
  selectedClass,
  onBack,
  multiClass,
  classBattle,
}) => {
  const [selected, setSelected] = useState(selectedClass || []);
  const [classSearch, setClassSearch] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const shouldDisable = Object.keys(selected || []).length > 0;

  const classes = useSelector(selectClassList);
  const classesPagination = useSelector(selectClassesPagination);
  const classesLoading = useSelector(selectClassesLoading);
  const classesError = useSelector(selectClassesError);

  const handleNext = () => {
    if (!_.isEmpty(selected)) {
      onNext(selected);
    }
  };

  const dispatch = useDispatch();
  const offset = (page - 1) * pageSize;

  useEffect(() => {
    dispatch(fetchClasses(classSearch, pageSize, offset));
  }, [dispatch, page, pageSize]);

  useEffect(() => {
    if (selectedClass.length > 0 && selected.length === 0) {
      setSelected(selectedClass);
    }
  }, []);

  const onSearch = (value) => {
    dispatch(fetchClasses(classSearch, pageSize, offset));
    setPage(1);
  };

  const handlePagination = () => {
    return {
      current: page,
      pageSize: pageSize,
      total: classesPagination.count,
      onChange: (page, pageSize) => {
        setPage(page);
        setPageSize(pageSize);
      },
    };
  };

  const renderClassList = () => (
    <InfiniteScroll
      className="p-24"
      dataLength={classes.length}
      loader={
        <div className="flex flex-center">
          <Loader type="Puff" color="#354AC9" height={50} width={50} />
        </div>
      }
      scrollableTarget="scrollableContainer"
    >
      <AntTable
        title={null}
        pagination={handlePagination()}
        columns={columns}
        dataSource={classes.map((obj) => ({ ...obj, key: obj.id }))}
        rowClassName={(record, index) =>
          index % 2 === 0 ? "table-row-light" : "table-row-dark"
        }
        showHeader
        rowSelection={{
          renderCell: (checked, record, index, originNode) => {
            if (multiClass) return originNode;
            if (shouldDisable && !checked) return null;
            return originNode;
          },
          selectedRowKeys: selected ? Object.keys(selected).map((a) => +a) : [],
          onChange: (selectedRowKeys, selectedRows) => {
            const rows = {};
            let validSelection = true;

            selectedRows.forEach((each) => {
              if (each.students.length === 0) {
                validSelection = false;
                setModalMessage("You cannot select a class with 0 students.");
                setModalVisible(true);
              } else if (classBattle && each.students.length > maxNumberOfStudentsInClassBattle) {
                validSelection = false;
                setModalMessage(
                  `You cannot select a class with more than ${maxNumberOfStudentsInClassBattle} students.`
                );
                setModalVisible(true);
              } else {
                rows[each.id] = each;
              }
            });

            if (validSelection) {
              setSelected(rows);
            }
          },
          columnTitle: <div />,
        }}
        size="default"
        bordered={false}
      />
    </InfiniteScroll>
  );

  return (
    <div className="go-choose-class flex-column fl-1 overflow-auto">
      <h3 className="mb-16 text-heading mg-top ml-10">Step 1: Choose Class</h3>
      <Search
        placeholder="Search Class"
        enterButton="Search"
        size="large"
        onSearch={onSearch}
        value={classSearch}
        onChange={(e) => setClassSearch(e.target.value)}
        style={{ width: "98%", marginLeft: "10px" }}
      />

      {classBattle ? null : (
        <div className="flex justify-between align-center ph-24 pv-16 ml-8">
          <h3>Total number of Classes: {classesPagination.count}</h3>
          <h3>Selected Classes: {Object.keys(selected).length}</h3>
        </div>
      )}

      {classesLoading ? (
        <div className="flex flex-center fl-1">
          <Loader type="Puff" color="#354AC9" height={50} width={50} />
        </div>
      ) : classesError ? (
        <TabError
          onClick={() => dispatch(fetchClasses())}
          message={classesError.message}
          label="Reload Tab"
        />
      ) : (
        <>
          <div id="scrollableContainer" className="list-container fl-1">
            {renderClassList()}
          </div>
          <div className="flex justify-end mb-24 mh-24">
            <>
              {!classBattle && (
                <Button
                  label="Back"
                  primary
                  onClick={onBack}
                  className="mr-24"
                  wide
                />
              )}
              <Button
                label="Next"
                primary
                onClick={handleNext}
                wide
                disabled={_.isEmpty(selected)}
              />
            </>
          </div>
        </>
      )}

      <Modal
        title="Selection Error"
        open={modalVisible}
        onOk={() => setModalVisible(false)}
        onCancel={() => setModalVisible(false)}
        footer={[
          <AntButton
            key="ok"
            type="primary"
            onClick={() => setModalVisible(false)}
          >
            Ok
          </AntButton>,
        ]}
      >
        <p>{modalMessage}</p>
      </Modal>
    </div>
  );
};

export default ChooseClass;
