import { PAYMENT_OPTION } from "../actions/types";

const DEFAULT_STATE = {
  payment_type: {},
};

const subscriptionReducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case PAYMENT_OPTION:
      return { ...state, payment_type: action.payload };
    default:
      return state;
  }
};

export default subscriptionReducer;
