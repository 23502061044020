import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import getSubscriptionDetail from "../../helper/getSubscriptionDetail";
import "./Students.scss";

const Students = () => {
  const [subscriptionDetail, setSubscriptionDetail] = useState("");
  const subscriptionData = async () => {
    try {
      const responseDetail = await getSubscriptionDetail();
      setSubscriptionDetail(responseDetail);
    } catch (error) {
      console.log("Error Creating Subscriptions");
    }
  };
  useEffect(() => {
    subscriptionData();
  }, []);

  return (
    <div className="students-page fl-1 p-24">
      <div className="grid-fill">
        <Link to="/students/my-students">
          <div className="card card-nav clickable flex-column flex-center font-black">
            <h2 className="mb-16">My Students</h2>
            <p>List of Your Students</p>
          </div>
        </Link>
        {/* {subscriptionDetail.is_valid && false ? (
          <Link to="/students/upload-data">
            <div className="card card-nav clickable flex-column flex-center font-black">
              <h2 className="mb-16">Bulk Create Students</h2>
              <p> Feature in Progress 🚧 </p>
            </div>
          </Link>
        ) : (
          <Link to="/subscriptions/dashboard">
            <div className="card card-nav non-clickable flex-column flex-center font-black">
              <h2 className="font-gray bold mb-16">Bulk Data</h2>
              <p className="font-gray bold">Upload Bulk Data</p>
            </div>
          </Link>
        )} */}
      </div>
    </div>
  );
};

export default Students;
