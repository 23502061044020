import useSound from "use-sound";
import "./WordListItem.scss";
import IconButton from "../Button/IconButton";
import classNames from "classnames";
import { ConfirmationDialog } from "../../components/ConfirmationDialog";

const WordListItem = ({ word, remove, selected, onClick }) => {
  const handleConfirmationMessage = () => {
    ConfirmationDialog({
      title: "Are You Sure?",
      onConfirm: () => {
        remove();
      },
    });
  };

  const classes = classNames("word-list-item", selected && "selected");
  const [wordSound] = useSound(word.to_word_audio);
  const [sentenceSound] = useSound(word.to_sentence_audio);
  return (
    <div className={classes} onClick={onClick}>
      <div className="flex-column">
        <div className="flex align-center mb-4">
          <h4 className="mr-8">{word.to_word}</h4>
          <div
            className="icon icon-24 icon-sound-b pointer"
            onClick={(e) => {
              e.stopPropagation();
              wordSound();
            }}
          />
        </div>
        <div className="flex align-center mb-16">
          <p className="mr-8">{word.to_sentence}</p>
          <div
            className="icon icon-24 icon-sound-b pointer"
            onClick={(e) => {
              e.stopPropagation();
              sentenceSound();
            }}
          />
        </div>
        <div className="flex-column">
          <h4 className="mb-4">{word.from_word}</h4>
          <p>{word.from_sentence}</p>
        </div>
      </div>
      {word.word_image !== word.sentence_image && (
        <div>
          <img src={word.sentence_image} alt="sentence" />
          <p className="img-desc">sentence</p>
        </div>
      )}
      <div>
        <img src={word.word_image} alt="word" />
        <p className="img-desc">word</p>
      </div>
      {remove && (
        <IconButton
          onClick={handleConfirmationMessage}
          icon="close"
          className="circle"
          danger
        />
      )}
    </div>
  );
};

export default WordListItem;
