import classNames from "classnames";
import {TailSpin as Loader} from "react-loader-spinner";
import "./Button.scss";

const Button = ({
  label,
  primary,
  danger,
  success,
  wide,
  short,
  className,
  loading,
  disabled,
  ...rest
}) => {
  const cssClasses = classNames(
    "btn",
    primary && "btn-primary",
    danger && "btn-danger",
    success && "btn-success",
    wide && "btn-wide",
    short && "btn-short",
    disabled && "disabled",
    className
  );
  if (loading) {
    return (
      <div className={classNames("ph-24", className)}>
        <Loader type="Puff" color="#354AC9" height={46} width={46} />
      </div>
    );
  }
  return (
    <button {...rest} className={cssClasses}>
      {label}
    </button>
  );
};

export default Button;
