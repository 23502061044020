import { useEffect, useState } from "react";
import "./ClassForm.scss";
import ClassAdd from "./Tabs/ClassAdd";
import ClassAdded from "./Tabs/ClassAdded";

const ClassForm = ({
  classData = {},
  className = "",
  onSubmit,
  status,
  edit,
}) => {
  const [tab, setTab] = useState(0);
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    if (status === "succeeded" && submitted) setTab(1);
  }, [status, submitted]);

  const renderTab = (tab) => {
    switch (tab) {
      case 0:
        return (
          <ClassAdd
            classData={classData}
            onAdd={(data) => {
              setSubmitted(true);
              onSubmit({
                name: data.className,
                students: data.selectedStudents.map(({ id }) => id),
                ids: data.selectedStudents.map(({ id }) => id),
                addIds: data.addSelectedStudents.map(({ id }) => id),
                deleteIds: data.delSelectedStudents.map(({ id }) => id),
              });
            }}
            createError={status === "failed"}
            createLoading={status === "loading" && submitted}
            edit={edit}
          />
        );
      case 1:
        return (
          <ClassAdded
            onBack={() => {
              setTab(0);
              setSubmitted(false);
            }}
            edit={edit}
          />
        );
      default:
        return (
          <ClassAdd
            classData={classData}
            onAdd={(data) => {
              setSubmitted(true);
              onSubmit({
                name: data.className,
                students: data.selectedStudents.map(({ id }) => id),
                ids: data.selectedids.map(({ id }) => id),
                addIds: data.addSelectedStudents.map(({ id }) => id),
                deleteIds: data.delSelectedStudents.map(({ id }) => id),
              });
            }}
            createError={status === "failed"}
            createLoading={status === "loading" && submitted}
            edit={edit}
          />
        );
    }
  };
  return <div className={`class-form ${className}`}>{renderTab(tab)}</div>;
};

export default ClassForm;
