import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ForgotPassword from "../pages/ForgotPassword/ForgotPassword";
import Home from "../pages/Home/Home";
import Login from "../pages/Login/Login";
import NewPassword from "../pages/NewPassword/NewPassword";
import NotFound from "../pages/NotFound/NotFound";
import PrivacyPage from "../pages/PrivacyPage/PrivacyPage";
import SignUp from "../pages/SignUp/SignUp";
import AdventuresRouter from "./AdventuresRouter";
import ClassesRouter from "./ClassesRouter";
import OwnerRoute from "./OwnerRoute";
import PrivateRoute from "./PrivateRoute";
import ProfileRouter from "./ProfileRouter";
import PublicRoute from "./PublicRoute";
import StudentsRouter from "./StudentsRouter";
import TeachersRouter from "./TeachersRouter";
import AnalyticsRouter from "./AnalyticsRouter";
import SubscriptionsRouter from "./SubscriptionsRouter";
import { config } from "../constants";
import ClassBattleRouter from "./ClassBattleRouter";

const AppRouter = () => {
  const STUDENT_DOMAIN = window.location.href.includes(config.STUDENT_URL);

  return STUDENT_DOMAIN ? (
    <Router>
      <Routes>
        <Route path="/new-password" element={<PublicRoute><NewPassword /></PublicRoute>} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  ) : (
    <Router>
      <Routes>
        <Route element={<PrivateRoute />}>
          <Route path="/" element={<Home />} />
          <Route path="/classes/*" element={<ClassesRouter />} />
          <Route path="/adventures/*" element={<AdventuresRouter />} />
          <Route path="/analytics/*" element={<AnalyticsRouter />} />
          <Route path="/profile/*" element={<ProfileRouter />} />
          <Route path="/class-battle/*" element={<ClassBattleRouter />} />
        </Route>
        <Route element={<OwnerRoute />}>
          <Route path="/teachers/*" element={<TeachersRouter />} />
          <Route path="/students/*" element={<StudentsRouter />} />
          <Route path="/subscriptions/*" element={<SubscriptionsRouter />} />
        </Route>

        <Route
          path="/login"
          element={
            <PublicRoute>
              <Login />
            </PublicRoute>
          }
        />
        <Route
          path="/signup"
          element={
            <PublicRoute>
              <SignUp />
            </PublicRoute>
          }
        />
        <Route
          path="/forgot-password"
          element={
            <PublicRoute>
              <ForgotPassword />
            </PublicRoute>
          }
        />
        <Route
          path="/new-password"
          element={
            <PublicRoute>
              <NewPassword />
            </PublicRoute>
          }
        />
        <Route path="/privacy" element={<PrivacyPage/>} />
        <Route path="*" element={<NotFound/>} />
      </Routes>
    </Router>
  );
};

export default AppRouter;
