import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchStudents, removeStudent } from '../../actions/student';
import './MyStudents.scss';
import {TailSpin as Loader} from 'react-loader-spinner';
import { Link } from 'react-router-dom';
import { Table as AntTable, Dropdown, Menu, Input, Checkbox, Button, Modal } from 'antd';
import { DownOutlined } from '@ant-design/icons';

import Buttons from '../../components/Button/Button';
import TabError from '../../components/TabError/TabError';
import {
  ConfirmationDialog,
  ErrorDialog,
} from '../../components/ConfirmationDialog';

const { Search } = Input;
const selectStudents = ({ students }) => Object.values(students.data);
const selectStudentsPagination = ({ students }) => students.pagination;
const selectStudentsLoading = ({ students }) => students.loading;
const selectStudentsError = ({ students }) => students.error;

const MyStudents = () => {
  const dispatch = useDispatch();

  const fetchedStudents = useSelector(selectStudents);
  const studentsPagination = useSelector(selectStudentsPagination);
  const studentsLoading = useSelector(selectStudentsLoading);
  const studentsError = useSelector(selectStudentsError);
  const [visible, setVisible] = useState(false);

  const [search, setSearch] = useState('');
  const [multiDelete, setMultiDelete] = useState(false);
  const [includingClasses, setIncludingClasses] = useState('0');
  const [page, setPage] = useState(1);
  const [selected, setSelected] = useState([]);
  const [pageSize, setPageSize] = useState(20);
  const offset = (page - 1) * pageSize;

  const deleteHandler = value => {
    ConfirmationDialog({
      title: 'Are You Sure?',
      onConfirm: () => {
        if (!window.navigator.onLine) {
          ErrorDialog({
            title: "You're offline right now. Check your connection.",
          });
        } else {
          dispatch(removeStudent([value.id]));
        }
      },
    });
  };

  const onChangeCheckbox = e => {
    if(e.target.checked === true){
      setIncludingClasses('1')
    }
    else{
      setIncludingClasses('0')
    }
  };

  const deleteStudentHandler = value => {
    const selectedKeys = Object.keys(selected);
    dispatch(removeStudent(selectedKeys, includingClasses));
    setMultiDelete(false);
    setVisible(false);
    setIncludingClasses('0')
  };

  const onCancelPopup = value => {
    setVisible(false);
    setIncludingClasses('0')
  };
  const columns = [
    {
      title: 'User Name',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: 'First Name',
      dataIndex: 'first_name',
      key: 'first_name',
    },

    {
      title: 'Last Name',
      dataIndex: 'last_name',
      key: 'last_name',
    },
    {
      title: 'Action',
      key: 'action',
      width: 160,
      fixed: 'right',
      render: value => (
        <span className="table-action-buttons">
          <Link
            className="text-button mr-10"
            to={`/students/my-students/student/${value.id}`}
          >
            View
          </Link>
          <Dropdown
            trigger={['click']}
            className="table-more-button"
            overlay={
              <Menu className="actions-menu">
                <Menu.Item
                  className="negative delete"
                  onClick={() => deleteHandler(value)}
                >
                  Delete
                </Menu.Item>
              </Menu>
            }
          >
            <span className="text-button ant-dropdown-link">
              More <DownOutlined />
            </span>
          </Dropdown>
        </span>
      ),
    },
  ];

  const handlePagination = () => {
    return {
      current: page,
      pageSize: pageSize,
      total: studentsPagination.count,
      onChange: (page, pageSize) => {
        setPage(page);
        setPageSize(pageSize);
      },
    };
  };

  useEffect(() => {
    dispatch(fetchStudents(search, pageSize, offset));
  }, [dispatch, page, pageSize]);

  const onSearch = value => {
    dispatch(fetchStudents(search, pageSize, offset));
    setPage(1);
  };

  const multiDeleteStudentCotroller = value => {
    setMultiDelete(!multiDelete)
    setSelected([])
  };
  const renderStudentsList = () => (
    <div>
      <div className="flex justify-between align-center ph-24 pv-16 mg-top">
        <h3>
          <span className="total_students mr-8 mb-24">Total Number of Students :</span>
          {studentsPagination.count}
        </h3>
        <div className="flex">
          {multiDelete && (
            <Buttons
              label="Delete"
              className="mr-24"
              disabled={selected.length === 0}
              type="primary"
              danger
              onClick={() => setVisible(true)}
            />
          )}

          <Buttons
            label={multiDelete ? 'Cancel' : 'Remove Multiple Students'}
            primary
            onClick={() => multiDeleteStudentCotroller()}
          />
        </div>
      </div>
      {multiDelete ? (
        <AntTable
          title={null}
          pagination={handlePagination()}
          columns={columns}
          dataSource={fetchedStudents.map(obj => ({ ...obj, key: obj.id }))}
          showHeader
          size="default "
          bordered={false}
          rowSelection={{
            selectedRowKeys: selected ? Object.keys(selected).map(a => +a) : [],
            onChange: (selectedRowKeys, selectedRows) => {
              const rows = {};
              selectedRows.forEach(each => {
                rows[each.id] = each;
              });
              setSelected(rows);
            },
            columnTitle: <div />,
          }}
        />
      ) : (
        <AntTable
          title={null}
          pagination={handlePagination()}
          columns={columns}
          dataSource={fetchedStudents}
          showHeader
          size="default"
          bordered={false}
        />
      )}
    </div>
  );

  return (
    <div className="my-students-page flex-column fl-1 pt-24">
      <div className="flex align-center justify-between mb-8 mh-24">
        <h2>My Students</h2>
      </div>
      <div className="flex align-center justify-between mb-8 mh-24">
        <Search
          placeholder="Search Students"
          enterButton="Search"
          size="large"
          onSearch={onSearch}
          value={search}
          onChange={e => setSearch(e.target.value)}
        />
      </div>
      <Modal
        title="Confirmation Message"
        centered
        visible={visible}
        onOk={() => deleteStudentHandler(false)}
        onCancel={() => onCancelPopup()}
        width={700}
      >
        <h3>Are You Sure You want to remove selected Students?</h3>
        <br/>
        <Checkbox onChange={onChangeCheckbox}>
          Also Remove Students From Classes
        </Checkbox>
      </Modal>
      {studentsLoading ? (
        <div className="flex flex-center fl-1">
          <Loader type="Puff" color="#354AC9" height={50} width={50} />
        </div>
      ) : studentsError ? (
        <TabError
          onClick={() => dispatch(fetchStudents(search))}
          message={studentsError.message}
          label="Reload Students"
        />
      ) : (
        <div id="scrollableContainer" className="my-students-list-container fl-1 ml-24">
          <br/>
          {renderStudentsList()}
        </div>
      )}
    </div>
  );
};

export default MyStudents;
