import "./Button.scss"

const RefreshButton = ({ onClick, label }) => {
  return (
    <button className="btn-refresh" onClick={onClick}>
      <div className="icon icon-48 icon-refresh-b" />
      <p>{label}</p>
    </button>
  )
}

export default RefreshButton