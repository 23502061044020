import StudentAvatar from "../../../images/student-avatar.jpg";
import "./StudentsBarListItem.scss";
const StudentsBarListItem = ({ student, className = "", onClick }) => {
  return (
    <li
      className={`student-bar-list-item flex align-center ${className}`}
      onClick={() => onClick(student)}
    >
      <img
        src={student.avatar || StudentAvatar}
        alt="avatar"
        className="mr-16"
      />
      <div className="flex-column">
        <h3 className="bold font-primary">
          {student.first_name} {student.last_name}
        </h3>
        <p>{student.username.split("@")[0]}</p>
        <p>@{student.username.split("@")[1]}</p>
      </div>
    </li>
  );
};

export default StudentsBarListItem;
