import { Fragment, useEffect, useState } from "react";
import {TailSpin as Loader} from "react-loader-spinner";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { gamerizeUnauthenticated } from "../../apis/gamerize";
import Button from "../../components/Button/Button";
import "./ForgotPassword.scss";
import "../../styles/typography.scss";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const isAuthenticated = useSelector(({ auth }) => auth.isAuthenticated);

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/");
    }
  }, [isAuthenticated, navigate]);

  const [username, setUsername] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);

  const resetFormSubmit = async (e) => {
    e.preventDefault();
    try {
      setError("");
      setLoading(true);
      const res = await gamerizeUnauthenticated.post(
        "/users/request-reset-email/",
        { username: username }
      );
      setSuccess(res.data.success);
    } catch (e) {
      setError("Invalid username!");
      setLoading(false);
    }
  };

  return (
    <div className="login-page flex-column flex-center">
      <h1 className="mb-24">Forgot Password</h1>
      {error && <p className="mb-16 font-danger bold">{error}</p>}
      {success ? (
        <h3 className="text-center">{success}</h3>
      ) : (
        <Fragment>
          <p className="mb-24 text-center">
            Enter your username below and we'll <br /> send you a password reset
            link
          </p>
          <form onSubmit={resetFormSubmit} className="flex-column">
            <input
              placeholder="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              type="text"
              className="mb-16"
            />
            {loading ? (
              <div style={{ alignSelf: "center" }}>
                <Loader type="Puff" color="#354AC9" height={50} width={50} />
              </div>
            ) : (
              <Fragment>
                <Button
                  label="Send Reset Link"
                  primary
                  type="submit"
                  disabled={!username}
                />
              </Fragment>
            )}
          </form>
        </Fragment>
      )}
      <div className="flex align-center mt-24">
        <Link to="/login" className="link">
          Back to Login
        </Link>
      </div>
    </div>
  );
};

export default ForgotPassword;
