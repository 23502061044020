import { Table, Dropdown, Menu, Select, Input } from 'antd';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';

import './AdventureList.scss';
import {TailSpin as Loader} from 'react-loader-spinner';
import 'antd/dist/reset.css';
import { DownOutlined } from '@ant-design/icons';
import moment from 'moment';

import TabError from '../../components/TabError/TabError';
import { fetchAdventures } from '../../actions/adventures';

const { Option } = Select;
const { Search } = Input;
const selectAdventures = ({ adventures }) => adventures.data;
const selectAdventuresPagination = ({ adventures }) => adventures.pagination;
const selectAdventuresLoading = ({ adventures }) => adventures.loading;
const selectAdventuresError = ({ adventures }) => adventures.error;

const adventuresArray = createSelector([selectAdventures], adventures =>
  Object.values(adventures)
);

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  
  {
    title: 'Learning Aim',
    dataIndex: 'learning_aim',
    render: value =>
      value === 'spelling_the_word'
        ? 'Spelling the Word'
        : value === 'writing_the_sentence'
        ? 'Write the Sentence'
        : value === 'saying_the_word'
        ? 'Saying the Word'
        : value === 'say_the_sentence'
        ? 'Say the Sentence'
        : '',
  },
  {
    title: "Start Date",
    dataIndex: "start_date",
    defaultSortOrder: "descend",
    render: (value) => moment(value).format("DD MMM, YYYY"),
  },
  {
    title: "End Date",
    dataIndex: "end_date",
    defaultSortOrder: "descend",
    render: (value) => moment(value).format("DD MMM, YYYY"),
  },

  {
    title: 'Date Created',
    dataIndex: 'date_created',
    defaultSortOrder: 'descend',
    render: value => moment(value).format('DD MMM, YYYY'),
  },

  {
    title: 'Date Updated',
    dataIndex: 'date_updated',
    defaultSortOrder: 'descend',
    render: value => moment(value).format('DD MMM, YYYY'),
  },

  {
    title: 'Action',
    key: 'action',
    width: 160,
    fixed: 'right',
    render: value => (
      <span className="table-action-buttons">
        <Link
          className="text-button view"
          style={{ color: 'white' }}
          to={`/adventures/list/adventure/view/${value.id}`}
        >
          View
        </Link>
        <Dropdown
          trigger={['click']}
          className="table-more-button"
          overlay={
            <Menu className="actions-menu">
              <Link
                className="text-button view"
                style={{ color: 'white' }}
                to={`/adventures/list/adventure/edit/${value.id}`}
              >
                Edit
              </Link>
            </Menu>
          }
        >
          <span className="text-button ant-dropdown-link">
            More <DownOutlined />
          </span>
        </Dropdown>
      </span>
    ),
  },
];

const AdventureList = () => {
  const adventures = useSelector(adventuresArray);
  const adventuresPagination = useSelector(selectAdventuresPagination);
  const adventuresLoading = useSelector(selectAdventuresLoading);
  const adventuresError = useSelector(selectAdventuresError);

  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [adventureFilterValue, setAdventureFilterValue] = useState(null);

  const dispatch = useDispatch();
  const offset = (page - 1) * pageSize;
  const limit = pageSize;

  useEffect(() => {
    dispatch(fetchAdventures(search, limit, offset, adventureFilterValue));
  }, [dispatch, page, pageSize]);

  const handlePagination = () => {
    return {
      current: page,
      pageSize: pageSize,
      total: adventuresPagination.count,
      onChange: (page, pageSize) => {
        setPage(page);
        setPageSize(pageSize);
      },
    };
  };

  const onSearch = value => {
    dispatch(fetchAdventures(search, limit, offset, adventureFilterValue));
    setPage(1);
  };

  const handleChange = value => {
    setAdventureFilterValue(value);
    dispatch(fetchAdventures(search, limit, offset, value));
  };

  const renderAdventureList = () => (
    <Table
      title={null}
      pagination={handlePagination()}
      columns={columns}
      dataSource={adventures}
      showHeader
      size="default"
      bordered={false}
    />
  );

  return (
    <div className="find-adventures-page flex-column fl-1">
      {adventuresLoading ? (
        <div className="flex flex-center fl-1">
          <Loader type="Puff" color="#354AC9" height={50} width={50} />
        </div>
      ) : adventuresError ? (
        <TabError
          onClick={() =>
            dispatch(fetchAdventures(search, limit, offset, adventureFilterValue))
          }
          message={adventuresError.message}
          label="Reload Adventures"
        />
      ) : (
        <>
          <h1 className="mh-24 mt-24 mb-16">Adventure List</h1>
          <div className="flex align-center ph-24 pv-16 ml-8">
            <Search
              placeholder="Search Adventures"
              enterButton="Search"
              size="large"
              onSearch={onSearch}
              value={search}
              onChange={e => setSearch(e.target.value)}
            />
            <br/>
          </div>  
          <div className="flex justify-end align-center ph-24 pv-16 ml-8">
            <Select
              placeholder="Adventure Filters"
              style={{
                width: 200,
              }}
              onChange={handleChange}
              value={adventureFilterValue}
              allowClear
            >
              <Option value="expired_adventures">Expired Adventures</Option>
              <Option value="cloned_adventures">Cloned Adventures</Option>
              <Option value="active_adventures">Active Adventures</Option>
            </Select>
          </div>

          <div className="overflow-hidden flex-column fl-1">
            <div id="scrollableContainer" className="list-container fl-1 ml-24">
              {renderAdventureList()}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default AdventureList;
