import { Checkbox } from "antd";
import { useState, useCallback, useEffect, useRef } from "react";
import Autosuggest from "react-autosuggest";
import {TailSpin as Loader} from "react-loader-spinner";
import gamerize from "../../../apis/gamerize";
import Button from "../../../components/Button/Button";
import WordListItem from "../../../components/WordListItem/WordListItem";
import getSubscriptionDetail from "../../../helper/getSubscriptionDetail";
const DataEntry = ({ words, onNext, onBack }) => {
  const [search, setSearch] = useState("");
  const [searchWord, setSearchWord] = useState("");
  const [phonicWordsOnly, setPhonicsWordsOnly] = useState(false);
  const [selectedWords, setSelectedWords] = useState(words || []);
  const [error, setError] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const handleBack = () => onBack(selectedWords);
  const subscriptionStatus = useRef("");

  const handleNext = () => {
    if (selectedWords.length >= 4 && selectedWords.length <= 8) {
      onNext(selectedWords);
    } else {
      setError("Please pick between 4-8 words.");
    }
  };

  const subscriptionData = async () => {
    setError("");
    try {
      const subscriptionDetail = await getSubscriptionDetail();
      if (subscriptionDetail.is_valid) {
        subscriptionStatus.current = 1;
      } else {
        subscriptionStatus.current = 0;
      }
    } catch (error) {
      setError("Error Creating Subscriptions");
    }
  };
  useEffect(() => {
    subscriptionData();
  }, []);
  const timeout = (delay) => {
    return new Promise((res) => setTimeout(res, delay));
  };

  const delaySearchWords = (value) => {
    setSearchWord(value, phonicWordsOnly);
  };

  useEffect(() => {
    const delaySearch = setTimeout(() => {
      searchWords(searchWord, phonicWordsOnly);
    }, 1000);
    return () => clearTimeout(delaySearch);
  }, [searchWord, phonicWordsOnly]);

  const searchWords = useCallback(
    async ({ value }) => {
      setError("");
      setLoading(true);
      try {
        const response = await gamerize.get(
          `/dictionary/word-list/?is_subscribed=${subscriptionStatus.current}&search=${value}&language_to=1&language_from=2&limit=15&has_phonics_only=${phonicWordsOnly ? 'True' : 'False'}`
        );
        const filtered = response.data.results.filter(
          ({ id }) => !selectedWords.map(({ id }) => id).includes(id)
        );
        setSuggestions(filtered);
        setLoading(false);
      } catch (error) {
        setError("Error Fetching words");
        setLoading(false);
      }
    },
    [searchWord, phonicWordsOnly]
  );

  const addWord = async (word) => {
    setSelectedWords((prevState) => [...prevState, word]);
    setSearch("");
  };

  const removeWord = (word) => {
    const filteredWords = selectedWords.filter(({ id }) => id !== word.id);
    setSelectedWords(filteredWords);
  };

  const getSuggestionValue = (suggestion) => {
    addWord(suggestion);
    return "";
  };

  const renderSuggestion = ({
    word,
    to_word,
    to_sentence,
    translation,
    id,
    word_image,
    from_word,
  }) => {
    return (
      <div className="word-suggestion">
        <img src={word_image} alt="word" className="mr-16" />
        <div className="flex-column">
          <h3>{to_word}</h3>
          <p>{to_sentence}</p>
          <p>{from_word}</p>
        </div>
      </div>
    );
  };

  const renderWordsList = () => (
    <div className="words-grid p-24">
      {selectedWords.map((word) => (
        <WordListItem
          key={word.id}
          remove={() => removeWord(word)}
          word={word}
        />
      ))}
    </div>
  );

  const suggestionProps = {
    placeholder: "Search for a word",
    value: search,
    onChange: (e, { newValue }) => setSearch(newValue),
    disabled: selectedWords.length >= 8,
  };

  const onChangePhonicsCheckBox = (box) => {
    setPhonicsWordsOnly(!phonicWordsOnly);
  };

  return (
    <div className="fl-1 flex-column overflow-hidden">
      <h3 className="ph-24 pv-16">Step 2: Data Entry</h3>
      <div className="flex align-center pv-16 ph-24">
        <div>
        <div>
        <Checkbox className="mr-16" onChange={onChangePhonicsCheckBox} checked={phonicWordsOnly}>Search for phonics and sight-words</Checkbox>
        </div>
        <label className="mr-16">Enter Word:</label>
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={delaySearchWords}
          onSuggestionsClearRequested={() => setSuggestions([])}
          renderSuggestion={renderSuggestion}
          getSuggestionValue={getSuggestionValue}
          inputProps={suggestionProps}
        />
        {loading && (
          <div className="ml-16">
            <Loader type="Puff" color="#354AC9" height={24} width={24} />
          </div>
        )}
        </div>
        {error && <p className="ml-16 error-message">{error}</p>}
      </div>
      <h3 className="pv-16 ph-24">Word List</h3>
      <div className="scroll-vertical fl-1 mb-16">{renderWordsList()}</div>
      <div className="flex mh-24 mb-24">
        <Button label="Back" onClick={handleBack} primary className="mr-24" />
        <Button label="Next" onClick={handleNext} primary />
      </div>
    </div>
  );
};

export default DataEntry;
