import { Route, Routes } from "react-router-dom";
import SideBar from "../components/SideBar/SideBar";
import ClassBattle from "../pages/ClassBattle/ClassBattle";
import ClassBattleHistory from "../pages/ClassBattleHistory/ClassBattleHistory";
import NewClassBattle from "../pages/NewClassBattle/NewClassBattle";

const ClassBattleRouter = () => {
  return (
    <div className="flex page">
      <SideBar
        pages={[
          { title: "Class Battle", link: `/class-battle/dashboard` },
          { title: "New Class Battle", link: `/class-battle/new-battle` },
          { title: "Class Battle History", link: `/class-battle/history` },
        ]}
      />
      <Routes>
        <Route path={`dashboard`} element={<ClassBattle/>} />
        <Route path={`new-battle`} element={<NewClassBattle/>} />
        <Route
          path={`history`}
          element={<ClassBattleHistory/>}
        />
      </Routes>
    </div>
  );
};

export default ClassBattleRouter;
