import {
  FETCH_USER,
  LOGIN,
  LOGOUT,
  SIGN_UP,
  UPDATE_USER,
  INSTITUTE_CODE,
} from "../actions/types";

const access_token = localStorage.getItem("access_token")
  ? localStorage.getItem("access_token")
  : "";
const refresh_token = localStorage.getItem("refresh_token")
  ? localStorage.getItem("refresh_token")
  : "";

const user_type = localStorage.getItem("user_type")
  ? localStorage.getItem("user_type")
  : "";

const DEFAULT_STATE = {
  uuid: "",
  email: "",
  first_name: "",
  last_name: "",
  school: "",
  user_type,
  access_token,
  refresh_token,
  isAuthenticated: access_token && refresh_token ? true : false,
  error: "",
};

const authReducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case LOGIN:
      localStorage.setItem("access_token", action.payload.access_token);
      localStorage.setItem("refresh_token", action.payload.refresh_token);
      localStorage.setItem("user_type", action.payload.user_type);
      return { ...state, ...action.payload, isAuthenticated: true };
    case SIGN_UP:
      localStorage.setItem("access_token", action.payload.access_token);
      localStorage.setItem("refresh_token", action.payload.refresh_token);
      localStorage.setItem("user_type", action.payload.user_type);
      return { ...state, ...action.payload, isAuthenticated: true };
    case LOGOUT:
      localStorage.removeItem("access_token");
      localStorage.removeItem("refresh_token");
      localStorage.removeItem("user_type");
      return {
        ...DEFAULT_STATE,
        access_token: "",
        refresh_token: "",
        user_type: "",
        isAuthenticated: false,
      };
    case FETCH_USER:
      return {
        ...state,
        ...action.payload,
      };
    case UPDATE_USER:
      return {
        ...state,
        ...action.payload,
      };
    case INSTITUTE_CODE:
      return {
        ...state,
        school: action.payload,
      };
    default: {
      return state;
    }
  }
};

export default authReducer;
