import { createStore, applyMiddleware, compose } from "redux";

import {thunk} from "redux-thunk";
import rootReducer from "../reducers/index";

const initialState = {};

const middleWare = [thunk];

const configureStore = () =>
  createStore(
    rootReducer,
    initialState,
    compose(applyMiddleware(...middleWare))
  );

export default configureStore;
