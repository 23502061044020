import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import {TailSpin as Loader} from "react-loader-spinner";
import { fetchUser } from "../actions/auth";
import NavBar from "../components/NavBar/NavBar";

const OwnerRoute = () => {
  const dispatch = useDispatch();

  const isAuthenticated = useSelector(({ auth }) => auth.isAuthenticated);
  const userType = useSelector(({ auth }) => auth.user_type);

  const user = useSelector(({ auth }) => auth.email)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    dispatch(fetchUser());
  }, [dispatch]);

  useEffect(() => {
    if (user) {
      setLoading(false)
    } else {
      setLoading(true)
    }
  }, [user])

  if (!isAuthenticated || userType !== 'owner') {
    // Redirect if not authenticated or if the user is not an 'owner'
    return <Navigate to="/login" replace />;
  }

  if (loading) {
    return (
      <div className="flex flex-center" style={{ height: "100vh" }}>
        <Loader type="Puff" color="#354AC9" height={50} width={50} />
      </div>
    );
  }

  return (
    <Fragment>
      <NavBar />
      <Outlet />
    </Fragment>
  );
};

export default OwnerRoute;
