import { Route, Routes } from "react-router-dom";
import SideBar from "../components/SideBar/SideBar";
import Adventures from "../pages/Adventures/Adventures";
import EditAdventure from "../pages/EditAdventure/EditAdventure";
import AdventureList from "../pages/AdventureList/AdventureList";
import NewAdventure from "../pages/NewAdventure/NewAdventure";
import PreviewAdventure from "../pages/PreviewAdeventure/PreviewAdventure";
import Bundles from "../pages/Bundles/Bundles";
import BundleView from "../pages/BundleView/BundleView";

const AdventuresRouter = () => {
  const PAGES = [
    { title: "Adventure", link: "/adventures/dashboard" },
    { title: "Create Adventure", link: "/adventures/create" },
    { title: "Adventures List", link: "/adventures/list" },
    { title: "Bundles", link: "/adventures/bundles" },
    { title: "Assign Adventure", link: "/classes/assign-adventure" },
  ];

  return (
    <div className="flex page">
      <SideBar pages={PAGES} />
      <Routes>
        <Route path="dashboard" element={<Adventures />} />
        <Route path="create" element={<NewAdventure />} />
        <Route path="list" element={<AdventureList />} />
        <Route path="list/adventure/view/:id" element={<PreviewAdventure />} />
        <Route path="dashboard/adventure/view/:id" element={<PreviewAdventure />} />
        <Route path="list/adventure/edit/:id" element={<EditAdventure />} />
        <Route path="dashboard/adventure/edit/:id" element={<EditAdventure />} />
        <Route path="bundles" element={<Bundles />} />
        <Route path="bundles/view/:id" element={<BundleView />} />
        <Route path="bundles/view/:bundleid/adventure/view/:id" element={<PreviewAdventure />} />
      </Routes>
    </div>
  );
};

export default AdventuresRouter;
