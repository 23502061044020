import { useState } from "react";
import AimListItem from "../../../components/AimListItem/AimListItem";
import Button from "../../../components/Button/Button";

const aims = [
  {
    id: "0",
    label: "Read & Write Words",
    aim: "spelling_the_word",
    description: "The learners be able to read and write the target words.",
  },
  {
    id: "1",
    label: "Read & Write Sentences",
    aim: "writing_the_sentence",
    description:
      "The learners be able to read and write sentences with the target words.",
  },
  {
    id: "2",
    label: "Understand & Speak Words",
    aim: "saying_the_word",
    description:
      "The learners be able to understand the spoken form of the target words and be able to say the target words.",
  },
  {
    id: "3",
    label: "Understand & Speak Sentences",
    aim: "say_the_sentence",
    description:
      "The learners be able to understand the spoken form of the target sentences and be able to say the target example sentence.",
  },
];

const NameAim = ({ values, onNext }) => {
  const [adventureName, setAdventureName] = useState(values.name);
  const [adventureAim, setAdventureAim] = useState(values.aim || aims[0]);
  const [error, setError] = useState("");

  const handleNext = () => {
    if (adventureName === "" || adventureAim === "") {
      setError("Please enter a valid name");
    } else {
      setError("");
      onNext({ name: adventureName, aim: adventureAim });
    }
  };

  const renderAimList = () => {
    return (
      <div className="aim-list ph-24">
        {aims.map((aim) => (
          <AimListItem
            key={aim.id}
            aim={aim}
            selected={adventureAim && adventureAim.aim === aim.aim}
            onClick={() => setAdventureAim(aim)}
          />
        ))}
      </div>
    );
  };
  return (
    <div className="overflow-hidden fl-1 flex-column">
      <h3 className="ph-24 pv-16">Step 1: Name & Aim</h3>
      <div className="flex align-center pv-16 ph-24">
        <label className="mr-16">Adventure Name:</label>
        <input
          value={adventureName}
          onChange={(e) => setAdventureName(e.target.value)}
        />
        {error && <p className="ml-16 error-message">{error}</p>}
      </div>
      <div className="scroll-vertical fl-1 mb-16">{renderAimList()}</div>
      <Button
        label="Next"
        onClick={handleNext}
        primary
        className="mh-24 mb-24"
      />
    </div>
  );
};

export default NameAim;
