import _ from "lodash";
import { FETCH_TEACHERS, FETCH_TEACHERS_ERROR, FETCH_TEACHERS_SUCCESS, DELETE_TEACHER, DELETE_TEACHER_SUCCESS, DELETE_TEACHER_ERROR, FETCH_NEXT_TEACHERS_SUCCESS } from "../actions/types";

const DEFAULT_STATE = {
  loading: false,
  error: null,
  pagination: {
    count: 0,
    next: null,
    prev: null
  },
  data: {}
};

const teachersReducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case FETCH_TEACHERS:
    case DELETE_TEACHER:
      return { ...state, loading: true, error: null };
    case FETCH_TEACHERS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        pagination: {
          count: action.payload.count,
          next: action.payload.next,
          previous: action.payload.previous
        },
        data: { ..._.mapKeys(action.payload.results, "id") }
      };
    case FETCH_NEXT_TEACHERS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        pagination: {
          count: action.payload.count,
          next: action.payload.next,
          previous: action.payload.previous
        },
        data: { ...state.data, ..._.mapKeys(action.payload.results, "id") }
      }
    case DELETE_TEACHER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: _.omit(state.data, action.payload)
      };
    case FETCH_TEACHERS_ERROR:
    case DELETE_TEACHER_ERROR:
      return { ...state, loading: false, error: action.payload }
    default:
      return state;
  }
};

export default teachersReducer;
