import { useMemo, useState } from "react";
import IconButton from "../../../components/Button/IconButton";
import Button from "../../../components/Button/Button";
import gamerize from "../../../apis/gamerize";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { ConfirmationDialog } from "../../../components/ConfirmationDialog";
import {TailSpin as Loader} from "react-loader-spinner";
import InvoiceDocument from "../../SubscriptionDetail/InvoiceDocument";
import "./BankRequestListItem.scss";

const BankRequestListItem = ({ paymentRequest, onIgnore }) => {
  const [error, setError] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [receiptStatus, setReceiptStatus] = useState(null);
  const [loading, setloading] = useState(false);

  const imageSelectedHandler = async (event) => {
    setSelectedImage(event.target.files);
  };

  const showRecipt = () => {
    setloading(true);
    const url = paymentRequest.transaction_proof;
    setloading(false);
    window.location.href = url;
  };

  const DownloadPdf = (invoiceDetail) => {
    return useMemo(
      () => (
        <PDFDownloadLink
          document={<InvoiceDocument invoiceDetail={invoiceDetail} />}
          fileName={`Bank transfer invoice ${invoiceDetail.id}`}
        >
          {() =>
            paymentRequest.transaction_proof !== null ? (
              <Button success className="mr-16" label="Generate Invoice" />
            ) : (
              <Button success className="mr-288" label="Generate Invoice" />
            )
          }
        </PDFDownloadLink>
      ),
      [invoiceDetail]
    );
  };
  const handleClose = () => {
    ConfirmationDialog({
      title: "Are You Sure?",
      onConfirm: () => {
        onIgnore();
      },
    });
  };

  const imageUploadHandler = async () => {
    setError(null);
    setReceiptStatus(null);
    setloading(true);
    if (selectedImage !== null) {
      try {
        const formData = new FormData();
        formData.append("transaction_proof", selectedImage[0]);
        await gamerize.patch(
          `/users/bank_transfer/requests/${paymentRequest.requestID}/`,
          formData
        );
        setReceiptStatus("Receipt Uploaded!!");
        setTimeout(() => window.location.reload());
      } catch (error) {
        setError("There was an error fetching requests");
      }
    } else {
      setError("Error! Choose File before Uploading");
    }
    setloading(false);
  };
  return (
    <div key={paymentRequest.id} className="bank-request-list-item mb-32">
      <div className="flex align-center">
        <div className="flex-column">
          <p className="mb-4"> Username : {paymentRequest.username}</p>
          <p className="mb-4"> Subscription Type : {paymentRequest.type}</p>
          <p className="mb-4">Seats : {paymentRequest.seats}</p>
          <p className="mb-4">Months : {paymentRequest.months}</p>
          <p className="mb-4">Total Amount : ¥{paymentRequest.payment >> 0}</p>
          <h4>Request Status : {paymentRequest.requestStatus}</h4>
        </div>
      </div>

      <div>{error !== "" && <p className="error mb-16">{error}</p>}</div>

      <div>
        {receiptStatus !== "" && <p className=" mb-16">{receiptStatus}</p>}
        {loading && (
          <div className="flex flex-center fl-1">
            <Loader type="Puff" color="#354AC9" height={50} width={50} />
          </div>
        )}
      </div>

      <div className="flex align-center">
        {DownloadPdf(paymentRequest)}
        {paymentRequest.requestStatus === "Pending" ? (
          <>
            <div>
              {selectedImage && (
                <Button
                  className="mr-16"
                  short
                  onClick={imageUploadHandler}
                  label="Upload receipt"
                />
              )}
              <input
                type="file"
                name="myImage"
                onChange={imageSelectedHandler}
              />
            </div>
            <IconButton
              onClick={handleClose}
              icon="close"
              className="circle ml-16"
            />
          </>
        ) : paymentRequest.transaction_proof !== null ? (
          <Button
            className="mr-144"
            primary
            onClick={showRecipt}
            label="View Receipt"
          />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default BankRequestListItem;
