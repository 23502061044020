import { PDFDownloadLink } from "@react-pdf/renderer";
import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { updateUser, generateNewCode } from "../../actions/auth";
import gamerize from "../../apis/gamerize";
import Button from "../../components/Button/Button";
import DetailsDocument from "./DetailsDocument";
import Modal from "../../components/Modal/Modal";
import { ConfirmationDialog } from "../../components/ConfirmationDialog";
import "./ProfileOptions.scss";

const OwnerOptions = ({ user }) => {
  const dispatch = useDispatch();

  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [adventureLimit, setAdventureLimit] = useState("");
  const [updates, setUpdates] = useState(false);
  const [error, setError] = useState("");
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    setFirstName(user?.first_name || "");
    setLastName(user?.last_name || "");
    setEmail(user?.email || "");
    setCode(user?.school.code || "");
    setAdventureLimit(user?.school.adventure_limit || "");
  }, [user]);

  useEffect(() => {
    const nameChange = first_name !== user?.first_name && first_name !== "";
    const lastNameChange = last_name !== user?.last_name && last_name !== "";
    const emailChange = email !== user?.email && email !== "";
    const adventureValueChange =
      adventureLimit !== user?.school.adventure_limit && adventureLimit !== "";
    if (nameChange || lastNameChange || emailChange || adventureValueChange) {
      setUpdates(true);
    } else {
      setUpdates(false);
    }
  }, [first_name, last_name, email, adventureLimit, user]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (adventureLimit < 0) {
      setError("Error! Adventure limit should be a positive number");
      return;
    }
    const updatedUser = {
      first_name,
      last_name,
      email,
    };
    const updatedAdventureLimit = {
      school: user.school.id,
      limit: adventureLimit,
    };
    dispatch(updateUser(updatedUser, updatedAdventureLimit));
  };
  const handleRefreshCode = () => {
    dispatch(generateNewCode());
    setShowPopup(false);
  };

  const DownloadPdf = (name, code) => {
    return useMemo(
      () => (
        <PDFDownloadLink
          document={<DetailsDocument name={name} code={code} />}
          fileName="institute details.pdf"
        >
          {({ blob, url, loading, error }) =>
            !loading && <Button label="Print Profile Slip" primary />
          }
        </PDFDownloadLink>
      ),
      [name, code]
    );
  };

  const handleConfirmationMessage = () => {
    ConfirmationDialog({
      title: "Are You Sure?",
      onConfirm: () => {
        handleRefreshCode();
      },
    });
  };

  return (
    <div className="profile-options-page flex-column fl-1 p-24">
      <div className="flex justify-between align-center mb-24">
        <h2>Profile Options</h2>
        {DownloadPdf(`${user.first_name} ${user.last_name}`, user.school.code)}
      </div>
      <form className="profile-form" onSubmit={handleSubmit}>
        <div className="flex mb-16 align-center justify-between">
          <h4 className="mr-24">First Name</h4>
          <input
            className="form-input"
            value={first_name}
            onChange={({ target }) => setFirstName(target.value)}
          />
        </div>
        <div className="flex mb-16 align-center justify-between">
          <h4 className="mr-24">Last Name</h4>
          <input
            className="form-input"
            value={last_name}
            onChange={({ target }) => setLastName(target.value)}
          />
        </div>
        <div className="flex mb-16 align-center justify-between">
          <h4 className="mr-24">Email</h4>
          <input
            className="form-input"
            value={email}
            onChange={({ target }) => setEmail(target.value)}
          />
        </div>

        <div className="flex mb-16 align-center justify-between">
          <h4 className="mr-24">Adventure Limit</h4>
          <input
            className="form-input"
            value={adventureLimit}
            type="number"
            onChange={({ target }) => setAdventureLimit(target.value)}
          />
        </div>
        <div className="flex mb-16 align-center justify-between">
          <h4 className="mr-24">Institute Code</h4>
          <h3>{code}</h3>
        </div>
        <div className="flex mb-32">
          <Button
            className="fl-1 mr-16"
            primary
            type="button"
            onClick={handleConfirmationMessage}
            label="Refresh Code"
          />
          <Button
            className="fl-1"
            primary
            type="submit"
            label="Update"
            disabled={!updates}
          />
        </div>
        <Modal
          modalIsOpen={showPopup}
          onYesClick={() => handleRefreshCode()}
          onCancelClick={() => {
            setShowPopup(false);
          }}
        />
        {error && <p className="error font-danger mb-32">{error}</p>}
      </form>

      <Link to="/privacy" className="link">
        Privacy Policy
      </Link>
    </div>
  );
};

export default OwnerOptions;
