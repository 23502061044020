import { PAYMENT_OPTION } from "./types";

export const setPaymentOption = (paymentType) => {
  return {
    type: PAYMENT_OPTION,
    payload: {
      status: paymentType,
    },
  };
};
