import Select from "react-select";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { DatePicker, Space } from "antd";

import dayjs from "dayjs";
import { ErrorDialog } from "../../../components/ConfirmationDialog";
import Button from "../../../components/Button/Button";
import { aims } from "../../../constants";
import utc from 'dayjs/plugin/utc';


const SuccesMulitpleClasses = ({
  choice,
  selectedAdventures,
  bundleListFlag,
  onBack,
  onGo,
}) => {
  const selectAdventuresError = ({ adventures }) => adventures.error;
  const adventuresError = useSelector(selectAdventuresError);

  const [aim, setAim] = useState("");
  const [error, setError] = useState(false);
  const [index, setIndex] = useState(0);
  const [checkDate, setCheckDate] = useState(true);

  dayjs.extend(utc);

  const currentDate = dayjs();

  const [startDate, setStartDate] = useState(currentDate);
  const [endDate, setEndDate] = useState(currentDate.add(2, "month"));

  const dateFormat = "YYYY/MM/DD";

  useEffect(() => {
    const filteredAims = aims.filter((aims, index) => {
      setIndex(index);
      return index;
    });
  }, []);

  const assignHandler = () => {
    if (checkDate) {
      onGo(aim, startDate, endDate);
      if (adventuresError) {
        setError("Failed to Assign Adventure");
      }
    }
  };

  const startDateChangeHandler = (date, dateString) => {
    setStartDate(date);
    setCheckDate(true);
    if (!date) {
      setStartDate('');
    } else {
      if (
        dayjs(date).format("YYYY-MM-DD") < dayjs(endDate).format("YYYY-MM-DD")
      ) {
        setCheckDate(true);
      } else if (
        dayjs(date).format("YYYY-MM-DD") >
          dayjs(endDate).format("YYYY-MM-DD") ||
        dayjs(date).format("YYYY-MM-DD") === dayjs(endDate).format("YYYY-MM-DD")
      ) {
        setCheckDate(false);
        ErrorDialog({
          title: "Start date must be earlier than end date",
        });
      } else {
        setCheckDate(false);
        ErrorDialog({
          title: "Please Select Valid Date ",
        });
      }
    }
  };

  const endDateChangeHandler = (date, dateString) => {
    setEndDate(date);
    if (!date) {
      setEndDate("");
    } else {
      if (
        dayjs(startDate).format("YYYY-MM-DD") >
          dayjs(date).format("YYYY-MM-DD") ||
        dayjs(date).format("YYYY-MM-DD") ===
          dayjs(startDate).format("YYYY-MM-DD")
      ) {
        setCheckDate(false);
        ErrorDialog({
          title: "Start date must be earlier than end date",
        });
      } else {
        setCheckDate(true);
      }
    }
  };

  return (
    <div className="go-choose-adventure flex-column fl-1">
      <h1 className="mb-16 mh-24 text-heading mg-top">Summary</h1>
      <div className="go-success-card mh-24">
        <h2 className="mb-8 text-heading">Class Name:</h2>
        {Object.values(choice.class).map(({ name }) => (
          <p className="mb-16" key={name}>
            {name}
          </p>
        ))}

        {bundleListFlag ? (
          <>
            <div className="flex">
              <h2 className="mb-8 mt-8 text-heading" style={{ width: "300px" }}>
                Adventure Names:
              </h2>{" "}
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </div>
            {selectedAdventures &&
              selectedAdventures.map((obj) => {
                return (
                  <div className="flex">
                    <div style={{ width: "300px" }}>{obj.name}:</div>{" "}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </div>
                );
              })}

            <br />
            <h2 className="mb-8 text-heading">Pick a learnings Aim:</h2>
            <div>
              <>
                <Select
                  options={aims}
                  onChange={({ value }) => setAim(value)}
                  defaultValue={
                    selectedAdventures?.length === 1 ? aims[0] : aims[0]
                  }
                  // defaultValue={{ value: 'say_the_sentence', label: 'Say the sentence' }}

                  className="adventures-list-filter"
                />
              </>
            </div>
          </>
        ) : (
          <>
            <div className="flex">
              <h2 className="mb-8 mt-8 text-heading" style={{ width: "300px" }}>
                Adventure Names:
              </h2>{" "}
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {/* <h2  className="mb-8 mt-8 text-heading"> Learning Aims</h2> */}
            </div>
            {selectedAdventures &&
              Object.entries(selectedAdventures).map(([key, value]) => {
                return (
                  <div className="flex">
                    <div style={{ width: "300px" }}>{value.name}:</div>{" "}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {/* <div  >{obj.learning_aim}</div> */}
                  </div>
                );
              })}
            <br />
          </>
        )}
        <br />
        <div className="flex">
          <h2 style={{ width: "250px" }} className="mb-8 text-heading">
            Start Date
          </h2>
          <h2 style={{ width: "250px" }} className="mb-8 text-heading">
            End Date
          </h2>
        </div>

        <div className="flex">
          <Space style={{ width: "250px" }} direction="vertical">
            <DatePicker
              defaultValue={startDate}
              format={dateFormat}
              onChange={startDateChangeHandler}
            />
          </Space>
          <Space style={{ width: "250px" }} direction="vertical">
            <DatePicker
              defaultValue={endDate}
              format={dateFormat}
              onChange={endDateChangeHandler}
            />
          </Space>
        </div>

        {error && <p className="font-danger text-center bold">{error}</p>}
      </div>

      <div className="flex justify-end mt-auto mh-24 mb-24 summary-btns">
        <Button label="Back" primary onClick={onBack} className="mr-24" wide />
        <Button
          label="Assign"
          primary
          onClick={assignHandler}
          wide
          disabled={!checkDate}
        />
      </div>
    </div>
  );
};

export default SuccesMulitpleClasses;
