import { Fragment, useEffect, useState } from "react";
import {TailSpin as Loader} from "react-loader-spinner";
import { useSelector } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { gamerizeUnauthenticated } from "../../apis/gamerize";
import Button from "../../components/Button/Button";
import "./NewPassword.scss";
import "../../styles/typography.scss";
import { config } from "../../constants";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const NewPassword = () => {
  const navigate = useNavigate();
  const query = useQuery();
  const isAuthenticated = useSelector(({ auth }) => auth.isAuthenticated);
  const STUDENT_DOMAIN = window.location.href.includes(config.STUDENT_URL);

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/");
    }
  }, [isAuthenticated, navigate]);

  const token = query.get("token");
  const uidb64 = query.get("uidb64");

  if (!token || !uidb64) {
    navigate("/forgot-password");
  }

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);

  const newPasswordFormSubmit = async (e) => {
    e.preventDefault();
    setError("");

    if (password.length < 6) {
      setError(
        "The Password must be atleast 6 digits long. パスワードの長さは、半角6文字以上で設定してくださ。 "
      );
      return;
    }

    if (confirmPassword !== password) {
      setError("The passwords do not match!");
      return;
    }

    try {
      setLoading(true);
      await gamerizeUnauthenticated.patch("/users/password-reset-complete/", {
        password,
        uidb64,
        token,
      });
      setSuccess("Password Reset! You can now login using your new password!");
    } catch (e) {
      setError("Error updating the password!");
      setLoading(false);
    }
  };

  return (
    <div className="login-page flex-column flex-center">
      <h1 className="mb-24">Reset Password</h1>
      {error && (
        <p className="error mb-16 font-danger bold text-center">{error}</p>
      )}
      {success ? (
        <h3 className="text-center">{success}</h3>
      ) : (
        <Fragment>
          <form onSubmit={newPasswordFormSubmit} className="flex-column">
            <input
              placeholder="New Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type="password"
              className="mb-16"
            />
            <input
              placeholder="Confirm Password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              type="password"
              className="mb-16"
            />
            {loading ? (
              <div style={{ alignSelf: "center" }}>
                <Loader type="Puff" color="#354AC9" height={50} width={50} />
              </div>
            ) : (
              <Fragment>
                <Button
                  label="Update Password パスワード変更"
                  primary
                  type="submit"
                  disabled={password.length < 6 || confirmPassword.length < 6}
                />
              </Fragment>
            )}
          </form>
        </Fragment>
      )}
      <div className="flex align-center mt-24">
        {STUDENT_DOMAIN ? (
          <Link to="/" className="link">
            Back to Game
          </Link>
        ) : (
          <Link to="/login" className="link">
            Back to Login
          </Link>
        )}
      </div>
    </div>
  );
};

export default NewPassword;
