import gamerize from "../apis/gamerize";
import dayjs from "dayjs";

import { SuccessDialog, ErrorDialog } from "../components/ConfirmationDialog";
import {
  CREATE_CLASS,
  FETCH_CLASS,
  FETCH_CLASSES,
  UPDATE_CLASS,
  ASSIGN_CLASS_ADVENTURE,
  ASSIGN_CLASS_TEACHER,
  UNASSIGN_CLASS_TEACHER,
  ASSIGN_MULTIPLE_CLASSES_TEACHER,
  FETCH_CLASSES_ERROR,
  FETCH_CLASSES_SUCCESS,
  FETCH_CLASS_SUCCESS,
  FETCH_CLASS_ERROR,
  CREATE_CLASS_ERROR,
  CREATE_CLASS_SUCCESS,
  UPDATE_CLASS_SUCCESS,
  UPDATE_EDIT_CLASS_STATUS,
  UPDATE_REMOVE_STUDENT_STATUS,
  UPDATE_CLASS_ERROR,
  ASSIGN_CLASS_TEACHER_SUCCESS,
  UNASSIGN_CLASS_TEACHER_SUCCESS,
  UNASSIGN_CLASS_TEACHER_ERROR,
  ASSIGN_MULTIPLE_CLASSES_TEACHER_SUCCESS,
  ASSIGN_MULTIPLE_CLASSES_TEACHER_ERROR,
  ASSIGN_CLASS_ADVENTURE_SUCCESS,
  ASSIGN_CLASS_ADVENTURE_ERROR,
  ASSIGN_MULTIPLE_CLASSES_ADVENTURE,
  ASSIGN_MULTIPLE_CLASSES_ADVENTURE_ERROR,
  ASSIGN_MULTIPLE_CLASSES_ADVENTURE_SUCCESS,
  FETCH_NEXT_CLASSES_SUCCESS,
  DELETE_CLASS_SUCCESS,
  DELETE_CLASS_ERROR,
  DELETE_CLASS,
  UNASSIGN_CLASS_ADVENTURE,
  UNASSIGN_CLASS_ADVENTURE_SUCCESS,
  UNASSIGN_CLASS_ADVENTURE_ERROR,
} from "./types";

let start_date_end_date_format = 'YYYY-MM-DD HH:mm'

export const fetchClasses =
  (searchClasses = "", limit = 10, offset = 0) =>
  async (dispatch) => {
    dispatch({ type: FETCH_CLASSES });
    try {
      const response = await gamerize.get(
        `/schools/classes/?search=${searchClasses}&limit=${limit}&offset=${offset}`
      );
      dispatch({
        type: FETCH_CLASSES_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: FETCH_CLASSES_ERROR,
        payload: {
          message: "Error fetching classes.",
        },
      });
    }
  };

export const fetchNextClasses = () => async (dispatch, getState) => {
  const { next } = getState().classes.pagination;
  if (!next) return;
  const query = next.split("?")[1] || "";
  try {
    const response = await gamerize.get(`/schools/classes/?${query}`);
    dispatch({
      type: FETCH_NEXT_CLASSES_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_CLASSES_ERROR,
      payload: {
        message: "Error fetching classes.",
      },
    });
  }
};

export const fetchAssignedClasses =
  (limit = 10) =>
  async (dispatch) => {
    dispatch({ type: FETCH_CLASSES });
    try {
      const response = await gamerize.get(
        `/schools/classes/?limit=${limit}&is_teacher_assigned=False`
      );
      dispatch({
        type: FETCH_CLASSES_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: FETCH_CLASSES_ERROR,
        payload: {
          message: "Error fetching classes.",
        },
      });
    }
  };

export const fetchUnassignedClasses =
  (limit = 10) =>
  async (dispatch) => {
    dispatch({ type: FETCH_CLASSES });
    try {
      const response = await gamerize.get(
        `/schools/classes/?limit=${limit}&is_teacher_assigned=True`
      );
      dispatch({
        type: FETCH_CLASSES_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: FETCH_CLASSES_ERROR,
        payload: {
          message: "Error fetching classes.",
        },
      });
    }
  };

export const createClass =
  ({ name, students }) =>
  async (dispatch) => {
    dispatch({ type: CREATE_CLASS });
    try {
      const response = await gamerize.post("/schools/classes/", {
        name,
        students,
      });
      dispatch({
        type: CREATE_CLASS_SUCCESS,
        payload: response.data,
      });
      if (response) {
        SuccessDialog({
          title: "Successfully Class Created!",
        });
      }
    } catch (error) {
      ErrorDialog({
        title: error.response.data,
      });
      dispatch({
        type: CREATE_CLASS_ERROR,
        payload: {
          message: "Error creating class.",
        },
      });
    }
  };

export const removeStudents = (id, delIds) => async (dispatch) => {
  dispatch({ type: UPDATE_CLASS });
  try {
    const responseDelete = await gamerize.post(
      `/schools/classes/${id}/remove_students/`,
      {
        ids: delIds,
      }
    );
    const response = await gamerize.get(`/schools/classes/${id}/`);
    dispatch({
      type: UPDATE_CLASS_SUCCESS,
      payload: response.data,
    });
    dispatch({
      type: UPDATE_REMOVE_STUDENT_STATUS,
      payload: responseDelete.data.status_code,
    });
    if (responseDelete) {
      SuccessDialog({
        title: "Successfully student(s) Removed!",
      });
    }
  } catch (error) {
    ErrorDialog({
      title: error.response.data,
    });
    dispatch({
      type: UPDATE_CLASS_ERROR,
      payload: {
        message: "Error updating remove Student.",
      },
    });
  }
};

export const editClass =
  (id, { name, ids }) =>
  async (dispatch) => {
    dispatch({ type: UPDATE_CLASS });
    try {
      const responseClass = await gamerize.post(
        `/schools/classes/${id}/add_students/`,
        {
          ids,
        }
      );
      const response = await gamerize.get(`/schools/classes/${id}/`);
      dispatch({
        type: UPDATE_CLASS_SUCCESS,
        payload: response.data,
      });
      dispatch({
        type: UPDATE_EDIT_CLASS_STATUS,
        payload: responseClass.data.status_code,
      });
      if (responseClass) {
        SuccessDialog({
          title: "Successfully Student(s) added!",
        });
      }
    } catch (error) {
      ErrorDialog({
        title: error.response.data,
      });
      dispatch({
        type: UPDATE_CLASS_ERROR,
        payload: {
          message: "Error updating class.",
        },
      });
    }
  };

export const fetchClass = (id) => async (dispatch) => {
  dispatch({ type: FETCH_CLASS });
  try {
    const response = await gamerize.get(`/schools/classes/${id}/`);
    dispatch({
      type: FETCH_CLASS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_CLASS_ERROR,
      payload: {
        message: "Error fetching class.",
      },
    });
  }
};

export const deleteClass = (id) => async (dispatch) => {
  dispatch({ type: DELETE_CLASS });
  try {
    const response = await gamerize.delete(`/schools/classes/${id}/`);
    dispatch({
      type: DELETE_CLASS_SUCCESS,
      payload: id,
    });
    if (response) {
      SuccessDialog({
        title: "Successfully Deleted Class!",
      });
    }
  } catch (error) {
    ErrorDialog({
      title: error.response.data.detail,
    });
    dispatch({
      type: DELETE_CLASS_ERROR,
      payload: {
        message: "Error deleting class.",
      },
    });
  }
};

export const assignAdventure =
  (classID, privateAdventureIds, bundleAdventureIds, aim, startDate, endDate) =>
  async (dispatch) => {
    dispatch({ type: ASSIGN_CLASS_ADVENTURE });
    let advResponse;
    let convertedStartDate = null
    let convertedEndDate = null

    if(startDate){
      convertedStartDate = dayjs.utc(startDate).format(start_date_end_date_format);
    }
    if(endDate){
       convertedEndDate = dayjs.utc(endDate).format(start_date_end_date_format);
    }
   
    try {
        advResponse = await gamerize.post(
          `/schools/classes/${classID}/add_adventures/`,
          aim
            ? { ids: privateAdventureIds, learning_aim: aim, start_date: convertedStartDate, end_date: convertedEndDate  }
            : { ids: privateAdventureIds, start_date:convertedStartDate, end_date:convertedEndDate }
        );
      const response = await gamerize.get(`/schools/classes/${classID}/`);
      if (advResponse) {
        SuccessDialog({
          title: "Adventures Assigned",
        });
      }
      dispatch({
        type: ASSIGN_CLASS_ADVENTURE_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      ErrorDialog({
        title: error.response.data,
      });
      dispatch({
        type: ASSIGN_CLASS_ADVENTURE_ERROR,
        payload: {
          message: "Error assigning adventure.",
        },
      });
    }
  };

export const assignAdventureToMultipleClasses =
  (classIDs, adventureID, aim, startDate, endDate) => async (dispatch) => {
    dispatch({ type: ASSIGN_MULTIPLE_CLASSES_ADVENTURE });
    let response;
    let convertedStartDate = null
    let convertedEndDate = null
    if(startDate){
      convertedStartDate = dayjs.utc(startDate).format(start_date_end_date_format);
    }
    if(endDate){
       convertedEndDate = dayjs.utc(endDate).format(start_date_end_date_format);
    }
    try {
      response = await gamerize.post(
        `/adventures/adventures/${adventureID}/add_to_classes/`,
        aim ? { ids: classIDs, learning_aim: aim, start_date:convertedStartDate, end_date:convertedEndDate  } : { ids: classIDs, start_date:convertedStartDate, end_date:convertedEndDate}
      );
      const classesResponse = await gamerize.get("/schools/classes/?limit=100");
      if (response) {
        SuccessDialog({
          title: "Adventure Assigned!",
        });
      }
      dispatch({
        type: ASSIGN_MULTIPLE_CLASSES_ADVENTURE_SUCCESS,
        payload: classesResponse.data,
      });
    } catch (error) {
      ErrorDialog({
        title: error.response.data,
      });
      dispatch({
        type: ASSIGN_MULTIPLE_CLASSES_ADVENTURE_ERROR,
        payload: {
          message: "Error assigning adventure.",
        },
      });
    }
  };

export const unassignAdventure = (classID, adventureID) => async (dispatch) => {
  dispatch({ type: UNASSIGN_CLASS_ADVENTURE });
  try {
    const res = await gamerize.post(
      `/schools/classes/${classID}/remove_adventures/`,
      {
        ids: adventureID,
      }
    );
    const response = await gamerize.get(`/schools/classes/${classID}/`);
    if (res) {
      SuccessDialog({
        title: "Successfully Deleted!",
      });
    }
    dispatch({
      type: UNASSIGN_CLASS_ADVENTURE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    ErrorDialog({
      title: error.response.data,
    });
    dispatch({
      type: UNASSIGN_CLASS_ADVENTURE_ERROR,
      payload: {
        message: "Error unassigning adventure.",
      },
    });
  }
};

export const assignTeacher = (classId, teacherId) => async (dispatch) => {
  dispatch({ type: ASSIGN_CLASS_TEACHER });
  try {
    const response = await gamerize.post(
      `/schools/classes/${classId}/assign_teacher/`,
      { id: teacherId }
    );
    dispatch({
      type: ASSIGN_CLASS_TEACHER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_CLASS_ERROR,
      payload: {
        message: "Error assigning teacher.",
      },
    });
  }
};

export const unassignTeacher = (classId, teacherId) => async (dispatch) => {
  dispatch({ type: UNASSIGN_CLASS_TEACHER });
  try {
    const response = await gamerize.post(
      `/schools/classes/${classId}/unassign_teacher/`,
      { id: teacherId }
    );
    dispatch({
      type: UNASSIGN_CLASS_TEACHER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: UNASSIGN_CLASS_TEACHER_ERROR,
      payload: {
        message: "Error assigning teacher.",
      },
    });
  }
};

export const assignTeacherMultipleClasses =
  (classIds, teacherId) => async (dispatch) => {
    dispatch({ type: ASSIGN_MULTIPLE_CLASSES_TEACHER });
    try {
      await gamerize.post(`/users/owners/teachers/${teacherId}/add_classes/`, {
        ids: classIds,
      });
      const response = await gamerize.get("/schools/classes/?limit=100");
      dispatch({
        type: ASSIGN_MULTIPLE_CLASSES_TEACHER_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: ASSIGN_MULTIPLE_CLASSES_TEACHER_ERROR,
        payload: {
          message: "Error assigning teacher.",
        },
      });
    }
  };
