import { Link } from "react-router-dom";
import "./Adventures.scss";

const Adventures = () => {
  return (
    <div className="adventures-page fl-1 p-24">
      <div className="grid-fill">
        <Link to="/adventures/create">
          <div className="card card-nav clickable flex-column flex-center font-black">
            <h2>Create Adventure</h2>
          </div>
        </Link>
        <Link to="/classes/assign-adventure">
          <div className="card card-nav clickable flex-column flex-center font-black">
            <h2>Assign Adventure</h2>
          </div>
        </Link>
        <Link to="/adventures/list">
          <div className="card card-nav clickable flex-column flex-center font-black">
            <h2>Adventures List</h2>
          </div>
        </Link>
        <Link to="/adventures/bundles">
          <div className="card card-nav clickable flex-column flex-center font-black">
            <h2>Bundles</h2>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default Adventures;
