import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchTeachers, removeTeacher } from "../../actions/teachers";
import TabError from "../../components/TabError/TabError";
import { stringMatch } from "../../utils";
import "./MyTeachers.scss";
import {TailSpin as Loader} from "react-loader-spinner";
import { Table as AntTable, Input } from "antd";
import {
  ConfirmationDialog,
  ErrorDialog,
} from "../../components/ConfirmationDialog";

const selectTeachers = ({ teachers }) => teachers.data;
const selectTeachersPagination = ({ teachers }) => teachers.pagination;
const selectTeachersLoading = ({ teachers }) => teachers.loading;
const selectTeachersError = ({ teachers }) => teachers.error;
const { Search } = Input;

const MyTeachers = () => {
  const dispatch = useDispatch();

  const fetchedTeachers = useSelector(selectTeachers);
  const teachersPagination = useSelector(selectTeachersPagination);
  const teachersLoading = useSelector(selectTeachersLoading);
  const teachersError = useSelector(selectTeachersError);

  const [teachers, setTeachers] = useState([]);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const offset = (page - 1) * pageSize;

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "First Name",
      dataIndex: "first_name",
      key: "first_name",
    },

    {
      title: "Last Name",
      dataIndex: "last_name",
      key: "last_name",
    },
    {
      title: "Action",
      key: "action",
      width: 160,
      fixed: "right",
      render: (value) => (
        <span
          className="text-button mr-10"
          onClick={() => deleteHandler(value)}
        >
          Delete
        </span>
      ),
    },
  ];

  useEffect(() => {
    dispatch(fetchTeachers(pageSize, offset));
  }, [dispatch, offset, pageSize]);

  useEffect(() => {
    setTeachers(Object.values(fetchedTeachers));
  }, [fetchedTeachers]);

  useEffect(() => {
    fetchTeacher()
  }, [fetchedTeachers]);

  const handlePagination = () => {
    return {
      current: page,
      pageSize: pageSize,
      total: teachersPagination.count,
      onChange: (teacherPage, teacherPageSize) => {
        setPage(teacherPage);
        setPageSize(teacherPageSize);
      },
    };
  };

  const fetchTeacher = ()=>{
    setTeachers(
      Object.values(fetchedTeachers).filter(({ first_name }) =>
        stringMatch(first_name, search)
      )
    );
  }

  const deleteHandler = (value) => {
    ConfirmationDialog({
      title: "Are You Sure?",
      onConfirm: () => {
        if (!window.navigator.onLine) {
          ErrorDialog({
            title: "You're offline right now. Check your connection.",
          });
        } else {
          dispatch(removeTeacher(value.id));
        }
      },
    });
  };

  const renderTeachersList = () => (
    <AntTable
      title={null}
      pagination={handlePagination()}
      columns={columns}
      dataSource={teachers}
      showHeader
      size="default"
      bordered={false}
    />
  );

  const onSearch = value => {
    fetchTeacher()
    setPage(1);
  };

  return (
    <div className="my-teachers-page flex-column fl-1 pt-24">
      {teachersLoading ? (
        <div className="flex flex-center fl-1">
          <Loader type="Puff" color="#354AC9" height={50} width={50} />
        </div>
      ) : teachersError ? (
        <TabError
          onClick={() => dispatch(fetchTeachers())}
          message={teachersError.message}
          label="Reload Teachers"
        />
      ) : (
        <Fragment>
          <div className="flex align-center justify-between mb-16 mh-24">
            <h2>My Teachers</h2>
          </div>
          <div className="mb-16 mh-24">
            <Search
                placeholder="Search Teachers"
                enterButton="Search"
                size="large"
                onSearch={onSearch}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
          </div>
          <div id="scrollableContainer" className="scroll-vertical fl-1">
            {renderTeachersList()}
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default MyTeachers;
