import StudentAvatar from "../../../images/student-avatar.jpg";
import IconButton from "../../../components/Button/IconButton";
import { Popover } from "antd";
import "./StudentGridItem.scss";

const displayUserName = (student) => (
  <div style={{ width: "fit-content" }}>
    <h3>{student.username}</h3>
  </div>
);

const StudentGridItem = ({ student, onRemove }) => (
  <div key={student.id} className="students-grid-item">
    <img src={student.avatar || StudentAvatar} alt="avatar" className="mb-16" />
    <h3 className="bold font-primary">
      {student.first_name + " " + student.last_name}
    </h3>
    {student.username?.length > 12 ? (
      <Popover content={displayUserName(student)}>
        <h3 className="bold font-secondary">
          {student.username.split("", 12)}...
        </h3>
      </Popover>
    ) : (
      <h3 className="bold font-secondary">{student.username}</h3>
    )}
    <IconButton onClick={onRemove} icon="close" danger className="circle" />
  </div>
);

export default StudentGridItem;
