import { useState, useEffect } from "react";
import Button from "../../components/Button/Button";
import * as XLSX from "xlsx";
import { Table as AntTable } from "antd";
import { useSelector } from "react-redux";
import gamerize from "../../apis/gamerize";
import { SuccessDialog } from "../../components/ConfirmationDialog";
import {TailSpin as Loader} from "react-loader-spinner";

const authSelector = ({ auth }) => auth;

const UploadData = () => {
  const [loading, setloading] = useState(false);
  const [data, setData] = useState(null);
  const [statusSuccess, setStatusSuccess] = useState([]);
  const [statusError, setStatusError] = useState([]);
  const [showUploadButton, setShowUploadButton] = useState(false);

  const user = useSelector(authSelector);

  const columns = [
    {
      title: "User Name",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "First Name",
      dataIndex: "first_name",
      key: "first_name",
    },

    {
      title: "Last Name",
      dataIndex: "last_name",
      key: "last_name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "School Code",
      dataIndex: "school_code",
      key: "school_code",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
  ];

  const convertToJson = (headers, fileData) => {
    const rows = [];
    fileData.forEach((row) => {
      let rowData = {};
      row.forEach((element, index) => {
        rowData[headers[index]] = element;
      });
      rows.push(rowData);
    });
    return rows;
  };

  const renderStudentsList = () => (
    <AntTable
      title={null}
      columns={columns}
      dataSource={data}
      showHeader
      size="default"
      bordered={false}
    />
  );

  const imageSelectedHandler = async (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const bstr = e.target.result;
      const workBook = XLSX.read(bstr, { type: "binary" });

      const workSheetName = workBook.SheetNames[0];
      const workSheet = workBook.Sheets[workSheetName];

      const fileData = XLSX.utils.sheet_to_json(workSheet, { header: 1 });
      const header = fileData[0];
      fileData.splice(0, 1);
      setData(
        convertToJson(header, fileData).map((stdData) => ({
          ...stdData,
          user_type: "student",
          school_code: user?.school.code,
        }))
      );
    };
    reader.readAsBinaryString(file);
  };

  useEffect(() => {
    dataUploadHandler();
  }, [data]);

  useEffect(() => {
    if (data !== null) {
      setloading(true);
      const delayTime = 2000;
      const delay = setTimeout(() => {
        setloading(false);
        SuccessDialog({
          title: `Records uploaded :  ${statusSuccess.length}.        Records not uploaded: ${statusError.length}`,
          onConfirm: () => {
            setShowUploadButton(true);
          },
          yesText: "Got it",
        });
      }, delayTime);
      return () => clearTimeout(delay);
    }
  }, [statusSuccess, statusError]);

  const dataUploadHandler = async () => {
    setloading(true);
    data?.map(async function (item) {
      try {
        const res = await gamerize.post(`/users/register/`, item);
        setStatusSuccess((prevStats) => [...prevStats, res.data.username]);
        item.status = res.statusText;
      } catch (error) {
        const errorData = error.response.data;
        let err;
        if (errorData.school_code) {
          err = errorData.data.school_code;
        } else if (errorData.non_field_errors) {
          err = errorData.non_field_errors;
        } else if (errorData.email) {
          err = errorData.email;
        } else {
          err = "Error occured";
        }
        setStatusError((prevStats) => [...prevStats, err]);
        item.status = err;
      }
    });
    setloading(false);
  };
  return (
    <div className="profile-options-page flex-column fl-1 p-24">
      <div className="flex justify-between align-center mb-24">
        <h2>Upload Bulk Data</h2>
        {showUploadButton ? (
          <Button
            label="Upload New File"
            primary
            onClick={() => window.location.reload()}
            wide
          />
        ) : (
          <></>
        )}
      </div>

      {!showUploadButton ? (
        <input
          className="mb-32"
          type="file"
          name="myImage"
          onChange={imageSelectedHandler}
        />
      ) : (
        <></>
      )}
      {loading ? (
        <div className="flex-center-self">
          <Loader type="Puff" color="#354AC9" height={50} width={50} />
        </div>
      ) : (
        <></>
      )}
      {renderStudentsList()}
    </div>
  );
};

export default UploadData;
