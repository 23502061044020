import gamerize from "../../apis/gamerize";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Button from "../../components/Button/Button";
import getDiscount from "../../helper/getDiscount";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import getSubscriptionDetail from "../../helper/getSubscriptionDetail";
import { Popover } from "antd";
import {TailSpin as Loader} from "react-loader-spinner";
import { ErrorDialog } from "../../components/ConfirmationDialog";
import { InfoCircleOutlined } from "@ant-design/icons";
import 'antd/dist/reset.css';
import "./CheckoutSession.scss";

const paymentType = ({ subscription }) => subscription.payment_type?.status;

const selectOptions = [
  {
    value: "stripe",
    label: "Credit/Debit Card Payment using Stripe (Recomended)",
  },
  {
    value: "bank",
    label: "Bank Transfer",
  },
];

const CheckoutSession = () => {
  const payment_type = useSelector(paymentType);

  const { packageId } = useParams();
  const [seats, setSeats] = useState("1");
  const [months, setMonths] = useState("1");
  const [discount, setDiscount] = useState(0);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [totalBankTransfer, setTotalBankTransfer] = useState(0);
  const [error, setError] = useState("");
  const [paymentOption, setPaymentOption] = useState("");
  const [subscriptionDetail, setSubscriptionDetail] = useState("");

  const filterAdventures = ({ value }) => {
    switch (value) {
      case "bank":
        setPaymentOption(value);
      case "stripe":
        setPaymentOption(value);
      default:
        setPaymentOption(value);
    }
  };

  useEffect(() => {
    const seat = parseInt(seats);
    if (seat) {
      setDiscount(getDiscount(seat, packageId));
      setTotal(discount * seat);
      setTotalBankTransfer(discount * seat * months);
    }
  }, [discount, packageId, seats, months]);

  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    const intPackageId = parseInt(packageId);
    const intSeats = parseInt(seats);
    let response = null;
    e.preventDefault();
    try {
      response = await gamerize.post(
        "/users/stripe/subscriptions/create_checkout_session/",
        { package: intPackageId, seats: intSeats }
      );
      const url = response.data.sessionId.url;
      window.location.replace(url);
    } catch (e) {
      ErrorDialog({
        title: e.response.data.error,
      });
    }
  };

  const getSubscription = async () => {
    setError("");
    try {
      setLoading(true);
      const response = await getSubscriptionDetail();
      setSubscriptionDetail(response);
      setLoading(false);
    } catch (error) {
      setError("Error Fetching Subscriptions Detail");
    }
  };

  useEffect(() => {
    getSubscription();
  }, []);

  const handleBankCheckout = async (e) => {
    e.preventDefault();
    try {
      await gamerize.post("/users/bank_transfer/requests/", {
        subscription_type: packageId,
        months: months,
        seats: seats,
      });
      navigate("/subscriptions/bank-transfers");
    } catch (error) {
      setError(
        "Error Creating Bank Checkout Session  Or You Already Have a Subscription"
      );
    }
  };

  const bankMessage = (
    <div style={{ width: "300px" }}>
      <p>
        Generate a bank transfer request and send the funds using bank transfer.
        No Credit Card required!
      </p>
    </div>
  );
  const stripeMessage = (
    <div style={{ width: "300px" }}>
      <p>
        Get subscription instantly. Automatic monthly subscriptions that you can
        upgrade/downgrade and cancel anytime easily.
      </p>
    </div>
  );

  const renderBankForm = () => (
    <>
      <p className="mt-24">
        <span className="transfer-message mr-8 mb-24">
          Generate a bank transfer request and send the funds using bank
          transfer. No Credit Card required!
        </span>
      </p>
      <h3 className="mt-24 mb-8">Enter Number of Seats : </h3>
      <input
        placeholder="Enter Seats"
        onChange={(e) => {
          let value = parseInt(e.target.value, 10);
          value = value >= 0 ? value : 0;
          setSeats(value);
        }}
        value={seats}
        type="number"
        min="1"
        className="seats-field mb-16 p-16"
      />

      <form className="mh-24 flex-column">
        {renderBankPaymentList(
          packageId,
          seats,
          discount,
          total,
          setMonths,
          months
        )}
        <Button
          label="Bank Payment Request"
          primary
          type="button"
          className="mb-16"
          onClick={handleBankCheckout}
        />
      </form>
    </>
  );
  const renderStripeForm = () => (
    <>
      <p className="mt-24">
        <span className="transfer-message mr-8 mb-24">
          Get subscription instantly. Automatic monthly subscriptions that you
          can upgrade/downgrade and cancel anytime easily.
        </span>
      </p>
      <h3 className="mt-24 mb-8">Enter Number of Seats : </h3>
      <input
        placeholder="Enter Seats"
        // onChange={(e) => setSeats(e.target.value)}
        onChange={(e) => {
          let value = parseInt(e.target.value, 10);
          value = value >= 0 ? value : 0;
          setSeats(value);
        }}
        value={seats}
        type="number"
        min="1"
        className="seats-field mb-16 p-16"
      />
      <form onSubmit={handleSubmit} className="mh-24 flex-column">
        <ul className="package-list">
          <li className="package-info">
            <Popover content={stripeMessage}>
              Payment Type: Stripe{" "}
              <InfoCircleOutlined style={{ color: "grey" }} />
            </Popover>
          </li>
          {packageId === "1" ? (
            <li className="package-info">Package: Gamerize Basic</li>
          ) : (
            <li className="package-info">Package: Gamerize Pro</li>
          )}

          <li className="package-info">Seats: {seats}</li>
          <li className="package-info">Price/Seat: ¥{discount}</li>
          <li className="package-info">Total Price: ¥{total}</li>
        </ul>
        <Button
          label="Stripe Payment Request"
          primary
          type="submit"
          className="mb-16"
        />
      </form>
    </>
  );

  const renderBankPaymentList = (
    packageId,
    seats,
    discount,
    total,
    setMonths,
    months
  ) => (
    <ul className="package-list">
      <li className="package-info">
        <Popover content={bankMessage}>
          Payment Type : Bank <InfoCircleOutlined style={{ color: "grey" }} />
        </Popover>
      </li>
      {packageId === "1" ? (
        <li className="package-info">Package : Gamerize Basic</li>
      ) : (
        <li className="package-info">Package : Gamerize Pro</li>
      )}
      <li className="package-info flex justify-center">
        <p>Months : </p>
        <input
          className="ml-16"
          placeholder="Enter Months"
          onChange={(e) => {
            let value = parseInt(e.target.value, 10);
            value = value >= 0 && value < 13 ? value : 1;
            setMonths(value);
          }}
          value={months}
          type="number"
          min="1"
          max="12"
        />
      </li>
      <li className="package-info">Seats : {seats}</li>
      <li className="package-info">Price/Seat : ¥{discount}</li>
      <li className="package-info">Tax (10%) : ¥{totalBankTransfer * 0.1}</li>
      <li className="package-info">
        Total Price : ¥{totalBankTransfer + totalBankTransfer * 0.1}
      </li>
    </ul>
  );

  return (
    <div className="checkout-session-page fl-1 flex-column p-24">
      <h1 className="mh-24 mt-24 mb-24">Create Subscription</h1>
      <p>
        We offer discounts based on number of seats. Here is a helpful chart
        with the discounts.
      </p>
      <table className="mb-24 table">
        <tr className="table-row">
          <th className="table-header">Number of Seats</th>
          <th className="table-header">Discounted Price/Unit</th>
        </tr>
        <tr className="table-row">
          <td>1-50</td>
          {packageId === "1" ? <td>¥800</td> : <td>¥1000</td>}
        </tr>
        <tr className="table-row">
          <td>51-99</td>
          {packageId === "1" ? <td>¥720</td> : <td>¥900</td>}
        </tr>
        <tr className="table-row">
          <td>100-499</td>
          {packageId === "1" ? <td>¥640</td> : <td>¥800</td>}
        </tr>
        <tr className="table-row">
          <td>500-</td>
          {packageId === "1" ? <td>¥560</td> : <td>¥700</td>}
        </tr>
      </table>

      {error !== "" &&
        ErrorDialog({
          title: error,
        })}

      {payment_type === "bank" &&
        subscriptionDetail.payment_type !== 1 &&
        renderBankForm()}
      {subscriptionDetail.payment_type === 1 && renderStripeForm()}

      {/*<h3 className="mb-8 ">Select Payment Method :</h3>*/}
      {payment_type !== "bank" && subscriptionDetail.payment_type !== 1 && (
        <>
          <h3 className="mb-8 ">Select Payment Method :</h3>
          <Select
            options={selectOptions}
            onChange={filterAdventures}
            defaultValue={"Select Payment type"}
            className="bundle-list-filter"
          />
        </>
      )}
      {loading && (
        <div className="ml-16">
          <Loader type="Puff" color="#354AC9" height={54} width={54} />
        </div>
      )}
      {paymentOption === "stripe" ? (
        renderStripeForm()
      ) : paymentOption === "bank" ? (
        renderBankForm()
      ) : (
        <></>
      )}
    </div>
  );
};

export default CheckoutSession;
