import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import SideBar from "../components/SideBar/SideBar";
import AssignTeacher from "../pages/AssignTeacher/AssignTeacher";
import Classes from "../pages/Classes/Classes";
import EditClass from "../pages/EditClass/EditClass";
import AssignAdventures from "../pages/AssignAdventures/AssignAdventures";
import AssignClasses from "../pages/AssignClasses/AssignClasses";

import MyClasses from "../pages/MyClasses/MyClasses";
import MyStudents from "../pages/MyStudents/MyStudents";
import NewClass from "../pages/NewClass/NewClass";
import UnassignTeacher from "../pages/UnassignTeacher/UnassignTeacher";
import ViewClass from "../pages/ViewClass/ViewClass";
import ViewClassAdventures from "../pages/ViewClassAdventures/ViewClassAdventures";
import ViewStudent from "../pages/ViewStudent/ViewStudent";
import AnalyticsStudent from "../pages/AnalyticsStudent/AnalyticsStudent";
import AssignStudent from "../pages/AssignStudentAdventure/AssignStudent";
const selectUserType = ({ auth }) => auth.user_type;

const ClassesRouter = () => {

  const userType = useSelector(selectUserType);

  const ownerPages = [
    { title: "Classes", link: `/classes/dashboard` },
    { title: "New Class", link: `/classes/new` },
    { title: "My Classes", link: `/classes/my-classes` },
    { title: "Assign Teacher", link: `/classes/assign-teacher` },
    { title: "Unassign Teacher", link: `/classes/unassign-teacher` },
    { title: "My Students", link: `/classes/my-students` },
    { title: "Assign Adventure", link: `/classes/assign-adventure` },
    { title: "Class Adventures Assignee", link: `/classes/assign-classes` },
    { title: "Student Adventures", link: `/classes/students-adventures` },

  ];

  const teacherPages = [
    { title: "Classes", link: `/classes/dashboard` },
    { title: "New Class", link: `/classes/new` },
    { title: "My Classes", link: `/classes/my-classes` },
    { title: "My Students", link: `/classes/my-students` },
    { title: "Assign Adventure", link: `/classes/assign-adventure` },
    { title: "Class Adventures Assignee", link: `/classes/assign-classes` },
    { title: "My Students1", link: `/classes/students-adventure` },
  ];

  const getPages = (userType) => {
    switch (userType) {
      case "owner":
        return ownerPages;
      case "teacher":
        return teacherPages;
      default:
        return teacherPages;
    }
  };

  return (
    <div className="flex page">
      <SideBar pages={getPages(userType)} />
      <Routes>
        <Route path={`dashboard`} element={<Classes/>} />
        <Route path={`new`} element={<NewClass/>} />
        <Route path={`my-classes`} element={<MyClasses/>} />
        <Route
          path={`my-classes/class/view/:id`}
          element={<ViewClass/>}
        />
        <Route
          path={`my-classes/class/view/:id/adventures`}
          element={<ViewClassAdventures/>}
        />
        <Route
          path={`my-classes/class/edit/:id`}
          element={<EditClass/>}
        />
        <Route path={`my-students`} element={<MyStudents/>} />
        <Route path={`students-adventure`} element={<AssignStudent/> } />
        <Route
          path={`my-classes/student/:id`}
          element={<ViewStudent/>}
        />
        <Route
          path={`my-students/student/:id`}
          element={<AnalyticsStudent/>}
        />
        {<Route path={`assign-adventure`} element={<AssignAdventures/>} />}
        {<Route path={`assign-classes`} element={<AssignClasses/>} />}

        {userType === "owner" && (
          <Route
            path={`assign-teacher`}
            element={<AssignTeacher/>}
          />
        )}
        {userType === "owner" && (
          <Route
            path={`unassign-teacher`}
            element={<UnassignTeacher/>}
          />
        )}
      </Routes>
    </div>
  );
};

export default ClassesRouter;
