import axios from "axios";
import { config } from "../constants";

const baseURL = config.API_URL;

const getAccessToken = () => localStorage.getItem("access_token")
  ? localStorage.getItem("access_token")
  : "";

export const gamerizeUnauthenticated = axios.create({
  baseURL,
});

const gamerize = axios.create({
  baseURL,
  // headers: {
  //   Authorization: "JWT " + getAccessToken(),
  // },
})

gamerize.interceptors.request.use(
  async config => {
    config.headers = {
      Authorization: "JWT " + getAccessToken()
    }
    return config
  },
  error => {
    Promise.reject(error)
  }
)

gamerize.interceptors.response.use((response) => response, async (error) => {
  const originalRequest = error.config;
  if (error.response.status === 403 && !originalRequest._retry) {
    originalRequest._retry = true;
    const access_token = getAccessToken();
    axios.defaults.headers.common['Authorization'] = 'JWT ' + access_token;
    return gamerize(originalRequest);
  }
  return Promise.reject(error);
});

export default gamerize

// export const AuthenticatedRequest = (method, url, data) => {
//   const idToken = localStorage.getItem("access_token");

//   if (idToken) {
//     const defaultOptions = {
//       method: method,
//       url: baseURL + url,
//       data: data,
//       headers: {
//         Authorization: "JWT " + idToken,
//       },
//     };

//     // Create Instance
//     const axiosInstance = axios.create();

//     // Last step: handle request error general case
//     axiosInstance.interceptors.response.use(
//       (response) => response,
//       (error) => {
//         // Error
//         const { status } = error.response;

//         if (status === 401) {
//           // Unauthorized request: maybe access token has expired!
//           return refreshtoken().then((_) => {
//             error.config.headers["Authorization"] =
//               "JWT " + localStorage.getItem("access_token");;
//             return axiosInstance.request(error.config);
//           });
//         } else {
//           return Promise.reject(error);
//         }
//       }
//     );

//     return axiosInstance.request(defaultOptions);
//   }
//   return Promise.reject();
// };
