import { Route, Routes } from "react-router-dom";
import LoginStats from "../pages/AnalyticsLoginStats/AnalyticsLoginStats";
import GameLoginStats from "../pages/AnalyticsGameLoginStats/AnalyticsGameLoginStats";
import CheckInStats from "../pages/AnalyticsCheckInStats/AnalyticsCheckInStats";
import SideBarAnalytics from "../components/SideBarAnalytics/SideBarAnalytics";
import AnalyticsStudent from "../pages/AnalyticsStudent/AnalyticsStudent";

const AnalyticsStatsRouter = () => {
  const PAGES = [
    { title: "App Login Stats", link: `/analytics/analytics-stats/login-stats` },
    { title: "Game Login Stats", link: `/analytics/analytics-stats/game-login-stats` },
    { title: "App Checkin Stats", link: `/analytics/analytics-stats/checkin-stats` },
  ];

  return (
    <div className="flex page">
      <SideBarAnalytics pages={PAGES} />
      <Routes>
        <Route path={`login-stats`} element={<LoginStats/>} />
        <Route
          path={`game-login-stats`}
         element={<GameLoginStats/>}
        />
        <Route path={`checkin-stats`} element={<CheckInStats/>} />
        <Route
          path={`student/:id`}
          element={<AnalyticsStudent/>}
        />
      </Routes>
    </div>
  );
};

export default AnalyticsStatsRouter;
