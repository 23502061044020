import { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import NavMenu from "../NavMenu/NavMenu";
import "./NavBar.scss";

const selectSchoolName = ({ auth }) => auth.school?.name;

const NavBar = ({ name }) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const schoolName = useSelector(selectSchoolName);
  const isBattleLive = useSelector((state) => state.classBattle.isBattleLive); // Assuming the game state is managed here

  const handleClick = (e) => {
    if (isBattleLive) {
      e.preventDefault();
      alert("You cannot navigate away during the game!");
    }
  };

  return (
    <Fragment>
      <header className="nav-bar">
        <Link to="/" onClick={handleClick}>Gamerize</Link>
        <h3 className="school-color">{schoolName || "School"}</h3>
        <div
          className="icon icon-24 icon-menu"
          onClick={() => setMenuOpen(true)}
        />
      </header>
      <NavMenu opened={menuOpen} close={() => setMenuOpen(false)} />
    </Fragment>
  );
};

export default NavBar;
