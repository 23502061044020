import _ from "lodash";
import { useState } from "react";
import Button from "../../../components/Button/Button";
import { Table as AntTable } from "antd";

const columns = [
  {
    title: "Class Name",
    dataIndex: "name",
    key: "name",
    width: 580,
  },
  {
    title: "Number of Students",
    dataIndex: "students",
    key: "students",
    render: (value) => value.length,
    width: 150,
  },
];

const ChooseClass = ({ onNext, selectedClass, classes, classesPagination }) => {
  const [selected, setSelected] = useState(selectedClass || null);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(15);

  const handlePagination = () => {
    return {
      current: page,
      pageSize: pageSize,
      total: classesPagination.count,
      onChange: (ClassesPage, ClassesPageSize) => {
        setPage(ClassesPage);
        setPageSize(ClassesPageSize);
      },
    };
  };

  const renderClassList = () => (
    <AntTable
      title={null}
      pagination={handlePagination()}
      columns={columns}
      dataSource={classes.map((obj) => ({ ...obj, key: obj.id }))}
      showHeader
      rowSelection={{
        selectedRowKeys: selected ? Object.keys(selected).map((a) => +a) : [],
        onChange: (selectedRowKeys, selectedRows) => {
          const rows = {};
          selectedRows.forEach((each) => {
            rows[each.id] = each;
          });
          setSelected(rows);
        },
      }}
      size="default"
      bordered={false}
    />
  );

  return (
    <div className="go-choose-class flex-column fl-1 overflow-hidden">
      <h3 className="mb-16 mh-24">Step 1: Choose Class</h3>
      <div id="scrollableContainer" className="list-container fl-1">
        {renderClassList()}
      </div>
      <Button
        label="Next"
        primary
        onClick={() => onNext(selected)}
        className="mb-24 mh-24"
        wide
        disabled={_.isEmpty(selected)}
      />
    </div>
  );
};

export default ChooseClass;
