import Button from "../../components/Button/Button";
import { Link } from "react-router-dom";
import "./PricingTable.scss";

const PricingTable = () => {
  return (
    <div className="pricing-table-page fl-1 p-24">
      <h1 className="mh-24 mt-24 mb-24">Gamerize Subscription Plans</h1>
      <p>Gamerzie Subscription allows your school to onboard students on the Gamerize App. We offer two types of subscriptions. We offer discounts based on number of seats. Please select a plan to see discounts.</p>
      <div className="mh-24 pt-24 columns">
        <ul className="price-card">
          <li className="header text-center p-20">Basic</li>
          <li className="price p-20">
            Starting from ¥800 <span className="plan">seat/month</span>
          </li>
          <li className="flex flex-center text-center p-20">
            <div className="mr-16 icon icon-24 icon-close-b" />
            <p>Voice Recognition</p>
          </li>
          <li className="flex flex-center text-center p-20">
            <div className="mr-16 icon icon-24 icon-check" />{" "}
            <p>All Adventures Unlocked</p>
          </li>
          <li className="flex flex-center text-center p-20">
            <div className="mr-16 icon icon-24 icon-check" />{" "}
            <p>All Bundles Unlocked</p>
          </li>
          <li className="grey text-center p-20">
            <Link to="/subscriptions/create-checkout-session/1">
              <Button label="Select" primary className="mb-16" />
            </Link>
          </li>
        </ul>
      </div>
      <div className="columns pt-24">
        <ul className="price-card">
          <li className="header text-center p-20">Pro</li>
          <li className="price p-20">
          Starting from ¥1000 <span className="plan">seat/month</span>
          </li>
          <li className="flex flex-center text-center p-20">
            <div className="mr-16 icon icon-24 icon-check" /> <p>Voice Recognition</p>
          </li>
          <li className="flex flex-center text-center p-20">
            <div className="mr-16 icon icon-24 icon-check" />{" "}
            <p>All Adventures Unlocked</p>
          </li>
          <li className="flex flex-center text-center p-20">
            <div className="mr-16 icon icon-24 icon-check" />{" "}
            <p>All Bundles Unlocked </p>
          </li>
          <li className="grey p-20">
            <Link to="/subscriptions/create-checkout-session/2">
              <Button label="Select" primary className="mb-16" />
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default PricingTable;
