import { Link } from "react-router-dom";
import Button from "../../../components/Button/Button";

const Success = ({ title, startOver }) => {
  return (
    <div className="flex-column p-24">
      <h1 className="mb-32">{title}</h1>
      <h3 className="mb-32">
        You can preview your adventure on Adventures Tab
      </h3>
      <div className="flex">
        <Button
          primary
          label="New Adventure"
          wide
          className="mr-24"
          onClick={startOver}
        />
        <Link to="/classes/assign-adventure">
          <Button primary label="Assign Adventure" wide />
        </Link>
      </div>
    </div>
  );
};

export default Success;
