import gamerize from "../apis/gamerize";
import {
  SuccessDialog,
  ErrorDialog,
  ConfirmationDialog,
} from "../components/ConfirmationDialog";
const setAdventureClone = async (id, adventureName, learningAim = 1) => {
  if (!window.navigator.onLine) {
    ErrorDialog({
      title: "You're offline right now. Check your connection.",
    });
  } else {
    ConfirmationDialog({
      title: "Are You Sure?",
      onConfirm: async () => {
        try {
          const response = await gamerize.post("adventures/duplicate/", {
            id: id,
            name: adventureName,
            learning_aim: learningAim,
          });
          if (response) {
            SuccessDialog({
              title: "Adventure has been Cloned, Successfully!",
            });
          }
          return response.data;
        } catch (error) {
          ErrorDialog({
            title: "Error, Adventure has not been Cloned!",
          });
        }
      },
    });
  }
};
export default setAdventureClone;
