import gamerize from "../apis/gamerize";
const isClassBattleEnabled = async () => {
  try {
    const response = await gamerize.get("/base-config/");
    return response.data.class_battle_activated;
  } catch (error) {
    throw new Error("Error Enabling Class Battle Feature");
  }
};
export default isClassBattleEnabled;
