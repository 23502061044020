import gamerize from "../apis/gamerize";
const isSubscriptionEnabled = async () => {
  try {
    const response = await gamerize.get("/base-config/");
    return response.data.subscriptions_enabled;
  } catch (error) {
    throw new Error("Error Enabling Subscriptions");
  }
};
export default isSubscriptionEnabled;
