import { useEffect, useState } from "react";
import Button from "../../../components/Button/Button";
import {TailSpin as Loader} from "react-loader-spinner";
import { useSelector } from "react-redux";
import { gamerizeUnauthenticated } from "../../../apis/gamerize";

const CodeForm = ({ onSubmit }) => {

  const apiErrors = useSelector(({ apiErrors }) => apiErrors)

  const [code, setCode] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false)

  const checkSchoolCode = async () => {
    try {
      await gamerizeUnauthenticated.post('/schools/schools/verify_school_code/', {
        school_code: code
      })
      return true
    } catch (error) {
      return false
    }
  }


  const onFormSubmit = async (e) => {
    e.preventDefault();
    setError("")
    setLoading(true)
    if (code) {
      const validCode = await checkSchoolCode(code)
      if (validCode) {
        onSubmit(code);
      } else {
        setLoading(false)
        setError('The school code is invalid.')
      }
    } else {
      onSubmit()
    }

  };

  useEffect(() => {
    if (apiErrors.message) {
      setLoading(false)
      setError('Error Signing Up!')
    }
  }, [apiErrors])

  return (
    <form onSubmit={onFormSubmit} className="flex-column">
      <h1 className="mb-32">Code (optional)</h1>
      <input
        placeholder="School Code"
        value={code}
        onChange={(e) => setCode(e.target.value)}
        type="text"
        className="mb-16"
      />
      {loading ? <div style={{ alignSelf: "center" }}>
        <Loader
          type="Puff"
          color="#354AC9"
          height={50}
          width={50}
        />
      </div> : <Button label="Sign Up" primary type="submit" className="mb-16" />}
      {error && <p className="font-danger text-center bold">{error}</p>}
    </form>
  );
};

export default CodeForm;
