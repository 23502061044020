import { useEffect, useState } from "react";
import "./AdventureForm.scss";
import AvailableGames from "./Tabs/AvailableGames";
import DataEntry from "./Tabs/DataEntry";
import NameAim from "./Tabs/NameAim";
import Success from "./Tabs/Success";

const gamesList = [
  {
    id: "1",
    name: "Game A",
  },
  {
    id: "2",
    name: "Game B",
  },
  {
    id: "3",
    name: "Game C",
  },
  {
    id: "4",
    name: "Game D",
  },
];

const AdventureForm = ({ className, adventure = {}, onSubmit, status, edit }) => {
  const [tab, setTab] = useState(0);
  const [submitted, setSubmitted] = useState(false);
  const [name, setName] = useState(adventure.name || "");
  const [selectedAim, setSelectedAim] = useState(adventure.aim || "");
  const [selectedWords, setSelectedWords] = useState(adventure.words || "");

  const resetForm = () => {
    setSubmitted(false);
    setName("");
    setSelectedAim("");
    setSelectedWords("");
    setTab(0);
  };

  useEffect(() => {
    if (status === 'succeeded' && submitted) setTab(3)
  }, [status, submitted])

  const renderTab = (tab) => {
    switch (tab) {
      case 0:
        return (
          <NameAim
            values={{ name, aim: selectedAim }}
            onNext={({ name, aim }) => {
              setName(name);
              setSelectedAim(aim);
              setTab(1);
            }}
          />
        );
      case 1:
        return (
          <DataEntry
            words={selectedWords}
            onBack={(words) => {
              setSelectedWords(words);
              setTab(0);
            }}
            onNext={(words) => {
              setSelectedWords(words);
              setTab(2);
            }}
          />
        );
      case 2:
        return (
          <AvailableGames
            type="Available Games"
            games={gamesList}
            edit={edit}
            onBack={() => {
              setTab(1);
            }}
            loading={status === 'loading'}
            onNext={() => {
              setSubmitted(true)
              onSubmit({
                name,
                learning_aim: selectedAim.aim,
                language_from: 2,
                language_to: 1,
                words: selectedWords.map(({ id }) => id),
                is_public: false,
              });
            }}
          />
        );
      case 3:
        return (
          <Success
            startOver={resetForm}
            title={edit ? "Adventure Edited" : "Adventure Created"}
          />
        );
      default:
        return null;
    }
  };

  return <div className={className}>{renderTab(tab)}</div>;
};

export default AdventureForm;
