import gamerize from "../apis/gamerize";
import {
  FETCH_BUNDLE,
  FETCH_BUNDLES,
  FETCH_BUNDLES_ERROR,
  FETCH_BUNDLES_SUCCESS,
  FETCH_BUNDLE_ERROR,
  FETCH_BUNDLE_SUCCESS,
  FETCH_NEXT_BUNDLES_SUCCESS,
} from "./types";

/*&date=${new Date().getTime()*/
export const fetchBundles =
(search='', limit = 10, offset = 0) =>
  async (dispatch) => {
    dispatch({ type: FETCH_BUNDLES });
    try {
      const response = await gamerize.get(
        `/adventures/bundles/?search=${search}&limit=${limit}&time=${new Date().getTime()}&offset=${offset}`
      );
      dispatch({
        type: FETCH_BUNDLES_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: FETCH_BUNDLES_ERROR,
        payload: {
          message: "Error fetching bundles.",
        },
      });
    }
  };

export const fetchNextBundles = () => async (dispatch, getState) => {
  const { next } = getState().bundles.pagination;
  if (!next) return;
  const query = next.split("?")[1] || "";
  try {
    const response = await gamerize.get(`/adventures/bundles/?${query}`);
    dispatch({
      type: FETCH_NEXT_BUNDLES_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_BUNDLES_ERROR,
      payload: {
        message: "Error fetching adventures.",
      },
    });
  }
};

export const fetchBundle = (id) => async (dispatch, getState) => {
  dispatch({ type: FETCH_BUNDLE });
  try {
    const response = await gamerize.get(`/adventures/bundles/${id}`);
    dispatch({
      type: FETCH_BUNDLE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_BUNDLE_ERROR,
      payload: {
        message: "Error fetching bundle.",
      },
    });
  }
};
