import {
  Document,
  Image,
  Link,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import appStoreQR from "../../images/appstore-qr.png";
import appLogo from "../../images/logo.png";
import playstoreQR from "../../images/playstore-qr.png";

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#E4E4E4",
    padding: 35,
    paddingBottom: 0,
  },
  section: {
    flex: 1,
    marginBottom: 35,
  },
  title: {
    fontSize: 24,
  },
  subTitle: {
    fontSize: 20,
  },
  qrLogo: {
    width: "50pt",
    height: "50pt",
    marginRight: 16,
  },
  appDetails: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 16,
  },
  appName: {
    fontSize: 36,
  },
  appLogo: {
    width: "50pt",
    height: "50pt",
    marginRight: 16,
  },
  letterContent: {
    flex: 1,
  },
  storeLinks: {
    flexDirection: "row",
    alignItems: "center",
  },
  instituteDetails: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    marginBottom: 16,
    marginTop: 16,
  },
  qrContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginRight: 16,
  },
  institute: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  instructions: {
    padding: 12,
  },
  step: {
    marginBottom: 8,
  },
});

const count = [1, 2];

const TeacherDocument = ({ name, code, instCode }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      {count.map((item) => (
        <View style={styles.section} key={item}>
          <View style={styles.appDetails}>
            <Image src={appLogo} style={styles.appLogo} />
            <Text style={styles.appName}>Gamerize</Text>
          </View>
          <View style={styles.letterContent}>
            <Text style={styles.subTitle}>Dear Parent/Gaurdian,</Text>
            <Text>
              Your child is getting access to Gamerize, the new way to learn.
            </Text>
            <Text>Follow the instructions below:</Text>
            <View style={styles.instructions}>
              <Text style={styles.step}>
                1: Go to{" "}
                <Link src="https://www.example.com">gamerize.com/register</Link>
              </Text>
              <Text>2: Enter the codes below:</Text>
              <View style={styles.instituteDetails}>
                <View style={styles.institute}>
                  <Text>Teacher Name:</Text>
                  <Text style={styles.title}>{name}</Text>
                </View>
                <View style={styles.institute}>
                  <Text>Teacher Code:</Text>
                  <Text style={styles.title}>{code}</Text>
                </View>
                <View style={styles.institute}>
                  <Text>Institute Code:</Text>
                  <Text style={styles.title}>{instCode}</Text>
                </View>
              </View>
              <Text style={styles.step}>3: Pick a username and password</Text>
              <Text>4: Start using Gamerize!</Text>
            </View>
          </View>
          <View style={styles.storeLinks}>
            <View style={styles.qrContainer}>
              <Image src={appStoreQR} style={styles.qrLogo} />
              <Link src="https://www.apple.com/app-store/">
                Download on Appstore
              </Link>
            </View>
            <View style={styles.qrContainer}>
              <Image src={playstoreQR} style={styles.qrLogo} />
              <Link src="https://play.google.com/store/apps">
                Download on Playstore
              </Link>
            </View>
          </View>
        </View>
      ))}
    </Page>
  </Document>
);

export default TeacherDocument;
