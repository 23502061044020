import { Link } from "react-router-dom";
import "./Analytics.scss";

const Analytics = () => {
  return (
    <div className="fl-1 p-24">
      <div className="grid-fill">
        <Link to="/analytics/analytics-stats">
          <div className="card card-nav clickable flex-column flex-center font-black">
            <h2 className="mb-16">Login Stats</h2>
            <p>View Login & CheckIn stats</p>
          </div>
        </Link>
        <Link to="/analytics/adventures-in-review">
          <div className="card card-nav clickable flex-column flex-center font-black">
            <h2 className="mb-16">Adventures In Review</h2>
            <p>View Adventures In Review</p>
          </div>
        </Link>
        <Link to="/analytics/adventures-completed">
          <div className="card card-nav clickable flex-column flex-center font-black">
            <h2 className="mb-16">Adventure Completed</h2>
            <p>View Completed Adventures</p>
          </div>
        </Link>
        <Link to="/analytics/number-of-words-studied">
          <div className="card card-nav clickable flex-column flex-center font-black">
            <h2 className="mb-16">Words Studied</h2>
            <p>View Number of Words Studied</p>
          </div>
        </Link>
        <Link to="/analytics/no-checkin-stats">
          <div className="card card-nav clickable flex-column flex-center font-black">
            <h2 className="mb-16">No CheckIn Stats</h2>
          </div>
        </Link>
        <Link to="/analytics/no-adventures-played">
          <div className="card card-nav clickable flex-column flex-center font-black">
            <h2 className="mb-16">No Adventures Played</h2>
          </div>
        </Link>
        <Link to="/analytics/no-time-spent">
          <div className="card card-nav clickable flex-column flex-center font-black">
            <h2 className="mb-16">No Time Spent</h2>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default Analytics;
