import { Table, Input } from "antd";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import _ from "lodash";
import { TailSpin as Loader } from "react-loader-spinner";
import { fetchAdventures } from "../../../actions/adventures";
import TabError from "../../../components/TabError/TabError";
import "antd/dist/reset.css";
import moment from "moment";
import Button from "../../../components/Button/Button";

const { Search } = Input;
const selectAdventures = ({ adventures }) => adventures.data;
const selectAdventuresPagination = ({ adventures }) => adventures.pagination;
const selectAdventuresLoading = ({ adventures }) => adventures.loading;
const selectAdventuresError = ({ adventures }) => adventures.error;

const adventuresArray = createSelector([selectAdventures], (adventures) =>
  Object.values(adventures)
);

const AdventureList = ({
  onNext,
  onBack,
  selectedAdventures,
  multiClass,
  classBattle,
}) => {
  const adventures = useSelector(adventuresArray);
  const adventuresPagination = useSelector(selectAdventuresPagination);
  const adventuresLoading = useSelector(selectAdventuresLoading);
  const adventuresError = useSelector(selectAdventuresError);
  const [selected, setSelected] = useState(selectedAdventures || []);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const shouldDisable = Object.keys(selected || []).length > 0;

  const handleNext = () => {
    if (!_.isEmpty(selected)) {
      onNext(selected);
    }
  };

  const dispatch = useDispatch();
  const offset = (page - 1) * pageSize;
  const limit = pageSize;

  useEffect(() => {
    dispatch(fetchAdventures(search, limit, offset));
  }, [dispatch, page, pageSize]);

  const onSearch = (value) => {
    dispatch(fetchAdventures(search, limit, 0));
    setPage(1);
  };

  const handlePagination = () => {
    return {
      current: page,
      pageSize: pageSize,
      total: adventuresPagination.count,
      onChange: (page, pageSize) => {
        setPage(page);
        setPageSize(pageSize);
      },
    };
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Learning Aim",
      dataIndex: "learning_aim",
      render: (value) =>
        value === "spelling_the_word"
          ? "Spelling the Word"
          : value === "writing_the_sentence"
          ? "Write the Sentence"
          : value === "saying_the_word"
          ? "Saying the Word"
          : value === "say_the_sentence"
          ? "Say the Sentence"
          : "",
    },
    {
      title: "Date Created",
      dataIndex: "date_created",
      defaultSortOrder: "descend",
      render: (value) => moment(value).format("DD MMM, YYYY"),
    },
    {
      title: "Date Updated",
      dataIndex: "date_updated",
      defaultSortOrder: "descend",
      render: (value) => moment(value).format("DD MMM, YYYY"),
    },
  ];

  const renderAdventureList = () => (
    <Table
      title={null}
      pagination={handlePagination()}
      columns={columns}
      dataSource={adventures.map((obj) => ({ ...obj, key: obj.id }))}
      showHeader
      size="default"
      bordered={false}
      rowClassName={(record, index) =>
        index % 2 === 0 ? "table-row-light" : "table-row-dark"
      }
      rowSelection={{
        renderCell: (checked, record, index, originNode) => {
          if (multiClass) {
            if (shouldDisable && !checked) return null;
          }
          return originNode;
        },
        selectedRowKeys: selected ? Object.keys(selected).map((a) => +a) : [],
        onChange: (selectedRowKeys, selectedRows) => {
          const rows = {};
          selectedRows.forEach((each) => {
            rows[each.id] = each;
          });
          setSelected(rows);
        },
        columnTitle: <div />,
      }}
    />
  );

  return (
    <div className="find-adventures-page flex-column fl-1">
      {adventuresLoading ? (
        <div className="flex flex-center fl-1">
          <Loader type="Puff" color="#354AC9" height={50} width={50} />
        </div>
      ) : adventuresError ? (
        <TabError
          onClick={() => dispatch(fetchAdventures())}
          message={adventuresError.message}
          label="Reload Adventures"
        />
      ) : (
        <>
          {classBattle ? (
            <h3 className="mb-16 text-heading mg-top ml-10">
              Step 3: Select an adventure
            </h3>
          ) : (
            <h1 className="mh-24 mt-24 mb-16">Adventure List</h1>
          )}

          <Search
            placeholder="Search Adventures"
            enterButton="Search"
            size="large"
            onSearch={onSearch}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <div className="overflow-hidden flex-column fl-1">
            {classBattle ? null : (
              <div className="flex justify-between align-center ph-24 pv-16 ml-8 mg-top">
                <h3>
                  Total Number of Adventures: {adventuresPagination.count}
                </h3>
                <h3>Selected Adventures: {Object.keys(selected).length}</h3>
              </div>
            )}

            <div id="scrollableContainer" className="list-container fl-1 ml-24">
              {renderAdventureList()}
            </div>
          </div>
          <div className="flex justify-end mb-24 mh-24">
            {adventures !== null && (
              <>
                <Button
                  label="Back"
                  primary
                  onClick={onBack}
                  className="mr-24"
                  wide
                />
                <Button
                  label="Next"
                  primary
                  onClick={handleNext}
                  wide
                  disabled={_.isEmpty(selected)}
                />
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default AdventureList;
