import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { createSelector } from "reselect";
import { deleteAdventure, fetchAdventure } from "../../actions/adventures";
import WordListItem from "../../components/WordListItem/WordListItem";
import TabError from "../../components/TabError/TabError";
import {TailSpin as Loader} from "react-loader-spinner";
import { Button } from "antd";
import setAdventureClone from "../../helper/setAdventureClone";
import Modal from "../../components/Modal/Modal";
import {
  ConfirmationDialog,
  ErrorDialog,
} from "../../components/ConfirmationDialog";
import "./PreviewAdventure.scss";

const selectAdventures = ({ adventures }) => adventures.data;
const selectAdventuresLoading = ({ adventures }) => adventures.loading;
const selectAdventuresError = ({ adventures }) => adventures.error;

const adventureByID = (id) =>
  createSelector([selectAdventures], (adventures) => adventures[id]);

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const PreviewAdventure = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const [deleted, setDeleted] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [learningaim, setLearningAim] = useState(1);
  const [loading, setLoading] = useState(false);
  const [selectedLearningAim, setSelectedLearningAim] = useState(1);
  const [adventureName, setAdventureName] = useState("");
  const adventure = useSelector(adventureByID(params.id));
  const adventuresLoading = useSelector(selectAdventuresLoading);
  const adventuresError = useSelector(selectAdventuresError);

  const query = useQuery();
  const history = query.get('history');

  useEffect(() => {
    dispatch(fetchAdventure(params.id));
  }, [dispatch, params]);

  useEffect(() => {
    if (!adventuresLoading && !adventure && deleted) {
      navigate("/adventures/list/");
    }
  }, [adventuresLoading, deleted, adventure, navigate]);

  useEffect(() => {
    if (learningaim !== "" && learningaim !== selectedLearningAim) {
      setLoading(true);
      setAdventureClone(params.id, adventureName, learningaim);
      setSelectedLearningAim(learningaim);
      setLoading(false);
    } else if (adventureName !== "") {
      setLoading(true);
      setAdventureClone(params.id, adventureName);
      setLoading(false);
    }
    setShowPopup(false);
  }, [learningaim, adventureName]);

  const handleConfirmationMessage = (adventure) => {
    ConfirmationDialog({
      title: "Are You Sure?",
      onConfirm: () => {
        if (!window.navigator.onLine) {
          ErrorDialog({
            title: "You're offline right now. Check your connection.",
          });
        } else {
          dispatch(deleteAdventure(adventure.id));
          setDeleted(true);
        }
      },
    });
  };

  return (
    <div className="fl-1 flex-column overflow-hidden">
      {adventuresLoading || loading ? (
        <div className="flex flex-center fl-1">
          <Loader type="Puff" color="#354AC9" height={50} width={50} />
        </div>
      ) : adventuresError ? (
        <TabError
          onClick={() => dispatch(fetchAdventure(params.id))}
          message={adventuresError.message}
          label="Reload Adventure"
        />
      ) : (
        <Fragment>
          <h2 className="ph-24 mt-8">Adventure Preview</h2>
          <div className="flex-column fl-1 overflow-hidden">
            <div className="flex-column overflow-hidden">
              <div className="flex-column ph-24">
                <div className="flex align-center justify-between">
                  <div className="flex-column">
                    <h1>{adventure?.name}</h1>
                    <p className="mb-16">Aim: {adventure?.learning_aim}</p>
                  </div>
                  {!history && !params.bundleid && (
                    <div className="flex">
                      <div className="mr-16">
                        <Button
                          size="large"
                          type="primary"
                          className="preview-button"
                          onClick={() => {
                            setShowPopup(true);
                          }}
                        >
                          Clone Adventure
                        </Button>
                      </div>
                      <Button
                        size="large"
                        type="danger"
                        className="preview-button"
                        onClick={() => handleConfirmationMessage(params)}
                      >
                        Delete adventure
                      </Button>
                    </div>
                  )}
                </div>
                <Modal
                  modalIsOpen={showPopup}
                  onYesClick={() => setShowPopup(false)}
                  onCancelClick={() => {
                    setShowPopup(false);
                  }}
                  adventureInput={true}
                  aim={(aim) => setLearningAim(aim)}
                  adventure={(name) => setAdventureName(name)}
                />
                <h3>Words:</h3>
              </div>
              {adventure?.words && (
                <div className="scroll-vertical fl-1">
                  <div className="words-grid p-24">
                    {adventure.words.map((word) => (
                      <WordListItem key={word.id} word={word} />
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default PreviewAdventure;
