export const stringMatch = (text, search) => {
  return text.toLowerCase().includes(search.toLowerCase());
};

export const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const getMinutes = (time) => {
  const hours = parseInt(time.split(":")[0]) * 60;
  const minutes = parseInt(time.split(":")[1]);
  return hours + minutes;
};
