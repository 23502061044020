import { useState } from "react";
import Select from "react-select";
import Button from "../../../components/Button/Button";
import { validateEmail } from "../../../utils";
import { gamerizeUnauthenticated } from "../../../apis/gamerize";
import { ErrorDialog } from "../../../components/ConfirmationDialog";
import {TailSpin as Loader} from "react-loader-spinner";

const selectOptions = [
  { value: "owner", label: "Admin" },
  { value: "teacher", label: "Teacher" },
];

const InformationForm = ({ onSubmit }) => {
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [user_type, setType] = useState(selectOptions[0].value);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const onFormSubmit = async (e) => {
    e.preventDefault();
    if (!window.navigator.onLine) {
      ErrorDialog({
        title: "You're offline right now. Check your connection.",
      });
    } else {
      setLoading(true);
      setError("");
      if (
        first_name === "" ||
        last_name === "" ||
        email === "" ||
        password === "" ||
        confirmPassword === ""
      ) {
        setError("Please complete the form!");
        setLoading(false);
        return;
      }

      if (!validateEmail(email)) {
        setError("Please enter a valid email address");
        setLoading(false);
        return;
      }

      if (password !== confirmPassword) {
        setError("The Passwords do not match");
        setLoading(false);
        return;
      }

      if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{6,}$/.test(password)) {
        setError(
          "The Password must be atleast 6 digits long, contain a lower case letter, an upper case letter and a digit."
        );
        setLoading(false);
        return;
      }

      try {
        await gamerizeUnauthenticated.post("/users/check-username/", {
          username,
        });
        onSubmit({
          first_name,
          last_name,
          email,
          username,
          user_type,
          password,
        });
      } catch (error) {
        if (error.response.status === 400) {
          setError("Username Already exists!");
          setLoading(false);
        } else {
          setError("Error validating Username, please try again");
          setLoading(false);
        }
      }
    }
  };

  return (
    <form onSubmit={onFormSubmit} className="flex-column">
      <div className="flex mb-16">
        <input
          placeholder="First Name"
          value={first_name}
          onChange={(e) => setFirstName(e.target.value)}
          type="text"
          className="fl-1 mr-16"
        />
        <input
          placeholder="Last Name"
          value={last_name}
          onChange={(e) => setLastName(e.target.value)}
          type="text"
          className="fl-1"
        />
      </div>
      <div className="flex mb-16">
        <input
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          type="text"
          className="fl-1 mr-16"
        />
        <input
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          type="text"
          className="fl-1"
        />
      </div>
      <div className="flex mb-16">
        <input
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          type="password"
          autoComplete="new-password"
          className="fl-1 mr-16"
        />
        <input
          placeholder="Confirm Password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          type="password"
          autoComplete="new-password"
          className="fl-1"
        />
      </div>
      <Select
        options={selectOptions}
        onChange={({ value }) => setType(value)}
        defaultValue={selectOptions[0]}
        className="fl-1 user-type mb-32"
        classNamePrefix="user-type"
      />
      {loading ? (
        <div className="flex-center-self">
          <Loader
            type="Puff"
            color="#354AC9"
            height={50}
            width={50} //3 secs
          />
        </div>
      ) : (
        <Button label="Next" primary type="submit" className="mb-16" />
      )}
      {error && <p className="text-center font-danger bold">{error}</p>}
    </form>
  );
};

export default InformationForm;
