import Button from "../../../components/Button/Button";
import { ErrorDialog } from "../../../components/ConfirmationDialog";

const SuccessSingleClass = ({ choice, onBack, onAssign }) => {
  const { first_name, last_name } = choice.teacher;

  const handleAssign = () => {
    if (!window.navigator.onLine) {
      ErrorDialog({
        title: "You're offline right now. Check your connection.",
      });
    } else {
      onAssign(choice);
    }
  };
  return (
    <div className="flex-column fl-1">
      <h3 className="mb-16 mh-24">Summary</h3>
      <div className="card flex-column mh-24">
        <h2 className="mb-24">{choice.class.name}</h2>
        <h2 className="mb-24" key={first_name}>
          {first_name} {last_name}
        </h2>
      </div>
      <div className="flex justify-end mt-auto mh-24 mb-24">
        <Button label="Back" primary onClick={onBack} className="mr-24" wide />
        <Button label="Assign" primary onClick={handleAssign} wide />
      </div>
    </div>
  );
};

export default SuccessSingleClass;
