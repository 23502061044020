import { useEffect, useState } from "react";
import StudentsBarListItem from "./StudentsBarListItem/StudentsBarListItem";
import "./StudentsBar.scss";
import InfiniteScroll from "react-infinite-scroll-component";
import {TailSpin as Loader} from "react-loader-spinner";
import gamerize from "../../apis/gamerize";
import fetchNext from "../../helper/fetchNext";
import TabError from "../TabError/TabError";
import { useDispatch, useSelector } from "react-redux";
import { fetchNextStudents, fetchStudents } from "../../actions/student";

const selectStudents = ({ students }) => Object.values(students.data);
const selectStudentsPagination = ({ students }) => students.pagination;
const selectStudentsLoading = ({ students }) => students.loading;
const selectStudentsError = ({ students }) => students.error;

const StudentsBar = ({ selectedStudents, pickStudent }) => {
  const dispatch = useDispatch();

  const fetchedStudents = useSelector(selectStudents);
  const studentsPagination = useSelector(selectStudentsPagination);
  const studentsLoading = useSelector(selectStudentsLoading);
  const studentsError = useSelector(selectStudentsError);
  const [selectedStudent, setSelectedStudents] = useState(selectedStudents);
  const [search, setSearch] = useState("");
  const offset = 0;

  useEffect(() => {
    setSelectedStudents(selectedStudents);
  }, [selectedStudents]);

  useEffect(() => {
    const delay = search === "" ? 0 : 1000;
    const delaySearch = setTimeout(() => {
      dispatch(fetchStudents(search, 20, offset));
    }, delay);
    return () => clearTimeout(delaySearch);
  }, [search, dispatch]);
  const renderStudentsList = () => (
    <InfiniteScroll
      className="mt-24"
      dataLength={fetchedStudents.length}
      next={() => dispatch(fetchNextStudents())}
      hasMore={studentsPagination.next ? true : false}
      loader={
        <div className="flex flex-center">
          <Loader type="Puff" color="#354AC9" height={50} width={50} />
        </div>
      }
      scrollableTarget="scrollableContainer"
      endMessage={
        <p style={{ textAlign: "center", gridColumn: "1 / 3" }}>
          <b>End of List</b>
        </p>
      }
    >
      {fetchedStudents.map((student) => {
        const selected = selectedStudent?.some((std) => std.id === student.id);
        return (
          <StudentsBarListItem
            key={student.id}
            student={student}
            className={selected ? "bg-gray mb-16" : "mb-16"}
            onClick={selected ? () => {} : pickStudent}
          />
        );
      })}
    </InfiniteScroll>
  );

  return (
    <div className="students-bar flex-column pv-16 ph-16">
      <h2>Students</h2>
      {studentsLoading ? (
        <div className="flex flex-center fl-1">
          <Loader type="Puff" color="#354AC9" height={50} width={50} />
        </div>
      ) : studentsError ? (
        <TabError
          onClick={() => dispatch(fetchStudents(search))}
          message={studentsError.message}
          label="Reload Stats"
        />
      ) : (
        <div id="scrollableContainer" className="students-list-container fl-1">
          {renderStudentsList()}
        </div>
      )}
      <input
        className="input input-icon icon-search-b"
        placeholder="Search Students"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
    </div>
  );
};

export default StudentsBar;
