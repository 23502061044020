import ReactModal from "react-modal";
import Button from "../Button/Button";
import Select from "react-select";
import { useState } from "react";

const customStyles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(255, 255, 255, 0.75)",
  },
  content: {
    position: "absolute",
    top: "40%",
    left: "40%",
    right: "40%",
    bottom: "40%",
    border: "1px solid #ccc",
    background: "#fff",
    overflow: "auto",
    WebkitOverflowScrolling: "touch",
    borderRadius: "16px",
    outline: "none",
    padding: "16px",
  },
};
const customStylesWide = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(255, 255, 255, 0.75)",
  },
  content: {
    position: "absolute",
    top: "30%",
    left: "30%",
    right: "30%",
    bottom: "22%",
    border: "1px solid #ccc",
    background: "#fff",
    overflow: "auto",
    WebkitOverflowScrolling: "touch",
    borderRadius: "16px",
    outline: "none",
    padding: "16px",
  },
};
const aims = [
  { value: 1, label: "Spell the word" },
  { value: 2, label: "Write the sentence" },
  { value: 3, label: "Say the word" },
  { value: 4, label: "Say the sentence" },
];
const packages = [
  { value: 1, label: "Basic" },
  { value: 2, label: "Pro" },
];
let aimValue;
const filterAdventures = ({ value }) => {
  aimValue = value;
};

let subsPackageValue = 1;
const filterPackages = ({ value }) => {
  subsPackageValue = value;
};

const Modal = ({
  modalIsOpen,
  onYesClick,
  onCancelClick,
  adventureInput = false,
  modifySubscription = false,
  aim,
  adventure,
  subsSeats,
  subsPackage,
  // totalPrice,
}) => {
  const [adventureName, setAdventureName] = useState("");
  const [seats, setSeats] = useState("");
  const handleYes = () => {
    onYesClick();
    if (modifySubscription) {
      subsSeats(seats);
      subsPackage(subsPackageValue);
    } else {
      adventure(adventureName);
      aim(aimValue);
    }
  };

  const handleCancel = () => {
    onCancelClick();
  };

  ReactModal.setAppElement("#root");
  if (adventureInput) {
    return (
      <ReactModal
        isOpen={modalIsOpen}
        onRequestClose={handleCancel}
        style={customStylesWide}
        contentLabel="Example Modal"
      >
        <h4 className="mr-24 mb-8">Adventure Name</h4>
        <input
          className="form-input"
          value={adventureName}
          onChange={({ target }) => setAdventureName(target.value)}
        />
        <h4 className="mr-24 mt-24  mb-8">Learning Aim</h4>
        <Select
          options={aims}
          onChange={filterAdventures}
          defaultValue={aims[0]}
          className="adventures-list-filter"
        />
        <div className="flex justify-end mt-24">
          <Button
            primary
            label="Cancel"
            className="mh-4 mv-24"
            onClick={handleCancel}
          />
          <Button
            primary
            label="Done"
            className="mh-4 mv-24"
            onClick={handleYes}
          />
        </div>
      </ReactModal>
    );
  } else if (modifySubscription) {
    return (
      <ReactModal
        isOpen={modalIsOpen}
        onRequestClose={handleCancel}
        style={customStylesWide}
        contentLabel="Example Modal"
      >
        <h4 className="mr-24 mb-8">Seats</h4>
        <input
          className="form-input"
          type="number"
          min="0"
          onChange={(e) => {
            let value = parseInt(e.target.value, 10);
            value = value >= 0 ? value : 0;
            setSeats(value);
          }}
          value={seats}
        />
        <h4 className="mr-24 mb-8 mt-16">Select Package</h4>
        {
          <Select
            options={packages}
            onChange={filterPackages}
            defaultValue={packages[0]}
            className="adventures-list-filter"
          />
        }
        {/*
          <input
            className="form-input"
            value={subscriptionPackage}
            onChange={({ target }) => setSubscriptionPackage(target.value)}
          />
        */}
        <div className="flex justify-end mt-24">
          <Button
            primary
            label="Cancel"
            className="mh-4 mv-24"
            onClick={handleCancel}
          />
          <Button
            primary
            label="Done"
            className="mh-4 mv-24"
            onClick={handleYes}
          />
        </div>
      </ReactModal>
    );
  } else {
    return (
      <ReactModal
        isOpen={modalIsOpen}
        onRequestClose={handleCancel}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <h2 className="pv-16">Are you sure?</h2>
        <div className="flex justify-end mt-24">
          <Button
            primary
            label="Cancel"
            className="mh-4"
            onClick={handleCancel}
          />
          <Button primary label="Yes" className="mh-4" onClick={handleYes} />
        </div>
      </ReactModal>
    );
  }
};

export default Modal;
