import { useCallback, useEffect, useMemo, useState } from "react";
import gamerize from "../../apis/gamerize";
import TabError from "../../components/TabError/TabError";
import Table from "../../components/Table/Table";
import fetchNext from "../../helper/fetchNext";
import Button from "../../components/Button/Button";
import DatePicker from "react-datepicker";
import { getFormattedDate } from "../../helper/getFormattedDate";
import "./AnalyticsNoCheckInStats.scss";
import { Link, Outlet } from "react-router-dom";

const NoCheckInStats = () => {
  const columns = useMemo(
    () => [
      {
        Header: "Username",
        accessor: "username",
        Cell: ({ row }) => (
          <Link to={`/analytics/student/${row.original.id}`}>{row.original.username}</Link>
        ),
      },
      {
        Header: "First Name",
        accessor: "first_name",
      },
      {
        Header: "Last Name",
        accessor: "last_name",
      },
    ],

    []
  );

  const [stats, setStats] = useState([]);
  const [next, setNext] = useState(null);
  const [error, setError] = useState("");

  let currentDate = new Date();
  let now = new Date();
  now.setDate( currentDate.getDate() - 7 );
  
  const [startDateSearch, setStartDateSearch] =  useState(now);
  const [endDateSearch, setEndDateSearch] = useState(currentDate);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const handleSearch = () => {
    setError(null);
    const startDateFormatted = getFormattedDate(startDateSearch);
    const currentDate = getFormattedDate(new Date());
    if (currentDate < startDateFormatted) {
      setError({ message: "Error,You cannot select that start date!" });
    }
    if (endDateSearch) {
      const endDateFormatted = getFormattedDate(endDateSearch);
      if (endDateFormatted < startDateFormatted) {
        setError({ message: "Error, Invalid Date Selection!" });
      }
      if (currentDate < endDateFormatted) {
        setError({ message: "Error,You cannot select that end date!" });
      }
      setEndDate(endDateFormatted);
    }
    setStartDate(startDateFormatted);
  };

  const URL = "/analytics/checkins/stats/checkin-stats/?";

  const fetchStats = useCallback(
    async (limit = 10) => {
      try {
        const response = await gamerize.get(
          `${URL}limit=${limit}&no_checkin=1&start=${startDate}&end=${endDate}`
        );
        setStats(response.data.results);
        setNext(response.data.next);
      } catch (error) {
        setError({ message: "Error fetching data" });
      }
    },
    [startDate, endDate]
  );

  useEffect(() => {
    fetchStats(20);
  }, [fetchStats]);

  return (
    <div className="checkin-stats-page fl-1 flex-column">
      <div className="flex justify-between">
        <div>
          <h1 className="mh-24 mt-24 mb-24">No CheckIn Stats</h1>
          <p className="mh-24 mb-24">Students who have not Checked In</p>
        </div>
        <div>
          <div className="flex mt-24 mr-24">
            <div className="mr-16">
              <h4>Enter Start Date:</h4>
              <DatePicker
                selected={startDateSearch}
                onChange={(date) => setStartDateSearch(date)}
              />
            </div>
            <div className="mr-16">
              <h4>Enter End Date:</h4>
              <DatePicker
                selected={endDateSearch}
                onChange={(date) => setEndDateSearch(date)}
              />
            </div>
            <Button primary label="Search" onClick={handleSearch} />
          </div>
        </div>
      </div>
      {error ? (
        <TabError
          onClick={() => {
            setError("");
            fetchStats();
          }}
          message={error.message}
          label="Reload Stats"
        />
      ) : (
        <div id="scrollableContainer" className="list-container fl-1">
          <Table
            columns={columns}
            data={stats}
            update={async () => {
              try {
                const response = await fetchNext(URL, next);
                const { data, nextPage } = response;
                setStats((prevStats) => [...prevStats, ...data]);
                setNext(nextPage);
              } catch (error) {
                setError("Error fetching more data");
              }
            }}
            next={next}
          />
        </div>
      )}
      <Outlet/>
    </div>
  );
};

export default NoCheckInStats;
