import RefreshButton from "../Button/RefreshButton"

const TabError = ({ onClick, message, label }) => {
  return (
    <div className="flex-column flex-center fl-1">
      <h3 className="mb-16 font-danger">{message}</h3>
      <RefreshButton label={label} onClick={onClick} />
    </div>
  )
}

export default TabError