import { useSelector } from "react-redux";
import "./Home.scss";
import OwnerHome from "./OwnerHome";
import TeacherHome from "./TeacherHome";

const authSelector = ({ auth }) => auth;

const Home = () => {
  const user = useSelector(authSelector);
  if (!user) {
    return <p>Loading</p>;
  }
  if (user.user_type === "teacher") {
    return <TeacherHome />;
  } else {
    return <OwnerHome />;
  }
};

export default Home;
