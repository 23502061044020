import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { Link, useNavigate } from "react-router-dom";
import { Table as AntTable, Dropdown, Menu, Input } from "antd";
import { DownOutlined, CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { fetchClasses } from "../../actions/classes";
import { ErrorDialog } from "../../components/ConfirmationDialog";
import "./MyClasses.scss";
import {TailSpin as Loader} from "react-loader-spinner";
import TabError from "../../components/TabError/TabError";

const selectClasses = ({ classes }) => classes.data;
const selectClassesPagination = ({ classes }) => classes.pagination;
const selectClassesLoading = ({ classes }) => classes.loading;
const selectClassesError = ({ classes }) => classes.error;
const { Search } = Input;

const selectClassList = createSelector([selectClasses], (classes) =>
  Object.values(classes)
);

const MyClasses = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const classes = useSelector(selectClassList);
  const classesPagination = useSelector(selectClassesPagination);
  const classesLoading = useSelector(selectClassesLoading);
  const classesError = useSelector(selectClassesError);

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const offset = (page - 1) * pageSize;
  const [search, setSearch] = useState("");

  const columns = [
    {
      title: "Class Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Number of Students",
      dataIndex: "students",
      key: "students.length",
      render: (value) => value.length,
    },

    {
      title: "Default Class",
      dataIndex: "is_default_class",
      key: "is_default_class",
      render: (value) => (value ? <CheckOutlined /> : <CloseOutlined />),
    },
    {
      title: "Teacher",
      dataIndex: "teacher",
      key: "teacher",
      render: (value) => (value ? <CheckOutlined /> : <CloseOutlined />),
    },
    {
      title: "Action",
      key: "action",
      width: 160,
      fixed: "right",
      render: (value) => (
        <span className="table-action-buttons">
          <Link
            className="text-button view"
            to={`/classes/my-classes/class/view/${value.id}`}
          >
            View
          </Link>
          <Dropdown
            trigger={["click"]}
            className="table-more-button"
            overlay={
              <Menu className="actions-menu">
                <Menu.Item onClick={() => editHandler(value)}>Edit</Menu.Item>
              </Menu>
            }
          >
            <span className="text-button ant-dropdown-link">
              More <DownOutlined />
            </span>
          </Dropdown>
        </span>
      ),
    },
  ];

  useEffect(() => {
    fetchClassData()   
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchClasses(search, pageSize, offset));
  }, [page, pageSize]);

  const editHandler = (value) => {
    if (!window.navigator.onLine) {
      ErrorDialog({
        title: "You're offline right now. Check your connection.",
      });
    } else {
      navigate(`/classes/my-classes/class/edit/${value.id}`);
    }
  };

  const handlePagination = () => {
    return {
      current: page,
      pageSize: pageSize,
      total: classesPagination.count,
      onChange: (ClassesPage, ClassesPageSize) => {
        setPage(ClassesPage);
        setPageSize(ClassesPageSize);
      },
    };
  };

  const renderClassList = () => (
    <>
      <div className="flex-column fl-1 mr-24">
        <h4>
          <span className="total_students ml-16 mb-24">Total Classes :</span>
          {classesPagination.count}
        </h4>
      </div>
      <AntTable
        title={null}
        pagination={handlePagination()}
        columns={columns}
        dataSource={classes}
        showHeader
        size="default"
        bordered={false}
      />
    </>
  );
  
  const fetchClassData = () =>{
    if (search !== "") {
      dispatch(fetchClasses(search, pageSize, 0));
    } else {
      dispatch(fetchClasses(search, pageSize, offset));
    }
    setPage(1);
  }

  const onSearch = value => {
    fetchClassData()   
    setPage(1);
  };

  return (
    <div className="my-classes-page flex-column fl-1 pt-24">
      {classesLoading ? (
        <div className="flex flex-center fl-1">
          <Loader type="Puff" color="#354AC9" height={50} width={50} />
        </div>
      ) : classesError ? (
        <TabError
          onClick={() => dispatch(fetchClasses())}
          message={classesError.message}
          label="Reload Tab"
        />
      ) : (
        <Fragment>
          <div className="flex align-center justify-between mb-8 mh-24">
            <h2>My Classes</h2> 
          </div>
          <div className="flex align-center justify-between mb-8 mh-24">
            <Search
              placeholder="Search Classes"
              enterButton="Search"
              size="large"
              onSearch={onSearch}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              />
          </div>
          <div id="scrollableContainer" className="list-container fl-1">
            {classes.length > 0 && renderClassList()}
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default MyClasses;
