import _ from "lodash";
import {
  FETCH_ADVENTURES,
  CREATE_ADVENTURE,
  UPDATE_ADVENTURE,
  FETCH_ADVENTURE,
  DELETE_ADVENTURE,
  FETCH_ADVENTURES_SUCCESS,
  FETCH_ADVENTURE_SUCCESS,
  CREATE_ADVENTURE_SUCCESS,
  UPDATE_ADVENTURE_SUCCESS,
  DELETE_ADVENTURE_SUCCESS,
  FETCH_ADVENTURES_ERROR,
  FETCH_ADVENTURE_ERROR,
  CREATE_ADVENTURE_ERROR,
  UPDATE_ADVENTURE_ERROR,
  DELETE_ADVENTURE_ERROR,
  FETCH_NEXT_ADVENTURES_SUCCESS,
} from "../actions/types";

const DEFAULT_STATE = {
  loading: false,
  error: null,
  status: 'idle',
  pagination: {
    count: 0,
    next: null,
    prev: null
  },
  data: {}
};

const adventuresReducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case FETCH_ADVENTURES:
    case FETCH_ADVENTURE:
    case CREATE_ADVENTURE:
    case UPDATE_ADVENTURE:
    case DELETE_ADVENTURE:
      return { ...state, status: 'loading', loading: true, error: null };
    case FETCH_ADVENTURES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        status: 'succeeded',
        pagination: {
          count: action.payload.count,
          next: action.payload.next,
          previous: action.payload.previous
        },
        data: { ..._.mapKeys(action.payload.results, "id") }
      };
    case FETCH_NEXT_ADVENTURES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        status: 'succeeded',
        pagination: {
          count: action.payload.count,
          next: action.payload.next,
          previous: action.payload.previous
        },
        data: { ...state.data, ..._.mapKeys(action.payload.results, "id") }
      }
    case FETCH_ADVENTURE_SUCCESS:
    case UPDATE_ADVENTURE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        status: 'succeeded',
        data: {
          ...state.data,
          [action.payload.id]: action.payload
        }
      };
    case CREATE_ADVENTURE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        status: 'succeeded',
        pagination: {
          ...state.pagination,
          count: state.pagination.count + 1
        },
        data: {
          ...state.data,
          [action.payload.id]: action.payload
        }
      };
    case DELETE_ADVENTURE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        status: 'succeeded',
        pagination: {
          ...state.pagination,
          count: state.pagination.count - 1
        },
        data: _.omit(state.data, action.payload)
      };
    case FETCH_ADVENTURES_ERROR:
    case FETCH_ADVENTURE_ERROR:
    case CREATE_ADVENTURE_ERROR:
    case UPDATE_ADVENTURE_ERROR:
    case DELETE_ADVENTURE_ERROR:
      return { ...state, status: 'failed', loading: false, error: action.payload }
    default:
      return state;
  }
};

export default adventuresReducer;
