import gamerize from "../apis/gamerize";
import { ErrorDialog } from "../components/ConfirmationDialog";
const getStudentAnalytic = async ({ id }) => {
  try {
    const response = await gamerize.get(`/analytics/student_analytics/${id}/`);
    return response.data;
  } catch (error) {
    ErrorDialog({
      title: "Error Fetching Student Details",
    });
  }
};
const getStudentOverallAnalytic = async ({ id }) => {
  try {
    const response = await gamerize.get(`/analytics/student_analytics/${id}/`, {
      params: { get_all: true },
    });
    return response.data;
  } catch (error) {
    ErrorDialog({
      title: "Error Fetching Student Details",
    });
  }
};
export { getStudentAnalytic, getStudentOverallAnalytic };
