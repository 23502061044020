import { Link } from "react-router-dom";
import "./ClassBattle.scss";
const ClassBattle = () => {
  return (
    <div className="class-battle-page fl-1 p-24">
      <div className="grid-fill">
        <Link to="/class-battle/new-battle">
          <div className="card card-nav clickable flex-column flex-center font-black">
            <h2 className="mb-16">New Class Battle</h2>
            <p>Create a new Class Battle</p>
          </div>
        </Link>
        <Link to="/class-battle/history">
          <div className="card card-nav clickable flex-column flex-center font-black">
            <h2 className="mb-16">Class Battle History</h2>
            <p>List of Class Battles</p>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default ClassBattle;
