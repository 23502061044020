import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import NavBar from '../components/NavBar/NavBar';
import { fetchUser } from "../actions/auth";
import { TailSpin as Loader } from 'react-loader-spinner';

const selectAuthenticated = ({ auth }) => auth.isAuthenticated;
const selectUser = ({ auth }) => auth.email;

const PrivateRoute = () => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(selectAuthenticated);
  const user = useSelector(selectUser);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // if (!user) {
    dispatch(fetchUser());
    // }
  }, [dispatch, user]);

  useEffect(() => {
    if (user) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [user]);

  if (!isAuthenticated) {
    console.log('not authenticated');
    return <Navigate to="/login" replace />;
  }

  if (loading) {
    return (
      <div className="flex flex-center" style={{ height: "100vh" }}>
        <Loader type="Puff" color="#354AC9" height={50} width={50} />
      </div>
    );
  }

  return (
    <Fragment>
      <NavBar />
      <Outlet />
    </Fragment>
  );
};

export default PrivateRoute;
