import { useCallback, useEffect, useMemo, useState } from "react";
import gamerize from "../../apis/gamerize";
import TabError from "../../components/TabError/TabError";
import Table from "../../components/Table/Table";
import fetchNext from "../../helper/fetchNext";
import Button from "../../components/Button/Button";
import DatePicker from "react-datepicker";
import { renderColorScheme } from "../../helper/renderColorScheme";
import getColorCoding from "../../helper/getColorCoding";
import { getFormattedDate } from "../../helper/getFormattedDate";
import { Link } from "react-router-dom";

const GameLoginStats = () => {
  const columns = useMemo(
    () => [
      {
        Header: "Username",
        accessor: "username",
        Cell: ({ row }) => (
          <Link to={`/analytics/analytics-stats/student/${row.original.id}`}>{row.original.username}</Link>
        ),
      },
      {
        Header: "First Name",
        accessor: "first_name",
      },
      {
        Header: "Last Name",
        accessor: "last_name",
      },
      {
        Header: "Time Spent",
        accessor: "time_spent",
      },
    ],

    []
  );

  const ascendingOrder = "time_spent";
  const descendingOrder = "-time_spent";

  const [stats, setStats] = useState([]);
  const [next, setNext] = useState(null);
  const [error, setError] = useState("");
  const [order, setOrder] = useState(descendingOrder);

  const [startDateSearch, setStartDateSearch] = useState(null);
  const [endDateSearch, setEndDateSearch] = useState(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const handleSearch = () => {
    setError(null);
    const startDateFormatted = getFormattedDate(startDateSearch);
    const currentDate = getFormattedDate(new Date());
    if (currentDate < startDateFormatted) {
      setError({ message: "Error,You cannot select that start date!" });
    }
    if (endDateSearch) {
      const endDateFormatted = getFormattedDate(endDateSearch);
      if (endDateFormatted < startDateFormatted) {
        setError({ message: "Error, Invalid Date Selection!" });
      }
      if (currentDate < endDateFormatted) {
        setError({ message: "Error,You cannot select that end date!" });
      }
      setEndDate(endDateFormatted);
    }
    setStartDate(startDateFormatted);
  };

  const handleAscending = () => {
    setOrder(ascendingOrder);
  };

  const handleDescending = () => {
    setOrder(descendingOrder);
  };

  const URL = `/analytics/stats/app-stats/?`;

  const fetchStats = useCallback(
    async (limit = 10) => {
      try {
        if (startDate && endDate === "") {
          const response = await gamerize.get(
            `${URL}limit=${limit}&ordering=${order}&start=${startDate}&time_spent_type=2`
          );
          setStats(response.data.results);
          setNext(response.data.next);
        } else if (startDate === "" && endDate) {
          const response = await gamerize.get(
            `${URL}limit=${limit}&ordering=${order}&end=${endDate}&time_spent_type=2`
          );
          setStats(response.data.results);
          setNext(response.data.next);
        } else if (startDate === "" && endDate === "") {
          const response = await gamerize.get(
            `${URL}limit=${limit}&ordering=${order}&time_spent_type=2`
          );
          setStats(response.data.results);
          setNext(response.data.next);
        } else {
          const response = await gamerize.get(
            `${URL}limit=${limit}&ordering=${order}&start=${startDate}&end=${endDate}&time_spent_type=2`
          );
          setStats(response.data.results);
          setNext(response.data.next);
        }
      } catch (error) {
        setError({ message: "Error fetching data" });
      }
    },
    [startDate, endDate, order]
  );

  useEffect(() => {
    fetchStats(20);
  }, [fetchStats]);

  return (
    <div
      className="login-stats-page fl-1 flex-column"
      style={{ width: "70vw" }}
    >
      <div className="flex justify-between">
        <div>
          <h1 className="mh-24 mt-24 mb-24">Game Login Stats</h1>
          <p className="mh-24 mb-24">
            Time spent in the game per student in the last 7 days
          </p>
        </div>
        <div>
          <div className="flex mt-24 mr-24">
            <div className="mr-16">
              <h4>Enter Start Date:</h4>
              <DatePicker
                selected={startDateSearch}
                onChange={(date) => setStartDateSearch(date)}
              />
            </div>
            <div className="mr-16">
              <h4>Enter End Date:</h4>
              <DatePicker
                selected={endDateSearch}
                onChange={(date) => setEndDateSearch(date)}
              />
            </div>
            <Button primary label="Search" onClick={handleSearch} />
          </div>
          <div className="flex justify-end">
            <h2 className="mt-32 mr-16 mb-24">Order : </h2>
            <Button
              primary
              className="mr-16 mt-24 mb-4 mb-24 order-select"
              label="Ascending"
              onClick={handleAscending}
            />
            <Button
              primary
              className=" mr-16 mt-24 mb-4 mb-24 order-select"
              label="Descending"
              onClick={handleDescending}
            />
          </div>
        </div>
      </div>
      {error ? (
        <TabError
          onClick={() => {
            setError("");
            fetchStats();
          }}
          message={error.message}
          label="Reload Stats"
        />
      ) : (
        <div id="scrollableContainer" className="list-container fl-1">
          <Table
            columns={columns}
            data={stats}
            update={async () => {
              try {
                const response = await fetchNext(URL, next);
                const { data, nextPage } = response;
                setStats((prevStats) => [...prevStats, ...data]);
                setNext(nextPage);
              } catch (error) {
                setError("Error fetching more data");
              }
            }}
            next={next}
            getRowProps={(row) => ({
              style: {
                background: getColorCoding(row),
              },
            })}
          />
          {stats.length === 0 ? <div></div> : renderColorScheme()}
        </div>
      )}
    </div>
  );
};

export default GameLoginStats;
