import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Unity, useUnityContext } from "react-unity-webgl";
import { setBattleState } from "../../../../actions/class-battle-history";
import { TailSpin as Loader } from "react-loader-spinner";
import "./Game.scss";
import { config, apiV1 } from "../../../../constants";

const authSelector = ({ auth }) => auth;

const Game = ({ selectedClass, monster_id, battle_type, adventure }) => {

  function handleCacheControl(url) {
    if (url.match(/\.unityweb/)) {
      return "must-revalidate";
    }
    return "no-store";
  }

  const {
    unityProvider,
    isLoaded,
    loadingProgression,
    sendMessage,
    addEventListener,
    removeEventListener,
  } = useUnityContext({
    loaderUrl: "/assets/unity_webgl_build/classbattle.loader.js",
    dataUrl: "/assets/unity_webgl_build/classbattle.data.unityweb",
    frameworkUrl: "/assets/unity_webgl_build/classbattle.framework.js.unityweb",
    codeUrl: "/assets/unity_webgl_build/classbattle.wasm.unityweb",
    cacheControl: handleCacheControl,
  });

  const dispatch = useDispatch();

  const access_token = localStorage.getItem("access_token");

  const [isBattleLive, setIsBattleLive] = useState(false);
  const [IsUnityBuildLaunched, setIsUnityBuildLaunched] = useState(false);

  const handleBattleState = useCallback((is_battle_live) => {
    const value = is_battle_live === 1 ? true : false;
    dispatch(setBattleState(value));
    setIsBattleLive(value);
  });

  const handleUnityLaunch = useCallback((state) => {
    setIsUnityBuildLaunched(state);
  });

  useEffect(() => {
    addEventListener("BattleStatus", handleBattleState);

    return () => {
      removeEventListener("BattleStatus", handleBattleState);
    };
  }, [addEventListener, removeEventListener, handleBattleState]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (isBattleLive) {
        const message =
          "A battle is currently live. Are you sure you want to leave?";
        event.returnValue = message;
        return message;
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isBattleLive]);

  useEffect(() => {
    addEventListener("IsUnityBuildLaunched", handleUnityLaunch);

    return () => {
      removeEventListener("IsUnityBuildLaunched", handleUnityLaunch);
    };
  }, [addEventListener, removeEventListener, handleUnityLaunch]);

  useEffect(() => {
    if (IsUnityBuildLaunched) {
      handleSend();
    }
  }, [IsUnityBuildLaunched]);

  const handleSend = () => {
    const jsonData = JSON.stringify({
      baseURL: config.API_URL.slice(0, -apiV1.length),
      token: access_token,
      class_id: Object.keys(selectedClass)[0],
      class_name: selectedClass[Object.keys(selectedClass)[0]].name,
      monster_id: monster_id,
      battle_type: battle_type,
      adventure: adventure ? Object.values(adventure)[0].id : null,
      adventure_name: adventure ? Object.values(adventure)[0].name : null,
      learning_aim: adventure ? Object.values(adventure)[0].learning_aim : null,
    });

    // Send JSON data to Unity
    sendMessage("UnityReactCommunicator", "SetUserDataFromReact", jsonData);
  };

  const user = useSelector(authSelector);

  const loadingPercentage = Math.round(loadingProgression * 100);

  return (
    <>
      <div className="unity-container">
        {isLoaded === false && (
          <div className="loading-overlay">
            <div className="progress-bar">
              <div
                className="progress-bar-fill"
                style={{ width: `${loadingPercentage}%` }}
              ></div>
              <span className="progress-text">{loadingPercentage}%</span>
            </div>
          </div>
        )}
        <Unity
          unityProvider={unityProvider}
          style={{ width: "90%", height: "90%" }}
        />
      </div>
    </>
  );
};

export default Game;
