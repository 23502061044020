import gamerize from "../apis/gamerize";
import { ErrorDialog, SuccessDialog } from "../components/ConfirmationDialog";
import {
  DELETE_TEACHER,
  DELETE_TEACHER_ERROR,
  DELETE_TEACHER_SUCCESS,
  FETCH_NEXT_TEACHERS_SUCCESS,
  FETCH_TEACHERS,
  FETCH_TEACHERS_ERROR,
  FETCH_TEACHERS_SUCCESS,
} from "./types";

export const fetchTeachers =
  (limit = 10, offset = 0) =>
  async (dispatch) => {
    dispatch({ type: FETCH_TEACHERS });
    try {
      const response = await gamerize.get(
        `/users/owners/teachers/?limit=${limit}&offset=${offset}`
      );
      dispatch({
        type: FETCH_TEACHERS_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: FETCH_TEACHERS_ERROR,
        payload: {
          message: "There was an error fetching the teachers.",
        },
      });
    }
  };

export const fetchNextTeachers = () => async (dispatch, getState) => {
  const { next } = getState().teachers.pagination;
  if (!next) return;
  const query = next.split("?")[1] || "";
  try {
    const response = await gamerize.get(`/users/owners/teachers/?${query}`);
    dispatch({
      type: FETCH_NEXT_TEACHERS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_TEACHERS_ERROR,
      payload: {
        message: "Error fetching teachers.",
      },
    });
  }
};

export const removeTeacher = (id) => async (dispatch) => {
  dispatch({ type: DELETE_TEACHER });
  try {
    const response = await gamerize.post(
      `/users/owners/teachers/${id}/remove_teacher/`
    );
    dispatch({
      type: DELETE_TEACHER_SUCCESS,
      payload: id,
    });
    if (response) {
      SuccessDialog({
        title: "Successfully teacher removed!",
      });
    }
  } catch (error) {
    ErrorDialog({
      title: error.response.data,
    });
    dispatch({
      type: DELETE_TEACHER_ERROR,
      payload: {
        message: "There was an error removing the teacher.",
      },
    });
  }
};
