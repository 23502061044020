import { PDFDownloadLink } from "@react-pdf/renderer";
import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { updateUser } from "../../actions/auth";
import gamerize from "../../apis/gamerize";
import Button from "../../components/Button/Button";
import TeacherDocument from "./TeacherDocument";
import {
  ConfirmationDialog,
  ErrorDialog,
} from "../../components/ConfirmationDialog";
import {TailSpin as Loader} from "react-loader-spinner";
import "./ProfileOptions.scss";

const TeacherOptions = ({ user }) => {
  const dispatch = useDispatch();

  const [error, setError] = useState("");
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [instituteCode, setInstituteCode] = useState("");
  const [sentRequest, setSentRequest] = useState(null);
  const [updates, setUpdates] = useState(false);
  const [loading, setloading] = useState(false);
  const [instituteCodeUpdate, setInstituteCodeUpdate] = useState(false);

  useEffect(() => {
    const fetchRequest = async () => {
      const res = await gamerize.get("/schools/requests/teachers/");
      if (res.data.results.length > 0) {
        setSentRequest(res.data.results[0]);
      }
    };
    fetchRequest();
  }, []);

  useEffect(() => {
    setFirstName(user?.first_name || "");
    setLastName(user?.last_name || "");
    setEmail(user?.email || "");
    setCode(user?.code || "");
    setInstituteCode(sentRequest?.school.code || "");
  }, [user, sentRequest]);

  useEffect(() => {
    if (user) {
      const nameChange = first_name !== user?.first_name && first_name !== "";
      const lastNameChange = last_name !== user?.last_name && last_name !== "";
      const emailChange = email !== user?.email && email !== "";
      const codeChange = code !== user?.code && code !== "";
      const instituteChange =
        instituteCode !== sentRequest?.school.code && instituteCode !== "";
      if (nameChange || lastNameChange || emailChange || codeChange) {
        setUpdates(true);
      } else {
        setUpdates(false);
      }
      if (instituteChange) {
        setInstituteCodeUpdate(true);
      } else {
        setInstituteCodeUpdate(false);
      }
    }
  }, [first_name, last_name, email, code, instituteCode, user, sentRequest]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    ConfirmationDialog({
      title: "Are You Sure?",
      onConfirm: async () => {
        setloading(true);
        const updatedUser = {
          first_name,
          last_name,
          email,
          code,
        };
        try {
          if (
            instituteCode !== sentRequest?.school.code &&
            instituteCode !== ""
          ) {
            await gamerize.post("/schools/requests/teachers/", {
              school: instituteCode,
            });
            const res = await gamerize.get("/schools/requests/teachers/");
            if (res.data.results.length > 0) {
              setSentRequest(res.data.results[0]);
            }
          }
          dispatch(updateUser(updatedUser));
          setloading(false);
        } catch (error) {
          ErrorDialog({
            title: Object.values(error.response.data)[0][0],
          });
          setloading(false);
          return;
        }
      },
    });
  };

  const handleConfirmationMessage = () => {
    ConfirmationDialog({
      title: "Are You Sure?",
      onConfirm: () => {
        handleSubmitCode();
      },
    });
  };

  const handleSubmitCode = async () => {
    setloading(true);
    try {
      const response = await gamerize.post(
        "users/teachers/profile/change_code/"
      );
      const res = response.data.code;
      if (res.length > 0) {
        setCode(res);
      }
    } catch (error) {
      ErrorDialog({
        title: "Error Fetching Teacher Code",
      });
    }
    setloading(false);
  };
  const DownloadPdf = (name, code, instCode) => {
    return useMemo(
      () => (
        <PDFDownloadLink
          document={
            <TeacherDocument name={name} code={code} instCode={instCode} />
          }
          fileName="Teacher Details.pdf"
        >
          {({ blob, url, loading, error }) =>
            !loading && <Button label="Print Profile Slip" primary />
          }
        </PDFDownloadLink>
      ),
      [code, name, instCode]
    );
  };

  const deleteRequest = async (id) => {
    try {
      await gamerize.delete(`/schools/requests/teachers/${id}/`);
      setSentRequest(null);
    } catch (error) {
      setError("Error deleting request");
    }
  };

  return (
    <div className="profile-options-page flex-column fl-1 p-24">
      <div className="flex justify-between align-center mb-24 mt-24">
        <h2>Profile Options</h2>
        <hr />
        {DownloadPdf(
          `${user.first_name} ${user.last_name}`,
          user.code,
          instituteCode
        )}
      </div>

      {error && <h2 className="mh-24 mb-24">{error}</h2>}
      <form className="profile-form" onSubmit={handleSubmit}>
        <div className="flex mb-16 align-center justify-between">
          <h4 className="mr-24">First Name</h4>
          <input
            className="form-input"
            value={first_name}
            onChange={({ target }) => setFirstName(target.value)}
          />
        </div>
        <div className="flex mb-16 align-center justify-between">
          <h4 className="mr-24">Last Name</h4>
          <input
            className="form-input"
            value={last_name}
            onChange={({ target }) => setLastName(target.value)}
          />
        </div>
        <div className="flex mb-16 align-center justify-between">
          <h4 className="mr-24">Email</h4>
          <input
            className="form-input"
            value={email}
            onChange={({ target }) => setEmail(target.value)}
          />
        </div>

        <div className="flex mb-32">
          <Button
            className="fl-1"
            primary
            type="submit"
            label="Update"
            disabled={!updates}
          />
        </div>
        {loading && (
          <div className="flex flex-center fl-1">
            <Loader type="Puff" color="#354AC9" height={50} width={50} />
          </div>
        )}
        <hr />
        <div className="flex mb-16 align-center justify-between">
          <h4 className="mr-24">Teachers Code</h4>
          <h3>{code}</h3>
        </div>

        <div className="flex mb-32">
          <Button
            className="fl-1"
            primary
            type="button"
            onClick={handleConfirmationMessage}
            label="Refresh Code"
          />
        </div>
        {sentRequest && sentRequest.status !== "accepted" ? (
          <>
            <hr />
            <div className="flex mb-16 align-center justify-between">
              <h4 className="mr-24">Sent Request</h4>
              <h3>{sentRequest.status}</h3>
            </div>
            <div className="flex mb-16 align-center justify-between">
              <h4 className="mr-24">School Name</h4>
              <h3>{sentRequest.school.name}</h3>
            </div>
            <div className="flex mb-32">
              <Button
                className="fl-1"
                primary
                label="Delete Request"
                onClick={() => {
                  deleteRequest(sentRequest.id);
                }}
              />
            </div>
            <hr />
          </>
        ) : (
          <>
            <div className=" flex mb-24 align-center justify-between">
              <h4 className="mr-24">Institute Code</h4>
              <input
                className="form-input"
                value={instituteCode}
                onChange={({ target }) => setInstituteCode(target.value)}
              />
            </div>
            <div className="flex mb-32">
              <Button
                className="fl-1"
                primary
                type="submit"
                label="Create Request"
                disabled={!instituteCodeUpdate}
              />
            </div>
            <hr />
          </>
        )}
      </form>
      <Link to="/privacy" className="link">
        Privacy Policy
      </Link>
    </div>
  );
};

export default TeacherOptions;
