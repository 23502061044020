import { useCallback, useEffect, useMemo, useState, useRef } from "react";
import { Outlet } from "react-router-dom";
import gamerize from "../../apis/gamerize";
import TabError from "../../components/TabError/TabError";
import Table from "../../components/Table/Table";
import fetchNext from "../../helper/fetchNext";
import Button from "../../components/Button/Button";
import Select from "react-select";
import "./AnalyticsAdventureCompleted.scss";
import { Link } from "react-router-dom";
import { CSVLink } from "react-csv";
import getAdventuresData from "../../helper/getAdventuresData";
import { set } from "lodash";

const ASCENDING_ORDER = "adventures_completed";
const DESCENDING_ORDER = "-adventures_completed";

const selectOptions = [
  { value: "all", label: "All" },
  { value: "spelling_the_word", label: "Spelling the Word" },
  { value: "writing_the_sentence", label: "Writing the Sentence" },
  { value: "saying_the_word", label: "Saying the Word" },
  { value: "say_the_sentence", label: "Saying the Sentence" },
];

const selectAssignedAdventure = [
  { value: "all", label: "All" },
  { value: "school_teacher", label: "School/Teacher" },
  { value: "others", label: "Others" },
];

const AdventuresCompleted = () => {
  const columns = useMemo(
    () => [
      {
        Header: "Username",
        accessor: "username",
        Cell: ({ row }) => (
          <Link to={`/analytics/student/${row.original.id}`}>{row.original.username}</Link>
        ),
      },
      {
        Header: "First Name",
        accessor: "first_name",
      },
      {
        Header: "Last Name",
        accessor: "last_name",
      },
      {
        Header: "Adventures Completed",
        accessor: "adventures_completed",
      },
    ],
    []
  );
  const csvLink = useRef();
  const [stats, setStats] = useState([]);
  const [next, setNext] = useState(null);
  const [error, setError] = useState("");
  const [currentAim, setCurrentAim] = useState("all");
  const [assignedAdventure, setAssignedAdventure] = useState("all");
  const [responseData, setResponseData] = useState("");
  const [order, setOrder] = useState(DESCENDING_ORDER);

  const filterLearningAims = ({ value }) => {
    switch (value) {
      case "all":
        return setCurrentAim("all");
      case "spelling_the_word":
        return setCurrentAim(1);
      case "writing_the_sentence":
        return setCurrentAim(2);
      case "saying_the_word":
        return setCurrentAim(3);
      case "say_the_sentence":
        return setCurrentAim(4);
      default:
        return setCurrentAim("all");
    }
  };

  const filterAssignedAdventure = ({ value }) => {
    switch (value) {
      case "all":
        return setAssignedAdventure("all");
      case "school_teacher":
        return setAssignedAdventure(1);
      case "others":
        return setAssignedAdventure(0);
      default:
        return setAssignedAdventure("all");
    }
  };

  const headers = [
    { label: "User Name", key: "username" },
    { label: "First Name", key: "first_name" },
    { label: "Last Name", key: "last_name" },
    { label: "Adventures Completed", key: "adventures_completed" },
  ];

  const URL = `/analytics/stats/adventures-completed-count/?`;
  const exportData = async (
    learning_aim = 1,
    assignedAdventure = 1,
    limit = 10
  ) => {
    try {
      if (learning_aim === "all" && assignedAdventure !== "all") {
        const response = await gamerize.get(
          `${URL}limit=${limit}&ordering=${order}&assigned=${assignedAdventure}`
        );
        setStats(response.data.results);
        setNext(response.data.next);
        setResponseData(response.data.results);
      } else if (assignedAdventure === "all" && learning_aim !== "all") {
        const response = await gamerize.get(
          `${URL}limit=${limit}&ordering=${order}&learning_aim=${learning_aim}`
        );
        setStats(response.data.results);
        setNext(response.data.next);
        setResponseData(response.data.results);
      } else if (assignedAdventure === "all" && learning_aim === "all") {
        const response = await gamerize.get(
          `${URL}limit=${limit}&ordering=${order}`
        );
        setStats(response.data.results);
        setNext(response.data.next);
        setResponseData(response.data.results);
      } else {
        const response = await gamerize.get(
          `${URL}limit=${limit}&ordering=${order}&learning_aim=${learning_aim}&assigned=${assignedAdventure}`
        );
        setStats(response.data.results);
        setNext(response.data.next);
        setResponseData(response.data.results);
      }

      csvLink.current.link.click();
    } catch (error) {
      setError({ message: "Error fetching data" });
    }
  };

  useEffect(() => {
    getAdventuresData(
      currentAim,
      assignedAdventure,
      20,
      order,
      setStats,
      setNext,
      setError
    );
  }, [getAdventuresData, currentAim, assignedAdventure, order]);

  return (
    <div className="adventure-completed-page fl-1 flex-column">
      <div className="flex justify-between">
        <div>
          <h1 className="mh-24 mt-24 mb-24">Adventures Completed</h1>
          <div className="ml-24 flex mt-8">
            <div>
              <h4>Learning Aim : </h4>
              <div className="mt-8 drop-down-size mb-24">
                <Select
                  options={selectOptions}
                  onChange={filterLearningAims}
                  defaultValue={selectOptions[0]}
                  className="bundle-list-filter"
                />
              </div>
            </div>
            <div className="ml-24">
              <h4 className="ml-4">Assigned Adventures : </h4>
              <div className="mt-8 drop-down-size mb-24">
                <Select
                  options={selectAssignedAdventure}
                  onChange={filterAssignedAdventure}
                  defaultValue={selectAssignedAdventure[0]}
                  className="bundle-list-filter"
                />
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="flex justify-end">
            <Button
              primary
              className=" mr-24 mt-24 order-select"
              label="Export Data"
              onClick={() => exportData(currentAim, assignedAdventure, 1000)}
            />
            <CSVLink
              data={responseData}
              filename="AdventureCompletedData.csv"
              className="hidden"
              ref={csvLink}
              headers={headers}
              target="_blank"
            />
          </div>
          <div className="flex">
            <h2 className="mt-32 mr-16 mb-8">Order : </h2>
            <Button
              primary
              className="mr-16 mt-24 mb-4 mb-24 order-select"
              label="Ascending"
              onClick={() => setOrder(ASCENDING_ORDER)}
            />
            <Button
              primary
              className=" mr-24 mt-24 mb-4 mb-24 order-select"
              label="Descending"
              onClick={() => setOrder(DESCENDING_ORDER)}
            />
          </div>
        </div>
      </div>
      {error ? (
        <TabError
          onClick={() => {
            setError("");
            getAdventuresData();
          }}
          message={error.message}
          label="Reload Stats"
        />
      ) : (
        <div id="scrollableContainer" className="list-container fl-1">
          <Table
            columns={columns}
            data={stats}
            update={async () => {
              try {
                const response = await fetchNext(URL, next);
                const { data, nextPage } = response;
                setStats((prevStats) => [...prevStats, ...data]);
                setNext(nextPage);
              } catch (error) {
                setError("Error fetching more data");
              }
            }}
            next={next}
          />
        </div>
      )}
      <Outlet/>
    </div>
  );
};
export default AdventuresCompleted;
