import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { signUp, signUpAdmin } from "../../actions/auth";
import Button from "../../components/Button/Button";
import "./SignUp.scss";
import BundleForm from "./Tabs/BundleForm";
import CodeForm from "./Tabs/CodeForm";
import InformationForm from "./Tabs/InformationForm";

const SignUp = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isAuthenticated = useSelector(({ auth }) => auth.isAuthenticated);

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/");
    }
  }, [isAuthenticated, navigate]);

  const [tab, setTab] = useState(1);

  const [information, setInformation] = useState("");

  const renderTab = (tab) => {
    switch (tab) {
      case 1:
        return (
          <InformationForm
            onSubmit={(info) => {
              setInformation(info);
              if (info.user_type === "teacher") setTab(2);
              else setTab(3);
            }}
          />
        );
      case 2:
        return (
          <CodeForm onSubmit={(code) => dispatch(signUp(information, code))} />
        );
      case 3:
        return (
          <BundleForm
            onSubmit={(bundle, schoolName) =>
              dispatch(signUpAdmin(information, bundle, schoolName))
            }
          />
        );
      default:
        return <InformationForm />;
    }
  };

  return (
    <div className="signup-page flex-column flex-center">
      <div className="signup-header flex justify-between align-center mb-24">
        <h1>Sign Up</h1>
        <Link to="/login" className="flex-column">
          <Button label="Login" />
        </Link>
      </div>
      {renderTab(tab)}
      <Link to="/privacy" style={{ color: "#0c59cf", marginTop: "24px" }}>
        Privacy Policy
      </Link>
    </div>
  );
};

export default SignUp;
