import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TabError from "../../components/TabError/TabError";
import { TailSpin as Loader } from "react-loader-spinner";
import { Table as AntTable } from "antd";
import { fetchJoinedStudents } from "../../actions/class-battle-history";

const selectStudentsJoined = ({ classBattle }) => classBattle.students_joined;
const selectPagination = ({ classBattle }) =>
  classBattle.students_joined_pagination;
const selectLoading = ({ classBattle }) => classBattle.students_loader;
const selectError = ({ classBattle }) => classBattle.error;

const StudentsTable = ({ selectedBattle, adventureType }) => {
  const dispatch = useDispatch();

  const fetchedStudentsJoined = useSelector(selectStudentsJoined);
  const pagination = useSelector(selectPagination);
  const loading = useSelector(selectLoading);
  const error = useSelector(selectError);

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const offset = (page - 1) * pageSize;
  const [studentsJoined, setStudentsJoined] = useState([]);

  const columns = [
    {
      title: "Name",
      dataIndex: "student",
      key: "name",
      render: (value) => <>{value.name}</>,
    },
    {
      title: "User Name",
      dataIndex: "student",
      key: "username",
      render: (value) => <>{value.username}</>,
    },
    {
      title: "Correct Answers",
      dataIndex: "result",
      key: "correct_answers",
      render: (value) => <>{value.correct_answers}</>,
    },
    {
      title: "Incorrect Answers",
      dataIndex: "result",
      key: "incorrect_answers",
      render: (value) => <>{value.incorrect_answers}</>,
    },
  ];

  // if (adventureType=="review_battle") {
  //   columns.push({
  //     title: "Adventure",
  //     dataIndex: "review_adventure",
  //     key: "review_adventure",
  //     render: (value) => value ? <>{value.name}</> : <span>N/A</span>,
  //   });
  // }

  useEffect(() => {
    dispatch(fetchJoinedStudents(selectedBattle.id, pageSize, offset));
  }, [dispatch, offset, pageSize]);

  useEffect(() => {
    setStudentsJoined(Object.values(fetchedStudentsJoined));
  }, [fetchedStudentsJoined]);

  const handlePagination = () => {
    return {
      current: page,
      pageSize: pageSize,
      total: pagination.count,
      onChange: (studentsJoinedPage, studentsJoinedPageSize) => {
        setPage(studentsJoinedPage);
        setPageSize(studentsJoinedPageSize);
      },
    };
  };

  const renderStudentsList = (studentsJoined) => (
    <AntTable
      title={null}
      columns={columns}
      dataSource={studentsJoined}
      showHeader
      size="small"
      pagination={handlePagination()}
      bordered={true}
    />
  );

  return (
    <>
      {loading ? (
        <div className="flex flex-center fl-1">
          <Loader type="Puff" color="#354AC9" height={50} width={50} />
        </div>
      ) : error ? (
        <TabError
          onClick={() =>
            dispatch(fetchJoinedStudents(selectedBattle.id, pageSize, offset))
          }
          message={error.message}
          label="Reload Students Joined"
        />
      ) : studentsJoined.length > 0 ? (
        <>
          <h2
            style={{
              marginTop: "20px",
              paddingBottom: "10px",
              textAlign: "center",
            }}
          >
            Students Joined
          </h2>
          {renderStudentsList(studentsJoined)}
        </>
      ) : (
        <h3 style={{ marginTop: "20px", textAlign: "center" }}>
          No Students Joined
        </h3>
      )}
    </>
  );
};

export default StudentsTable;
