import gamerize from "../apis/gamerize";
import {
  FETCH_STUDENT,
  FETCH_STUDENTS,
  FETCH_STUDENTS_ERROR,
  FETCH_STUDENTS_SUCCESS,
  FETCH_STUDENT_ERROR,
  FETCH_STUDENT_SUCCESS,
  FETCH_NEXT_STUDENTS_SUCCESS,
  DELETE_STUDENT,
  DELETE_STUDENT_SUCCESS,
  DELETE_STUDENT_ERROR,
} from "./types";

export const fetchStudents =
  (search = "", limit = 10, offset = 0) =>
  async (dispatch, getState) => {
    dispatch({ type: FETCH_STUDENTS });
    try {
      const usertype = getState().auth.user_type;
      const response = await gamerize.get(
        `/users/${usertype}s/students/?search=${search}&offset=${offset}&limit=${limit}`
      );
      dispatch({
        type: FETCH_STUDENTS_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: FETCH_STUDENTS_ERROR,
        payload: {
          message: "Error fetching students.",
        },
      });
    }
  };

export const fetchNextStudents = () => async (dispatch, getState) => {
  const { next } = getState().students.pagination;
  if (!next) return;
  const query = next.split("?")[1] || "";
  try {
    const usertype = getState().auth.user_type;
    const response = await gamerize.get(
      `/users/${usertype}s/students/?${query}`
    );
    dispatch({
      type: FETCH_NEXT_STUDENTS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_STUDENTS_ERROR,
      payload: {
        message: "Error fetching adventures.",
      },
    });
  }
};

export const fetchStudent = (id) => async (dispatch, getState) => {
  dispatch({ type: FETCH_STUDENT });
  try {
    const usertype = getState().auth.user_type;
    const response = await gamerize.get(`/users/${usertype}s/students/${id}/`);
    dispatch({
      type: FETCH_STUDENT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_STUDENT_ERROR,
      payload: {
        message: "Error fetching student.",
      },
    });
  }
};

export const removeStudent = (id, includeClassCheck=false) => async (dispatch) => {
  dispatch({ type: DELETE_STUDENT });
  try {
    await gamerize.post(`users/owners/students/kick_students/?including_classes=${includeClassCheck}`, { ids: id });
    dispatch({
      type: DELETE_STUDENT_SUCCESS,
      payload: id,
    });
  } catch (error) {
    dispatch({
      type: DELETE_STUDENT_ERROR,
      payload: {
        message: "There was an error removing the student.",
      },
    });
  }
};
