import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, Link } from 'react-router-dom';
import {TailSpin as Loader} from 'react-loader-spinner';
import _ from 'lodash';
import moment from "moment";

import { Table as AntTable, Dropdown, Menu, Input, Modal } from 'antd';
import { DownOutlined, CloseOutlined } from '@ant-design/icons';
import { fetchClass, unassignAdventure } from '../../actions/classes';
import { stringMatch } from '../../utils';
import TabError from '../../components/TabError/TabError';
import Button from '../../components/Button/Button';

import { ConfirmationDialog, ErrorDialog } from '../../components/ConfirmationDialog';

const { Search } = Input;
const selectClasses = ({ classes }) => classes.data;
const selectClassesLoading = ({ classes }) => classes.loading;
const selectClassesError = ({ classes }) => classes.error;

const ViewClassAdventures = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const classes = useSelector(selectClasses);
  const classesLoading = useSelector(selectClassesLoading);
  const classesError = useSelector(selectClassesError);

  const [fetchedAdventures, setFetchedAdventures] = useState([]);
  const [adventures, setAdventures] = useState([]);
  const [search, setSearch] = useState('');
  const [multiDelete, setMultiDelete] = useState(false);
  const [selected, setSelected] = useState([]);
  const selectedKeys = Object.keys(selected);

  const columns = [
    {
      title: 'Adventure Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: "Start Date",
      dataIndex: "start_date",
      defaultSortOrder: "descend",
      render: (value) => moment(value).format("DD MMM, YYYY"),
    },
    {
      title: "End Date",
      dataIndex: "end_date",
      defaultSortOrder: "descend",
      render: (value) => moment(value).format("DD MMM, YYYY"),
    },
    {
      title: 'Learning Aim',
      dataIndex: 'learning_aim',
      render: value =>
        value === 'spelling_the_word' ? (
          'Spelling the Word'
        ) : value === 'writing_the_sentence' ? (
          'Write the Sentence'
        ) : value === 'saying_the_word' ? (
          'Saying the Word'
        ) : value === 'say_the_sentence' ? (
          'Say the Sentence'
        ) : (
          <CloseOutlined />
        ),
    },
    {
      title: 'Action',
      key: 'action',
      width: 160,
      fixed: 'right',
      render: value => (
        <span className="table-action-buttons">
          <Link
            className="text-button mr-10"
            to={`/adventures/list/adventure/view/${value.id}`}
          >
            View
          </Link>
          <Dropdown
            trigger={['click']}
            className="table-more-button"
            overlay={
              <Menu className="actions-menu">
                <Menu.Item
                  className="negative delete"
                  onClick={() => handleDelete(value)}
                >
                  Delete
                </Menu.Item>
              </Menu>
            }
          >
            <span className="text-button ant-dropdown-link">
              More <DownOutlined />
            </span>
          </Dropdown>
        </span>
      ),
    },
  ];

  useEffect(() => {
    dispatch(fetchClass(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (classesLoading === false && !_.isEmpty(classes) && !classes[id]) {
      navigate('/classes/my-classes');
    }
  }, [classes, navigate, id, classesLoading]);

  const onSearch = value => {
    setAdventures(fetchedAdventures.filter(({ name }) => stringMatch(name, search)));
  };

  useEffect(() => {
    if (classes[id]) {
      setFetchedAdventures(Object.values(classes[id].adventures));
    }
  }, [classes, id]);

  useEffect(() => {
    setAdventures(fetchedAdventures.filter(({ name }) => stringMatch(name, search)));
  }, [fetchedAdventures]);

  const handleDelete = value => {
    ConfirmationDialog({
      title: `Are you sure you want to delete "${value.name}"?`,
      onConfirm: () => {
        dispatch(unassignAdventure(id, [value.id]));
      },
      yesText: 'Yes',
    });
  };

  const handleMultiDelete = () => {
    if (selectedKeys.length > 20) {
      ErrorDialog({
        title: 'You cannot select more than 20 adventues at a same time',
      });
    } else {
      ConfirmationDialog({
        title: `Are you sure you want to delete Selected Adventures?`,
        onConfirm: () => {
          dispatch(unassignAdventure(id, selectedKeys));
          setMultiDelete(false);
        },
        yesText: 'Yes',
      });
    }
  };

  const multiDeleteAdventureController = value => {
    setMultiDelete(!multiDelete);
    setSelected([]);
  };

  const renderAdventureList = () =>
    adventures.length > 0 ? (
      <>
        {multiDelete ? (
          <AntTable
            title={null}
            columns={columns}
            dataSource={adventures.map(obj => ({ ...obj, key: obj.id }))}
            showHeader
            size="default"
            bordered={false}
            rowSelection={{
              selectedRowKeys: selected ? Object.keys(selected).map(a => +a) : [],
              onChange: (selectedRowKeys, selectedRows) => {
                const rows = {};
                selectedRows.forEach(each => {
                  rows[each.id] = each;
                });
                setSelected(rows);
              },
              columnTitle: <div />,
            }}
          />
        ) : (
          <AntTable
            title={null}
            columns={columns}
            dataSource={adventures}
            showHeader
            size="default"
            bordered={false}
          />
        )}
      </>
    ) : (
      <div className="ph-24">No adventures assigned to this class.</div>
    );

  return (
    <div className="ViewClassAdventures-page flex-column fl-1 pt-24">
      {classesLoading ? (
        <div className="flex flex-center fl-1">
          <Loader type="Puff" color="#354AC9" height={50} width={50} />
        </div>
      ) : classesError ? (
        <TabError
          onClick={() => dispatch(fetchClass(id))}
          message={classesError.message}
          label="Reload Tab"
        />
      ) : (
        <>
          <div className="flex align-center justify-between mb-16 mh-24">
            <h2>{`${classes[id]?.name}${
              classes[id]?.is_default_class ? ' (Default Class)' : ''
            }`}</h2>

            <br />
          </div>
          <div className="flex align-center justify-between mb-16 mh-24">
            <Search
              placeholder="Search Adventures"
              enterButton="Search"
              size="large"
              onSearch={onSearch}
              value={search}
              onChange={e => setSearch(e.target.value)}
            />
          </div>

          {classes[id]?.teacher && (
            <div className="mb-16 mh-24">
              {classes[id].teacher ? (
                <h3>
                  Teacher: {classes[id].teacher.first_name || ''}{' '}
                  {classes[id].teacher.last_name || ''}
                </h3>
              ) : (
                <h3>No teacher</h3>
              )}
            </div>
          )}
          <div className="flex justify-end align-center ph-24 pv-16 mg-top">
            <Button
              label="Back to Class"
              primary
              onClick={() => {
                navigate(`/classes/my-classes/class/view/${id}`);
              }}
              className="mr-16"
            />
            {multiDelete && (
              <Button
                label="Delete"
                className="mr-24"
                disabled={selected.length === 0}
                type="primary"
                danger
                onClick={() => handleMultiDelete()}
              />
            )}
            <Button
              label={multiDelete ? 'Cancel' : 'Remove Multiple Adventures'}
              primary
              onClick={() => multiDeleteAdventureController()}
              className="mr-16 flex "
            />
          </div>
          <div className="fl-1 list-container">{renderAdventureList()}</div>
        </>
      )}
    </div>
  );
};

export default ViewClassAdventures;
