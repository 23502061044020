const prod = {
  API_URL: "https://api.gamerize-dictionary.site/api/v1",
  ADMIN_URL: "https://dashboard.gamerize-dictionary.site",
  STUDENT_URL: "https://student.gamerize-dictionary.site",
};
const dev = {
  API_URL:
  // "https://api.gamerize-dictionary.site/api/v1",
   "https://stagingapi.gamerize-dictionary.site/api/v1",
  // "http://localhost:1337/api/v1",
  ADMIN_URL: "http://localhost:3000",
  STUDENT_URL: "http://localhost:3001",
  STRIPE_KEY: "pk_test_TYooMQauvdEDq54NiTphI7jx",
};

export const config =
  process.env.REACT_APP_HOST_ENV === "development" ? dev : prod;

export const aims = [
  { value: null, label: "No aim" },
  { value: "spelling_the_word", label: "Spell the word" },
  { value: "writing_the_sentence", label: "Write the sentence" },
  { value: "saying_the_word", label: "Say the word" },
  { value: "say_the_sentence", label: "Say the sentence" },
];

export const apiV1 = "api/v1"

export const maxNumberOfStudentsInClassBattle = 40;