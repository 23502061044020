import gamerize from "../apis/gamerize";
const URL = `/analytics/stats/adventures-completed-count/?`;

const getAdventuresData = async (
  learning_aim = 1,
  assignedAdventure = 1,
  limit = 10,
  order,
  setStats,
  setNext,
  setError
) => {
  try {
    if (learning_aim === "all" && assignedAdventure !== "all") {
      const response = await gamerize.get(
        `${URL}limit=${limit}&ordering=${order}&assigned=${assignedAdventure}`
      );
      setStats(response.data.results);
      setNext(response.data.next);
    } else if (assignedAdventure === "all" && learning_aim !== "all") {
      const response = await gamerize.get(
        `${URL}limit=${limit}&ordering=${order}&learning_aim=${learning_aim}`
      );
      setStats(response.data.results);
      setNext(response.data.next);
    } else if (assignedAdventure === "all" && learning_aim === "all") {
      const response = await gamerize.get(
        `${URL}limit=${limit}&ordering=${order}`
      );
      setStats(response.data.results);
      setNext(response.data.next);
    } else {
      const response = await gamerize.get(
        `${URL}limit=${limit}&ordering=${order}&learning_aim=${learning_aim}&assigned=${assignedAdventure}`
      );
      setStats(response.data.results);
      setNext(response.data.next);
    }
  } catch (error) {
    setError({ message: "Error fetching data" });
  }
};

export default getAdventuresData;
