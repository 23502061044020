import {
  Document,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import appLogo from "../../images/logo.png";
import Util from "../../components/util/index";
import bankDetails from "../../images/BankDetails.png";
import invoiceHeaders from "../../images/invoice-headers.png";
import creationDate from "../../images/creation-date.png";

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    alignItems: "center",
    padding: 36,
    paddingBottom: 0,
  },
  section: {
    flex: 1,
    marginBottom: 36,
  },
  title: {
    fontSize: 12,
  },
  subTitle: {
    fontSize: 12,
    color: "#6d7075",
  },
  headers: {
    fontSize: 12,
    color: "#6d7075",
    marginBottom: 20,
  },
  appDetails: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 16,
  },
  appName: {
    fontSize: 24,
    alignItems: "center",
  },
  appLogo: {
    width: "30pt",
    height: "30pt",
    marginRight: 16,
  },
  bankDetails: {
    width: "200pt",
    height: "230pt",
  },
  invoiceHeaders: {
    marginLeft: 32,
    width: "450pt",
    height: "30pt",
  },
  letterContent: {
    flex: 1,
  },
  instituteDetails: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    marginBottom: 16,
  },
  institute: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  instructions: {
    padding: 12,
  },
  total: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    fontSize: 12,
    marginTop: 8,
  },
  balance: {
    marginLeft: 24,
    marginRight: 16,
  },
  subTitleMessage: {
    marginTop: 8,
    marginBottom: 8,
    fontSize: 12,
    color: "#6d7075",
  },
  detailHeader: {
    marginTop: 8,
    color: "#6d7075",
    fontSize: 12,
  },
  dateTop: {
    fontSize: 8,
  },
  statusMessage: {
    marginTop: 8,
    fontSize: 12,
    color: "#6d7075",
  },
  priceDetail: {
    fontSize: 12,
  },
});

const InvoiceDocument = ({ invoiceDetail }) => {
  const pricePerSeat =
    (invoiceDetail?.payment / invoiceDetail?.seats) * invoiceDetail?.months;
  const taxAmount = (invoiceDetail?.payment / 10) >> 0;
  const total = invoiceDetail?.payment * 1.1;
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        {
          <View style={styles.section}>
            <View style={styles.total}>
              <Text style={styles.dateTop}>
                {Util.getLocalDateTime(invoiceDetail?.created_date)}
              </Text>
            </View>
            <View style={styles.appDetails}>
              <Image src={appLogo} style={styles.appLogo} />
              <Text style={styles.appName}>School Invoice</Text>
            </View>
            <View>
              <Text style={styles.subTitleMessage}>
                <Image src={creationDate} style={styles.appLogo} /> :{" "}
                {Util.getLocalDate(invoiceDetail?.created_date)}
              </Text>
            </View>
            <View style={styles.letterContent}>
              <Text style={styles.subTitle}>
                Dear {invoiceDetail?.school_name},
              </Text>
              <Text style={styles.subTitle}>
                Your package details and price are as following:
              </Text>
              <Text style={styles.subTitleMessage}>
                Thank you for ordering from Gamerize Dictionary
              </Text>
              <Image src={invoiceHeaders} style={styles.invoiceHeaders} />

              <View style={styles.instructions}>
                <View style={styles.instituteDetails}>
                  <View style={styles.institute}>
                    <Text style={styles.title}>{invoiceDetail?.type}</Text>
                  </View>
                  <View style={styles.institute}>
                    <Text style={styles.title}>{invoiceDetail?.seats}</Text>
                  </View>
                  <View style={styles.institute}>
                    <Text style={styles.title}>{invoiceDetail?.months}</Text>
                  </View>
                  <View style={styles.institute}>
                    <Text style={styles.title}>{pricePerSeat}</Text>
                  </View>
                  {invoiceDetail?.requestStatus === "Approved" ? (
                    <>
                      <View style={styles.institute}>
                        <Text style={styles.title}>
                          {Util.getLocalDate(invoiceDetail?.start_date)}
                        </Text>
                      </View>
                      <View style={styles.institute}>
                        <Text style={styles.title}>
                          {Util.getLocalDate(invoiceDetail?.end_date)}
                        </Text>
                      </View>
                    </>
                  ) : (
                    <>
                      <View style={styles.institute}>
                        <Text style={styles.headers}>Total Cost</Text>
                        <Text style={styles.title}>
                          ¥{invoiceDetail?.payment >> 0}
                        </Text>
                      </View>
                    </>
                  )}
                </View>
                <View>
                  <Text style={{ color: "red" }}>
                    --------------------------------------------------------------------------------------
                  </Text>
                </View>
                {invoiceDetail?.requestStatus === "Pending" ? (
                  <Text style={styles.statusMessage}>
                    Request Status : Not Paid Yet
                  </Text>
                ) : invoiceDetail?.requestStatus === "Funds Transferred" ? (
                  <Text style={styles.statusMessage}>
                    Request Status : Funds transferred on{" "}
                    {Util.getLocalDate(invoiceDetail?.funds_transferred_date)}{" "}
                    and its not verified yet.
                  </Text>
                ) : invoiceDetail?.requestStatus === "Approved" ? (
                  invoiceDetail?.funds_transferred_date !== null ? (
                    <Text style={styles.statusMessage}>
                      Request Status : Paid on{" "}
                      {Util.getLocalDate(invoiceDetail?.funds_transferred_date)}{" "}
                      and approved on{" "}
                      {Util.getLocalDate(invoiceDetail?.approval_date)}
                    </Text>
                  ) : (
                    <Text style={styles.statusMessage}>
                      Request Status : Approved on{" "}
                      {Util.getLocalDate(invoiceDetail?.approval_date)}
                    </Text>
                  )
                ) : invoiceDetail?.requestStatus === "Rejected" ? (
                  <Text style={styles.statusMessage}>
                    Request Status : Verification Failed on{" "}
                    {Util.getLocalDate(invoiceDetail?.approval_date)}
                  </Text>
                ) : (
                  <></>
                )}
              </View>
              <View style={styles.total}>
                <Text>
                  {invoiceDetail?.seats + " seats"}
                  {" x   " + invoiceDetail?.months + " "} month{"  "} x {"  "}@
                  {pricePerSeat}yen =
                </Text>
                <Text style={styles.balance}>
                  ¥{invoiceDetail?.payment >> 0}
                </Text>
              </View>
              <View style={styles.total}>
                <Text>Consumption Tax (10%) =</Text>
                <Text style={styles.balance}>¥{taxAmount}</Text>
              </View>
              <View style={styles.total}>
                <Text>Total =</Text>
                <Text style={styles.balance}>¥{total >> 0}</Text>
              </View>
              <View>
                <Text>Bank Details: </Text>
                <Image src={bankDetails} style={styles.bankDetails} />
              </View>
            </View>
          </View>
        }
      </Page>
    </Document>
  );
};

export default InvoiceDocument;
