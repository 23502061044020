export const NavBarLinkSubscribed = [
  { title: "Overview", link: "/" },
  { title: "Teachers", link: "/teachers/dashboard" },
  { title: "Students", link: "/students/dashboard" },
  { title: "Classes", link: "/classes/dashboard" },
  { title: "Adventures", link: "/adventures/dashboard" },
  { title: "Analytics", link: "/analytics/dashboard" },
];

export const NavBarLinkUnsubscribed = [
  { title: "Overview", link: "/" },
  { title: "Teachers", link: "/teachers/dashboard" },
  { title: "Students", link: "/students/dashboard" },
];

export const TeacherNavBarLink = [
  { title: "Overview", link: "/" },
  { title: "Classes", link: "/classes/dashboard" },
  { title: "Adventures", link: "/adventures/dashboard" },
  { title: "Analytics", link: "/analytics/dashboard" },
]