import "./AimListItem.scss";
import classNames from "classnames";

import book from "../../images/book.png";
import pencil from "../../images/write.png";
import ear from "../../images/ear.png";
import air from "../../images/air.png";

const AimListItem = ({ aim, selected, onClick }) => {
  const classes = classNames("aim-list-item", selected && "selected");

  const renderHeader = () => {
    switch (aim.label) {
      case "Read & Write Words":
        return (
          <div className="flex-column flex-center">
            <div className="flex align-center mb-16">
              <img src={book} alt="read" className="aim-icon" />
              <h2 className="mh-16">&</h2>
              <img src={pencil} alt="write" className="aim-icon" />
            </div>
            <h2 className="mb-16">Words</h2>
          </div>
        );
      case "Understand & Speak Words":
        return (
          <div className="flex-column flex-center">
            <div className="flex align-center mb-16">
              <img src={ear} alt="understand" className="aim-icon" />
              <h2 className="mh-16">&</h2>
              <img src={air} alt="speak" className="aim-icon" />
            </div>
            <h2 className="mb-16">Words</h2>
          </div>
        );
      case "Read & Write Sentences":
        return (
          <div className="flex-column flex-center">
            <div className="flex align-center mb-16">
              <img src={book} alt="read" className="aim-icon" />
              <h2 className="mh-16">&</h2>
              <img src={pencil} alt="write" className="aim-icon" />
            </div>
            <h2 className="mb-16">Sentences</h2>
          </div>
        );
      case "Understand & Speak Sentences":
        return (
          <div className="flex-column flex-center">
            <div className="flex align-center mb-16">
              <img src={ear} alt="understand" className="aim-icon" />
              <h2 className="mh-16">&</h2>
              <img src={air} alt="speak" className="aim-icon" />
            </div>
            <h2 className="mb-16">Sentences</h2>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className={classes} onClick={onClick}>
      {renderHeader()}
      <p>{aim.description}</p>
    </div>
  );
};

export default AimListItem;
