import { Fragment, useCallback, useEffect, useState } from "react";
import gamerize from "../../apis/gamerize";
import TabError from "../../components/TabError/TabError";
import BankRequestListItem from "./BankRequestListItem/BankRequestListItem";
import {TailSpin as Loader} from "react-loader-spinner";
import Button from "../../components/Button/Button";
import { useNavigate } from "react-router-dom";
import { setPaymentOption } from "../../actions/subscriptions";
import { useDispatch, useSelector } from "react-redux";
import "./BankTransfer.scss";

const BankTransfer = () => {
  const [fetchedRequests, setFetchedRequests] = useState([]);
  const [paymentRequests, setPaymentRequests] = useState([]);
  const [loadingRequests, setLoadingRequests] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const fetchBankRequests = useCallback(async () => {
    setLoadingRequests(true);
    try {
      const res = await gamerize.get("users/bank_transfer/requests/");
      const list = res.data.results.map(
        ({
          id,
          user,
          status,
          subscription_type,
          amount,
          seats,
          months,
          transaction_proof,
          created_date,
          school_name,
          start_date,
          validity_date,
          funds_transferred_date,
          approval_date,
        }) => ({
          ...user,
          requestID: id,
          requestStatus: status,
          type: subscription_type,
          payment: amount,
          seats: seats,
          months: months,
          transaction_proof: transaction_proof,
          created_date: created_date,
          school_name: school_name,
          start_date: start_date,
          end_date: validity_date,
          funds_transferred_date: funds_transferred_date,
          approval_date: approval_date,
        })
      );
      setFetchedRequests(list);
      setLoadingRequests(false);
    } catch (error) {
      setError("There was an error fetching requests");
      setLoadingRequests(false);
    }
  }, []);

  useEffect(() => {
    fetchBankRequests();
  }, [fetchBankRequests]);

  useEffect(() => {
    setPaymentRequests(Object.values(fetchedRequests));
  }, [fetchedRequests]);

  const onRemoveRequest = async (id) => {
    await gamerize.delete(`/users/bank_transfer/requests/${id}/`);
    fetchBankRequests();
  };

  const dispatch = useDispatch();

  const newRequestHandler = async () => {
    dispatch(setPaymentOption("bank"));
    navigate("/subscriptions/dashboard/");
  };
  const renderRequestList = () =>
    paymentRequests.length > 0 ? (
      <ul className="ph-24">
        {paymentRequests.map((paymentRequest) => (
          <BankRequestListItem
            key={paymentRequest.id}
            paymentRequest={paymentRequest}
            onIgnore={() => onRemoveRequest(paymentRequest.requestID)}
          />
        ))}
      </ul>
    ) : (
      <div className="p-24">
        <h1>No Pending Requests</h1>
      </div>
    );

  return (
    <div className="flex-column fl-1 pt-24">
      {loadingRequests ? (
        <div className="flex flex-center fl-1">
          <Loader type="Puff" color="#354AC9" height={50} width={50} />
        </div>
      ) : error ? (
        <TabError
          onClick={() => fetchBankRequests()}
          message={error}
          label="Reload requests"
        />
      ) : (
        <Fragment>
          <div className="flex align-center justify-between mb-24 mh-24">
            <h2>Bank Transfer Requests</h2>
            <Button
              label="Make New Request"
              primary
              type="button"
              className="mb-16"
              onClick={newRequestHandler}
            />
          </div>
          <div className="scroll-vertical fl-1">{renderRequestList()}</div>
        </Fragment>
      )}
    </div>
  );
};

export default BankTransfer;
