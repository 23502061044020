import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import gamerize from "../../apis/gamerize";
import { useDispatch, useSelector } from "react-redux";
import { fetchStudents } from "../../actions/student";
import getSubscriptionDetail from "../../helper/getSubscriptionDetail";
import Button from "../../components/Button/Button";
import { Popover } from "antd";
import Modal from "../../components/Modal/Modal";
import {TailSpin as Loader} from "react-loader-spinner";
import {
  ConfirmationDialog,
  ErrorDialog,
  SuccessDialog,
} from "../../components/ConfirmationDialog";
import Util from "../../components/util/index";

import "./SubscriptionDetail.scss";

const selectStudentsPagination = ({ students }) => students.pagination;

const SubscriptionDetail = () => {
  const students = useSelector(selectStudentsPagination);

  const [subscriptionDetail, setSubscriptionDetail] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [showPopup, setShowPopup] = useState(false);
  const [seats, setSeats] = useState(-1);
  const [subsPackage, setSubsPackage] = useState(1);
  const [subsDifferenceCharges, setSubsDifferenceCharges] = useState(0);

  const dispatch = useDispatch();

  const getSubscription = async () => {
    setError("");
    try {
      setLoading(true);
      const response = await getSubscriptionDetail();
      setSubscriptionDetail(response);
      setLoading(false);
    } catch (error) {
      ErrorDialog({
        title: "Error Fetching Subscriptions Detail",
      });
      setError("Error Fetching Subscriptions Detail");
    }
  };

  useEffect(() => {
    getSubscription();
  }, []);
  const subsPackages = parseInt(subsPackage);
  const seatNumbers = parseInt(seats, 0);

  useEffect(() => {
    const getTotal = async () => {
      if (!window.navigator.onLine && seats >= 0) {
        ErrorDialog({
          title: "You're offline right now. Check your connection.",
        });
      } else {
        dispatch(fetchStudents());
        if (seats > students.count) {
          try {
            const response = await gamerize.post(
              `/users/stripe/subscriptions/modification_charges/`,
              { package: subsPackages, seats: seatNumbers }
            );
            let differenceCharges = response?.data?.total ?? 0;
            try {
              let lineOne = response?.data?.lines.data[0].amount;
              let lineTwo = response?.data?.lines.data[1].amount;
              let difference = lineOne + lineTwo;
              differenceCharges = difference;
            } catch (err) {}
            setSubsDifferenceCharges(differenceCharges ?? 0);
          } catch (e) {
            ErrorDialog({
              title: e.response.data.seats,
            });
          }
        } else if (seats >= 0) {
          ErrorDialog({
            title: `Please select seats more than ${students.count}!`,
          });
        }
      }
    };
    getTotal();
  }, [seats, subsPackage]);

  const handleModifyChanges = async (e) => {
    if (!window.navigator.onLine) {
      ErrorDialog({
        title: "You're offline right now. Check your connection.",
      });
    } else {
      try {
        setLoading(true);
        setShowPopup(false);
        await gamerize.post(`users/stripe/subscriptions/modify_subscription/`, {
          package: subsPackages,
          seats: seatNumbers,
        });
        setLoading(false);
        setTimeout(() => window.location.reload(), 500);
      } catch (error) {
        ErrorDialog({
          title: error.response.data,
        });
      }
    }
  };

  useEffect(() => {
    if (subsDifferenceCharges !== 0) {
      ConfirmationDialog({
        title: `${
          subsDifferenceCharges > 0
            ? `That amount will be deducted from your account = ${subsDifferenceCharges}¥. Are you Sure?`
            : ` You will get that amount back = ${subsDifferenceCharges}¥. Are you Sure?`
        }`,
        onConfirm: () => {
          handleModifyChanges();
        },
        yesText: "Sure",
      });
    }
  }, [subsDifferenceCharges]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!window.navigator.onLine) {
      ErrorDialog({
        title: "You're offline right now. Check your connection.",
      });
    } else {
      try {
        const response = await gamerize.post(
          "/users/stripe/subscriptions/customer_portal/"
        );
        const url = response.data.url;
        window.location.replace(url);
      } catch (error) {
        ErrorDialog({
          title: error.response.data,
        });
      }
    }
  };
  const handleBuySubscription = async (e) => {
    ConfirmationDialog({
      title:
        "Your trial will expire once you opt in for a subscription. Are you sure you want to continue?",
      onConfirm: () => {
        navigate(`/subscriptions/dashboard`);
      },
      yesText: "Continue",
    });
  };

  const syncStripeMessage = (
    <div style={{ width: "300px" }}>
      <p>Press this button to refresh your subscription details.</p>
    </div>
  );

  const modifyStripeMessage = (
    <div style={{ width: "300px" }}>
      <p>Change seats or the package(pro/basic).</p>
    </div>
  );

  const stripeDashboardMessage = (
    <div style={{ width: "300px" }}>
      <p>
        Manage your credit card. Add or remove cards or view payment history.
      </p>
    </div>
  );

  const syncStripeSubscirption = async (e) => {
    if (!window.navigator.onLine) {
      ErrorDialog({
        title: "You're offline right now. Check your connection.",
      });
    } else {
      try {
        const response = await gamerize.get(
          "/users/stripe/subscriptions/get_subscription_detail/"
        );
        if (response.status === 200) {
          SuccessDialog({
            title: "Successfully Synced with Stripe!",
          });
          getSubscription();
        }
      } catch (error) {
        ErrorDialog({
          title: "unable to sync to stripe",
        });
      }
    }
  };

  return (
    <div className="pricing-table-page fl-1 p-24">
      <h1 className="mh-24 mt-24 mb-24">Subscription Plan</h1>
      <div className="mh-24 pt-24 columns">
        <ul className="price-card">
          <li className="header p-20">Subscription Detail</li>
          <li className="price p-20">
            <p>
              {error !== "" ? <p className="error mb-16">{error}</p> : <p></p>}
              Subscription :
              {subscriptionDetail.is_valid ? " Valid" : " Expired"}
            </p>
          </li>
          <li className="flex flex-center p-24">
            <p>
              {error !== "" ? <p className="error mb-16">{error}</p> : <p></p>}
              Payment Type:
              {subscriptionDetail.payment_type === 3 ? (
                " Trial"
              ) : subscriptionDetail.payment_type === 2 ? (
                " Bank"
              ) : subscriptionDetail.payment_type === 1 ? (
                " Stripe"
              ) : (
                <></>
              )}
            </p>
          </li>
          <li className="flex flex-center p-24">
            <p>
              {subscriptionDetail.subscription_type === 2 ? "Pro" : "Basic"}{" "}
              Plan
            </p>
          </li>
          <li className="flex flex-center p-24">
            <p>Seats: {subscriptionDetail.seats}</p>
          </li>
          <li className="flex flex-center p-24">
            <p>Seats used: {subscriptionDetail.seats_used}</p>
          </li>
          <li className="flex flex-center p-24">
            <p>
              Start Date: {Util.getLocalDate(subscriptionDetail.start_date)}
            </p>
          </li>
          <li className="flex flex-center p-24">
            <p>
              Valid till: {Util.getLocalDate(subscriptionDetail.validity_date)}
            </p>
          </li>
        </ul>
      </div>
      {loading && (
        <div style={{ alignSelf: "center" }}>
          <Loader type="Puff" color="#354AC9" height={50} width={50} />
        </div>
      )}
      {!subscriptionDetail.is_valid && subscriptionDetail.payment_type === 2 ? (
        <>
          <div className="columns pt-24">
            <Button
              label="Request Subscription"
              primary
              onClick={() => navigate(`/subscriptions/bank-transfers`)}
            />
          </div>
        </>
      ) : subscriptionDetail.payment_type === 3 &&
        subscriptionDetail.is_valid === true ? (
        <>
          <div className="columns pt-24">
            <div className="grey">
              <Button
                label="Buy Subscription"
                primary
                onClick={handleBuySubscription}
              />
            </div>
          </div>
        </>
      ) : subscriptionDetail.payment_type === 3 &&
        subscriptionDetail.is_valid === false ? (
        <>
          <div className="columns pt-24">
            <div className="grey">
              <Button
                label="Buy Subscription"
                primary
                onClick={() => navigate(`/subscriptions/dashboard`)}
              />
            </div>
          </div>
        </>
      ) : (
        subscriptionDetail.payment_type === 1 && (
          <>
            <form onSubmit={handleSubmit}>
              <div className="columns pt-24">
                <div className="grey">
                  <h3 className="p-8">
                    Access stipe credit card managment page
                  </h3>
                  <Popover content={stripeDashboardMessage}>
                    <Button label="Stripe Dashboard" primary type="submit" />
                  </Popover>
                </div>
              </div>
            </form>
            <div className="columns pt-24">
              <h3 className="p-8">Fetch latest subscription information</h3>
              <Popover content={syncStripeMessage}>
                <Button
                  label="Sync Stripe"
                  primary
                  onClick={syncStripeSubscirption}
                />
              </Popover>
            </div>
            <Modal
              modalIsOpen={showPopup}
              // onYesClick={handleModifySubscription}
              onYesClick={() => {
                setShowPopup(true);
              }}
              onCancelClick={() => {
                setShowPopup(false);
              }}
              modifySubscription={true}
              subsSeats={(subsSeats) => setSeats(subsSeats)}
              subsPackage={(subsPackage) => setSubsPackage(subsPackage)}
              //totalPrice={subsDifferenceCharges}
            />
            {subscriptionDetail.start_date === null &&
            subscriptionDetail.validity_date === null ? (
              <div className="columns pt-24">
                <h3 className="p-8">Create a new subscription</h3>
                <Button
                  label="Create Subscription"
                  primary
                  onClick={() => navigate("/subscriptions/dashboard")}
                />
              </div>
            ) : (
              <div className="columns pt-24">
                <h3 className="p-8">Change seats or the package</h3>
                <Popover content={modifyStripeMessage}>
                  <Button
                    label="Modify Subscription"
                    primary
                    onClick={() => setShowPopup(true)}
                    //onClick={modifyStripeSubscirption}
                  />
                </Popover>
              </div>
            )}
          </>
        )
      )}
    </div>
  );
};
export default SubscriptionDetail;
