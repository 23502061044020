import ReactModal from "react-modal";
import { useState } from "react";
import { Link } from "react-router-dom";
import { Table as AntTable } from "antd";
import StudentsTable from "../StudentsTable/StudentsTable";
import "./ClassBattleModal.scss";
import { TailSpin as Loader } from "react-loader-spinner";
import { fetchBattleHistoryDetails } from "../../actions/class-battle-history";
import { useDispatch, useSelector } from "react-redux";
import TabError from "../TabError/TabError";
import moment from 'moment';
const style = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.2)",
  },
  content: {
    position: "absolute",
    top: "15%",
    left: "24%",
    right: "24%",
    bottom: "15%",
    border: "1px solid #ccc",
    background: "#fff",
    overflowY: "auto",
    WebkitOverflowScrolling: "touch",
    borderRadius: "16px",
    outline: "none",
    padding: "0px",
    width: "800px",
    height: "600px",
    maxWidth: "90%",
    maxHeight: "90%",
  },
  closeButton: {
    position: "absolute",
    top: "8px",
    right: "8px",
    cursor: "pointer",
    background: "none",
    border: "none",
    fontSize: "30px",
    fontWeight: "bold",
    color: "white",
    zIndex: 1,
  },
};
const getFormattedTime = (dateTimeString) => {
  if (!dateTimeString) {
    return "N/A";
  }

  const date = new Date(dateTimeString + "Z");

    const options = {
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    };

    const localDateString = date.toLocaleString("en-US", options);
    return localDateString;
};


const selectLoading = ({ classBattle }) => classBattle.history_loader;
const selectError = ({ classBattle }) => classBattle.error;

const ClassBattleModal = ({
  modalIsOpen,
  onCancelClick,
  selectedBattle,
  battleHistoryDetails,
}) => {
  const dispatch = useDispatch();
  const loading = useSelector(selectLoading);
  const error = useSelector(selectError);

  const calculateDuration = (startTimestamp, endTimestamp) => {
    const time1 = moment(startTimestamp).seconds(0).milliseconds(0);
    const time2 = moment(endTimestamp).seconds(0).milliseconds(0);
  
    const differenceInMinutes = time2.diff(time1, 'minutes');

    const hours = Math.floor(differenceInMinutes / 60);
    const minutes = differenceInMinutes % 60;

    let duration = "";
    if (hours > 0) {
      duration += hours + " hour" + (hours > 1 ? "s" : "") + " and ";
    }
    duration += minutes + " minute" + (minutes !== 1 ? "s" : "");
  
    return duration;
  };
  

  const columns = [
    {
      title: "Result",
      dataIndex: "result",
      key: "result",
      render: () => (
        <>
          {battleHistoryDetails.result === true
            ? " Students Won"
            : " Students Lost"}
        </>
      ),
    },
    {
      title: "Start Time",
      dataIndex: "date_created",
      key: "date_created",
      render: () => <>{getFormattedTime(battleHistoryDetails.date_created)}</>,
    },
    {
      title: "End Time",
      dataIndex: "date_ended",
      key: "date_ended",
      render: () => <>{getFormattedTime(battleHistoryDetails.date_ended)}</>,
    },
    {
      title: "Duration",
      dataIndex: "date_ended",
      key: "duration",
      render: () => (
        <>
          {calculateDuration(
            battleHistoryDetails.date_created,
            battleHistoryDetails.date_ended
          )}
        </>
      ),
    },
    {
      title: "Battle Type",
      dataIndex: "battle_type",
      key: "battle_type",
      render: () => (
        <>
          {battleHistoryDetails.battle_type === "adventure_battle"
            ? "Adventure"
            : "Review"}
        </>
      ),
    },
  ];

  if (battleHistoryDetails.battle_type === "adventure_battle") {
    columns.push({
      title: "Adventure Name",
      dataIndex: "battle_type",
      key: "adventure_name",
      render: () => (
        <Link
          to={`/adventures/list/adventure/view/${battleHistoryDetails.adventure.id}?history=true`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {battleHistoryDetails.adventure.name}
        </Link>
      ),
    });
  }

  ReactModal.setAppElement("#root");

  return (
    <ReactModal
      isOpen={modalIsOpen}
      onRequestClose={onCancelClick}
      style={style}
      contentLabel="Example Modal"
    >
      <div className="header">
        <button style={style.closeButton} onClick={onCancelClick}>
          &times;
        </button>
        <h2>{selectedBattle.name}</h2>
      </div>
      <div className="container">
        {loading ? (
          <Loader type="Puff" color="#354AC9" height={50} width={50} />
        ) : error ? (
          <TabError
            onClick={() =>
              dispatch(fetchBattleHistoryDetails(selectedBattle.id))
            }
            message={error.message}
            label="Reload History Details"
          />
        ) : (
          <div className="inner-container">
            <AntTable
              title={null}
              columns={columns}
              dataSource={[battleHistoryDetails]}
              showHeader
              size="large"
              pagination={false}
              bordered={true}
            />
            <StudentsTable
              selectedBattle={selectedBattle}
              adventureType={battleHistoryDetails.battle_type}
            />
          </div>
        )}
      </div>
    </ReactModal>
  );
};

export default ClassBattleModal;
