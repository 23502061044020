import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import SideBar from "../../components/SideBar/SideBar";
import isSubscriptionEnabled from "../../helper/isSubscriptionEnabled";
import getSubscriptionDetail from "../../helper/getSubscriptionDetail";
import {
  NavBarLinkSubscribed,
  NavBarLinkUnsubscribed,
} from "../../components/NavBar/navbarLinks";
import { TailSpin as Loader } from "react-loader-spinner";
import isClassBattleEnabled from "../../helper/isClassBattleEnabled";

const OwnerHome = () => {
  const [subscriptionEnabled, setSubscriptionEnabled] = useState(false);
  const [classRoomBattleEnabled, setClassRoomBattleEnabled] = useState(false);
  const [subscriptionDetail, setSubscriptionDetail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const subscriptionData = async () => {
    setError("");
    try {
      setIsLoading(true);
      const responseEnable = await isSubscriptionEnabled();
      const enableClassBattle = await isClassBattleEnabled();
      setSubscriptionEnabled(responseEnable);
      setClassRoomBattleEnabled(enableClassBattle);
      const responseDetail = await getSubscriptionDetail();
      setSubscriptionDetail(responseDetail);
      setIsLoading(false);
    } catch (error) {
      setError("Error Creating Subscriptions");
    }
  };
  useEffect(() => {
    subscriptionData();
  }, []);

  return (
    <div className="home-page page flex">
      {isLoading ? (
        <div
          className="flex-column flex-center"
          style={{ width: "-webkit-fill-available" }}
        >
          <Loader type="Puff" color="#354AC9" height={54} width={54} />
        </div>
      ) : (
        <>
          {!subscriptionEnabled ? (
            <SideBar
              pages={[
                ...NavBarLinkSubscribed,
                { title: "Subscriptions", link: "/subscriptions/dashboard" },
              ]}
            />
          ) : subscriptionDetail.is_valid ? (
            <>
             <SideBar
                pages={[
                  ...NavBarLinkSubscribed,
                  {
                    title: "Subscription Detail",
                    link: "/subscriptions/detail",
                  },
                  ...(classRoomBattleEnabled ? [{ title: "Class Battle 🚧", link: "/class-battle/dashboard" }] : []),
                ]}
              />
            </>
          ) : !subscriptionDetail.is_valid &&
            subscriptionDetail.payment_type == null ? (
            <SideBar
              pages={[
                ...NavBarLinkUnsubscribed,
                { title: "Subscriptions", link: "/subscriptions/dashboard" },
              ]}
            />
          ) : (
            //!subscriptionDetail.is_valid
            <SideBar
              pages={[
                ...NavBarLinkUnsubscribed,
                { title: "Subscription Detail", link: "/subscriptions/detail" },
              ]}
            />
          )}
          {error !== "" ? <p className="error mb-16">{error}</p> : <p></p>}
          <div className="fl-1 p-24">
            <div className="grid-fill">
              {HomePageTeacherStud}
              {!subscriptionEnabled ? (
                <>
                  {HomePageClick}
                  <Link
                    className="card card-nav clickable flex flex-center"
                    to="/subscriptions/dashboard"
                  >
                    <p className="font-black bold">Subscriptions</p>
                  </Link>
                </>
              ) : subscriptionDetail.is_valid ? (
                <>
                  {HomePageClick}
                  <Link
                    className="card card-nav clickable flex flex-center"
                    to="/subscriptions/detail"
                  >
                    <p className="font-black bold">Subscription Detail</p>
                  </Link>
                  {classRoomBattleEnabled ? (
                    <Link
                      className="card card-nav clickable flex flex-center"
                      to="/class-battle/dashboard"
                    >
                      <p className="font-black bold">Class Battle (beta) 🚧</p>
                    </Link>
                  ) : null}
                </>
              ) : !subscriptionDetail.is_valid &&
                subscriptionDetail.payment_type == null ? (
                <>
                  <Link
                    className="card card-nav clickable flex flex-center"
                    to="/subscriptions/dashboard"
                  >
                    <p className="font-black bold">Subscriptions</p>
                  </Link>
                  {HomePageNonClick}
                  {classRoomBattleEnabled ? (
                    <Link
                      className="card card-nav non-clickable flex flex-center"
                      to="/subscriptions/dashboard"
                    >
                      <p className="font-gray bold">Class Battle</p>
                    </Link>
                  ) : null}
                </>
              ) : (
                //!subscriptionDetail.is_valid
                <>
                  <Link
                    className="card card-nav clickable flex flex-center"
                    to="/subscriptions/detail"
                  >
                    <p className="font-black bold">Subscription Detail</p>
                  </Link>
                  {HomePageNonClick}
                  {classRoomBattleEnabled ? (
                    <Link
                      className="card card-nav non-clickable flex flex-center"
                      to="/subscriptions/dashboard"
                    >
                      <p className="font-gray bold">Class Battle</p>
                    </Link>
                  ) : null}
                </>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};
const HomePageClick = (
  <>
    <Link
      className="card card-nav clickable flex flex-center"
      to="/classes/dashboard"
    >
      <p className="font-black bold">Classes</p>
    </Link>
    <Link
      className="card card-nav clickable flex flex-center"
      to="/adventures/dashboard"
    >
      <p className="font-black bold">Adventures</p>
    </Link>
    <Link
      className="card card-nav clickable flex flex-center"
      to="/analytics/dashboard"
    >
      <p className="font-black bold">Analytics</p>
    </Link>
  </>
);

const HomePageNonClick = (
  <>
    <Link
      className="card card-nav non-clickable flex flex-center"
      to="/subscriptions/dashboard"
    >
      <p className="font-gray bold">Classes</p>
    </Link>
    <Link
      className="card card-nav non-clickable flex flex-center"
      to="/subscriptions/dashboard"
    >
      <p className="font-gray bold">Adventures</p>
    </Link>
    <Link
      className="card card-nav non-clickable flex flex-center"
      to="/subscriptions/dashboard"
    >
      <p className="font-gray bold">Analytics</p>
    </Link>
  </>
);
const HomePageTeacherStud = (
  <>
    <Link
      className="card card-nav clickable flex flex-center"
      to="/teachers/dashboard"
    >
      <p className="font-black bold">Teachers</p>
    </Link>
    <Link
      className="card card-nav clickable flex flex-center"
      to="/students/dashboard"
    >
      <p className="font-black bold">Students</p>
    </Link>
  </>
);
export default OwnerHome;
