import { useEffect, useState } from 'react';
import {TailSpin as Loader} from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { createSelector } from 'reselect';
import { Table as AntTable, Image, Input } from 'antd';
import { fetchBundles } from '../../../actions/bundles';
import TabError from '../../../components/TabError/TabError';
import { stringMatch } from '../../../utils';

import 'antd/dist/reset.css';
import Button from '../../../components/Button/Button';

const { Search } = Input;

const selectBundles = ({ bundles }) => bundles.data;
const selectBundlesPagination = ({ bundles }) => bundles.pagination;
const selectBundlesLoading = ({ bundles }) => bundles.loading;
const selectBundlesError = ({ bundles }) => bundles.error;

const bundlesArray = createSelector([selectBundles], bundles => Object.values(bundles));

const Bundles = ({ onBack, onNext, classBattle }) => {
  const history = useNavigate();

  const bundlesData = useSelector(bundlesArray);
  const bundlesPagination = useSelector(selectBundlesPagination);
  const bundlesLoading = useSelector(selectBundlesLoading);
  const bundlesError = useSelector(selectBundlesError);
  const dispatch = useDispatch();

  const [search, setSearch] = useState('');
  const [bundles, setBundles] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  let offset = (page - 1) * 10;

  useEffect(() => {
    dispatch(fetchBundles(search, pageSize, offset));
  }, [dispatch, page, pageSize]);

  useEffect(() => {
    setBundles(bundlesData);
  }, [bundlesData]);

  useEffect(() => {
    setBundles(bundlesData.filter(({ name }) => stringMatch(name, search)));
  }, [bundlesData]);

  const onSearch = value => {
    dispatch(fetchBundles(search, pageSize, offset));
    setPage(1);
  };
 
  const columns = [
    {
      title: 'Action',
      key: 'action',
      width: 100,
      fixed: 'right',
      render: value => (
        <span onClick={() => onNext(value)} className="table-action-buttons">
          <p className="text-buttons view" style={{ color: 'white' }}>
            select
          </p>
        </span>
      ),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Image',
      dataIndex: 'cover_image',
      width: 300,
      render: value => (
        <Image src={value} height={75} width={75} style={{ objectFit: 'contain' }} />
      ),
    },
  ];

  const handlePagination = () => {
    return {
      current: page,
      pageSize: pageSize,
      total: bundlesPagination.count,
      onChange: (page, pageSize) => {
        setPage(page);
        setPageSize(pageSize);
      },
    };
  };

  const renderBundleList = () => (
    <AntTable
      title={null}
      pagination={search === '' && handlePagination()}
      columns={columns}
      dataSource={bundles}
      showHeader
      size="default"
      bordered={false}
    />
  );

  return (
    <div className="bundles-page flex-column fl-1">
      {classBattle?<h3 className="ml-10 mg-top mb-16 text-heading ">Step 3: Select a bundle from the list</h3>:<h1 className="mh-24 mt-24 mb-16">Bundle List</h1>}
      

      {bundlesLoading ? (
        <div className="flex flex-center fl-1">
          <Loader type="Puff" color="#354AC9" height={50} width={50} />
        </div>
      ) : bundlesError ? (
        <TabError
          onClick={() => dispatch(fetchBundles())}
          message={bundlesError.message}
          label="Reload Bundle"
        />
      ) : (
        <>
          <Search
            placeholder="Search Bundles"
            enterButton="Search"
            size="large"
            onSearch={onSearch}
            value={search}
            onChange={e => setSearch(e.target.value)}
          />
          <div className="overflow-hidden flex-column fl-1">
            <div id="scrollableContainer" className="list-container fl-1 ml-24">
              {renderBundleList()}
            </div>
          </div>
          <div className="flex justify-end mb-24 mh-24 summary-btns">
          {classBattle && (
            <>
              <Button label="Back" primary onClick={onBack} className="mr-24" wide />
            </>
          )}
        </div>
        </>
      )}
    </div>
  );
};

export default Bundles;
