const getDiscount = (seat, packageId) => {
  if (packageId === "1") {
    if (seat >= 1 && seat <= 50) return 800;
    else if (seat <= 99) return 720;
    else if (seat <= 499) return 640;
    else return 560;
  } else {
    if (seat >= 1 && seat <= 50) return 1000;
    else if (seat <= 99) return 900;
    else if (seat <= 499) return 800;
    else return 700;
  }
};

export default getDiscount;
