import { useDispatch, useSelector } from "react-redux";
import { createClass } from "../../actions/classes";
import ClassForm from "../../components/ClassForm/ClassForm";
import "./NewClass.scss";

const selectClassesStatus = ({ classes }) => classes.status;

const NewClass = () => {
  const dispatch = useDispatch();
  const classesStatus = useSelector(selectClassesStatus);
  return (
    <div className="new-class-page flex-column fl-1">
      <ClassForm
        className="fl-1"
        onSubmit={(createdClass) => dispatch(createClass(createdClass))}
        status={classesStatus}
      />
    </div>
  );
};

export default NewClass;
